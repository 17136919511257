import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { Link, useLocation } from 'react-router-dom';
import { Footer } from '../footer/Footer'
import { LandingHeader } from '../landing/LandingHeader'
import { useFetch } from '../../hooks/useFetch';
import moment from 'moment';
const landingImages = require.context('../../assets/images', true);
/**
 * Creado por Equilibrio Agency 2024<br>
 * Página para mostrar contenido de Preguntas Frecuentes Placetopay
 * @return {HTMLElement} Elementos HTML de cada componente
 * @interface AdminHeader
 */
export const ViewCertification = () => {
    const { code } = useParams();
    const location = useLocation();
    const navigator = useNavigate();
    const [data, handleFetch, handleFetchPDF] = useFetch();  
    const [searchCriteria, setSearchCriteria] =  useState(location.state?location.state:{});
    const [loading, setLoading] =  useState(true);
    const [dataCert, setDataCert] =  useState([]);
    const [dataCourse, setDataCourse] =  useState([]);
    const [imagenUrl, setImagenUrl] = useState(null);
    const searchAction = (keyword) => {
      setSearchCriteria({keyword});
    }
    const getNameFromUrl = (url) => {
        const params = new URLSearchParams(new URL(url).search);
        return params.get('name');
    }


    const [showDescription, setShowDescription] = useState(false);
    const [showContent, setShowContent] = useState([false]);

    // Función mostrar u ocultar contenido
    const handleContentShown = (index) =>{
        if(showContent[index]){

        let auxArr = Array(dataCourse.contentData?.length).fill(false);
        setShowContent(auxArr);
        }else{
        let auxArr = Array(dataCourse.contentData?.length).fill(false);
        auxArr[index] = true;
        setShowContent(auxArr);

        }
    }

    useEffect(() => {
        setShowContent(Array(dataCourse.contentData?.length).fill(false))
    }, [dataCourse.contentData])
    
    const show_correct_time = (time_minutes) =>{
        return Math.floor(time_minutes/60)+"h "+time_minutes%60+"m";
    }
    useEffect(() => {
        window.scrollTo(0, 0);
        handleFetch({
            url:`catalog/viewcertificate?certificationId=${code}`,//kwNbGmTnWe
            method: 'GET'
            })
            .then (async r => {
                if(r.data.length!==0){
                    let slugcourse = r.data[0]['user_study.studyId'];
                    setDataCert(r.data[0])
                    await handleFetch({
                        url:`catalog/b2bcatalog?&company=1&courseId=${slugcourse}`,
                        method: 'GET',
                    }).then(async rc => {
                        setDataCourse(rc.data[0]);
                        try {
                          const blob = await handleFetchPDF({
                            url: `certifications/gencertificatesimg/download`,
                            method: 'POST',
                            data: {
                              certCode: rc.data[0]['attr.certificationTemplateId'],
                              courseName: rc.data[0]['course.title'],
                              certDate: rc.data[0]['attr.createdAt'],
                              studyId: rc.data[0]['course.slug'],
                              userId: r.data[0]['userId'],
                              receiver: {
                                fullname: `${r.data[0]['webuser.name']} ${r.data[0]['webuser.lastname']} `,
                                document: r.data[0]['webuser.document']
                              }
                            },
                            responseType: 'blob'
                          });
                  
                          const url = window.URL.createObjectURL(blob);
                          setImagenUrl(url);
                      } catch (error) {
                          console.error('There was an error downloading the PDF:', error);
                      }
                    },
                    error => {
                        console.log(error)
                    });
                    setLoading(false)
                }else{
                    navigator('/')
                }
            });
    }, []);
    const filterModules = (items) => {
        return items?.filter(item => item?.name?.startsWith('Módulo'));
    };
    const filteredData = filterModules(dataCourse['course.contentData']);
    const copyToClipboard =  (e) => {
      e.preventDefault();
      var aux = document.createElement("input");
      aux.value = dataCert?.certificationUrl;
      document.body.appendChild(aux);
      aux.select();
      document.execCommand("copy");
      document.body.removeChild(aux);
    };
    return (
    <div>
        <LandingHeader  searchAction={searchAction} />
        <div style={{marginTop: "75px"}}></div>
        { loading ? <Loading /> : <>
          <div className='row justify-content-center'>
            <div className='col-10 col-xl-9 ps-0' style={{marginTop: "30px", marginBottom: "10px"}}>
            <div className='mb-3'>
              <div className='row'>
                  <div className="col-12 col-lg-8">
                    <div className='col-12' style={{padding: "0px"}}>
                      <h1 className='CourseDetail__title'>{dataCourse['course.title']}</h1>
                    </div>
                    <div className='col-12' style={{padding: "0px"}}>
                      <img alt={dataCourse['course.title']+" banner"} src={imagenUrl && imagenUrl} style={{maxWidth: "100%", borderRadius: "8px", minWidth: "100%"}}/>
                    </div>
                    <div className='col-12' style={{padding: "0px"}}>
                    <p style={{fontWeight: "700", fontSize:"28px", letterSpacing: "-0.01em", lineHeight: "140%"}}>Contenido del curso</p>
                  <hr className='CourseDetail__separation-line' style={{marginBottom: "20px"}} />
                  {
                    filteredData!=null && filteredData.map((chapter, index) =>{
                      return(
                        <div  key={`course-lesson-${index}`}>
                          <div className='ps-0' style={{marginBottom: "20px"}}>
                            <div className='d-flex justify-content-between justify-content-lg-start align-items-center' style={{fontSize: "21px", fontWeight: "600"}} onClick={()=>handleContentShown(index)}>
                              <div>{chapter.name} 
                              </div>
                              <div>
                                { showContent[index] ?
                                  <i className="fa-solid fa-angle-up" style={{fontSize: "14px", marginLeft: "10px"}}></i> :
                                  <i className="fa-solid fa-angle-down" style={{fontSize: "14px", marginLeft: "10px"}}></i>
                                }
                              </div>
                            </div>
                            {
                              showContent[index]?
                                <ul style={{marginTop: "16px"}}>
                                  {chapter?.lessons.map((lesson,index) =>{
                                    return(
                                      <li key={`lesson-${index}`}>
                                        {lesson.name}
                                      </li>
                                    )
                                  })}
                                </ul>
                              :
                              <></>
                            }
                          </div>
                          <hr className='CourseDetail__separation-line' style={{marginBottom: "20px"}} />  
                        </div>
                      )
                    })

                  }
                    </div>
                  </div>
                  <div className="col-12 col-lg-4">
                    <div className='row justify-content-center'>

                      <div className='col-12 ps-0 d-flex flex-wrap'>
                        <div className='col-12 text-center'>
                          <i className="fa-solid fa-circle-check" style={{color: "green", fontSize: "72px"}}></i>
                        </div>
                        <div className='col-12 text-center' style={{color: "green", fontSize: "22px", fontWeight: "bold"}}>
                          Certificado Verificado
                        </div>
                      </div>
                      <div className='col-12 ps-0 d-flex flex-wrap mt-3'>
                        <div className='col-12'>
                          <div className="d-flex justify-content-between justify-content-lg-start align-items-center">
                            <div className='col-10'>
                              <input 
                                  value={dataCert?.certificationUrl}
                                  disabled
                                  type="text"
                                  className={`form-control`} />
                              </div>
                              <div className='col-2'>
                                <Link to={'#'} className="FilterAdmin__search-button" onClick={(event) => copyToClipboard(event)}>Copiar</Link>
                              </div>
                            </div> 
                        </div>
                      </div>
                      <div className='col-12 ps-0 d-flex flex-wrap' style={{color: "grey", fontSize: "14px"}}>
                        <div className='row'>
                          <div className='col-6 col-sm-5 col-md-3 col-lg-6 col-xl-6 col-xxl-5 d-flex align-items-baseline justify-content-start'>
                            <i className="fa-solid fa-certificate" style={{color: "green", fontSize: "14px"}}></i><p className='ms-2'>Verificado</p>
                          </div>
                          <div className='col-5 col-sm-4 col-md-3 col-lg-5 col-xl-5 col-xxl-4 d-flex align-items-baseline justify-content-start'>
                            <i className="fa-solid fa-clock" style={{color: "green", fontSize: "14px"}}></i><p className='ms-2'>{show_correct_time(dataCourse['course.duration'])}</p>
                          </div>
                          <div className='col-4 col-sm-3 col-md-2 col-lg-4 col-xl-3 col-xxl-3 d-flex align-items-baseline justify-content-start'>
                            <i className="fa-solid fa-language" style={{color: "green", fontSize: "14px"}}></i><p className='ms-2'>Español</p>
                          </div>
                        </div>
                      </div>
                      </div>

                      <label className="form-label UserFormContainer__label">Nombre del Estudiante</label><p>{dataCert['webuser.name']} {dataCert['webuser.lastname']}</p>
                      <label className="form-label UserFormContainer__label">Número Documento del Estudiante</label><p>{dataCert['webuser.documentType']} {dataCert['webuser.document']}</p>
                      <label className="form-label UserFormContainer__label">Certificado Expedido</label><p>{moment.utc(dataCert.createdAt).format('DD/MM/YYYY')}</p>
                  </div>
                  </div>

        </div>
      </div>
      
    </div></> }
        <Footer />
    </div>
  )
}

/**
 * Creado por Equilibrio Agency 2024<br>
 * Función Loading
 * @function Loading
 */
const Loading = (props) => {
    const loadingImage = landingImages('./icons/loading_spin_ean.svg');
    return (
        <div className='d-flex justify-content-center' style={{ minHeight: "300px" }}>
            <div className='row text-center position-absolute justify-content-center'>
                <img alt='Loader' src={loadingImage} />
            </div>
        </div>
    );
}