import React, { useEffect, useState } from 'react';
import CSVReader from 'react-csv-reader';
import { useForm as hookUseForm } from 'react-hook-form';
import { ErrorMessage, SubmitFormButton, Toast } from '../../controls/UIControls';
import * as CONSTANTS from '../../../assets/constants';
import { useFetch } from '../../../hooks/useFetch';
import { useAuthToken } from '../../../hooks/useAuthToken';
import { Link, useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import Modal from '@mui/material/Modal';
import { apiConfig } from '../../../api/apiConfig';
import Papa from 'papaparse';
const imagesb2b = require.context('../../../assets/images', true);
/**
 * Creado por Equilibrio Agency 2022<br>
 * Página contenido de sección usuarios
 * @interface B2BCreateEditSection
 */
export const B2BCreateEditSection = () => {

    const { register, handleSubmit, setValue, getValues, formState: { errors } } = hookUseForm({ reValidateMode: "onChange" });
    const [users, setUsers] = useState({});
    const [usersb2b, setUsersb2b] = useState([]);
    const [segment, setSegment] = useState([]);
    const [plans, setPlans] = useState([]);
    const [courses, setCourses] = useState([]);
    const [fullcatalog, setFullcatalog] = useState([]);
    const [companies, setCompanies] = useState([]);
    const { authToken } = useAuthToken(true);
    const [fetchData, handleFetch] = useFetch();
    const [toastProperties, setToastProperties] = useState({ show: false });
    const params = useParams();
    const DURATION_DAYS = 1;
    const DURATION_WEEKS = 2;
    const DURATION_MONTHS = 3;
    const DURATION_YEARS = 4;
    const [showModalEditCourse, setShowModalEditCourse] = useState(false);
    const [showModalCreatePlan, setShowModalCreatePlan] = useState(false);
    const [showModalCreateSegment, setShowModalCreateSegment] = useState(false);
    const [showModalCreateUser, setShowModalCreateUser] = useState(false);
    const [showModalCreateUserBatch, setShowModalCreateUserBatch] = useState(false);
    const [waiting, setWaiting] = useState(false);

    const alphabetPattern = CONSTANTS.PATTERN_ALPHABET;
    const numericPattern = CONSTANTS.PATTERN_NUMERIC;
    const alphanumericPattern = CONSTANTS.PATTERN_ALPHANUMERIC;

    const numericMessage = CONSTANTS.MESSAGE_DOCUMENT_NUMERIC_INVALID;
    const alphanumericMessage = CONSTANTS.MESSAGE_DOCUMENT_NUMERIC_INVALID;
    const [namePattern] = useState(alphabetPattern);
    const [docPattern, setDocPattern] = useState(numericPattern);
    const [docMessage, setDocMessage] = useState(numericMessage);
    const [showAge, setShowAge] = useState(false);
    const [showModalUpdated, setShowModalUpdated] = useState(false);
    const [showModalDelete, setShowModalDelete] = useState(false);
    const [showModalSincSap, setShowModalSincSap] = useState(false);
    const [showModalEmptyDBEanist, setShowModalEmptyDBEanist] = useState(false);
    const [showModalTypeUsersCSV, setShowModalTypeUsersCSV] = useState(false);
    const [isEditing, setIsEditing] = useState(false)
    const [editPlan, setEditPlan] = useState(false)
    const [idEditPlan, setIdEditPlan] = useState(0)
    const [editSegment, setEditSegment] = useState(false)
    const [idEditSegment, setIdEditSegment] = useState(0)
    const [countUsersSegments, setCountUsersSegments] = useState(0)
    const [maxUsersAdd, setMaxUsersAdd] = useState(0)
    const afterCloseToast = () => {
        setToastProperties({ show: false });
    }

    const navigate = useNavigate();
    // Al cerrar dialigo se reenvia a ruta principal de administración de usuarios
    const refreshCatalog = () => {
        // consulta de endpoint para historico de cursos inscritos por el usuario, se pasa por parámetro id del usuario
        const responsech = handleFetch({
            url: `bo/b2bcatalog?$limit=500&company=${params.id}`,
            method: 'GET',
        });
        responsech
            .then((r) => {
                setCourses(r.data)
                let newArray = fullcatalog.filter(item1 => {
                    return !r.data.some(item2 => item1.title === item2['course.title']);
                  });
                  setFullcatalog(newArray);
            },
                error => {
                    console.log(error.errors);
                    if (error.errors) {

                    }
                })
    }
    const refreshUsers = () => {
        let url = parseInt(params.id)!==1 ? `bo/b2buser?&companyId=${params.id}&$limit=10`: 'bo/eanxuser?$limit=10'
        const responseusersb2b = handleFetch({
            url: url,
            method: 'GET',
            headers: { Authorization: `Bearer ${authToken}` }
        });
        responseusersb2b
            .then((r) => {
                setUsersb2b(r.data)
            },
                error => {
                    console.log(error.errors);
                })
    }
    const refreshSegments = () => {
        const responsesegment = handleFetch({
            url: `bo/segment?&companyId=${params.id}`,
            method: 'GET',
            headers: { Authorization: `Bearer ${authToken}` }
        });
        responsesegment
            .then(async (r) => {
                if(r.data.length == 0){
                    if(parseInt(params.id)===1){
                        setSegment([
                            { "type": 1, "name": "Público General"},
                            { "type": 2, "name": "Estudiante Eanista"},
                            { "type": 3, "name": "Docente de Planta" },
                            { "type": 4, "name": "Docente de Cátedra"},
                            { "type": 5, "name": "Colaborador" },
                            { "type": 6, "name": "Egresado" } ])
                        }else{
                            setSegment([])
                        }
                    }else{setSegment(r.data)}
                setMaxUsersAdd((parseInt(getValues('limitusers'))-parseInt(r.data.reduce((sum, record) => sum + record.limitusers, 0))))
                setCountUsersSegments(r.data.reduce((sum, record) => sum + record.limitusers, 0))
            },
                error => {
                    console.log(error.errors);
                })
    }
    const refreshCompanies = () => {
        const responsecomp = handleFetch({
            url: `bo/companiesb2b`,
            method: 'GET',
            headers: { Authorization: `Bearer ${authToken}` }
        });
        responsecomp
            .then((r) => {
                setCompanies(r.data)
            },
                error => {
                    console.log(error.errors);
                })
    }
    const refreshPlans = () => {
            //consulta de endpoint de planes disponibles
            const response = handleFetch({
                url: `bo/plans?companyId[$eq]=${params.id}`,
                method: 'GET',

            });
            response
                .then((r) => {
                    setPlans(r)
                },
                    error => {
                    })
    }
    const compararCursos = (a, b) => {
        var tituloA = a?.['course.title'].toUpperCase();
        var tituloB = b?.['course.title'].toUpperCase();
        if (tituloA < tituloB) {
            return -1;
        }
        if (tituloA > tituloB) {
            return 1;
        }
        return 0;
    };
    const compararFullCatalog = (a, b) => {
        var tituloA = a?.title.toUpperCase();
        var tituloB = b?.title.toUpperCase();
        if (tituloA < tituloB) {
            return -1;
        }
        if (tituloA > tituloB) {
            return 1;
        }
        return 0;
    };
    //Ordenar array del catálogo completo de cursos
    const ordenarFullcatalog = () => {
        let arrayOrdenado = [...fullcatalog].sort(compararFullCatalog);
        setFullcatalog(arrayOrdenado)
    };
    //Ordenar array de cursos agregados al B2B
    const ordenarCourses = () => {
        let arrayOrdenado = [...courses].sort(compararCursos);
        setCourses(arrayOrdenado)
    };
    // Función mostrar modal mostrar tipo de usuarios eanistas y empresas b2b
    const handleOpenModalTypeUsersCSV = async () => {
        setShowModalTypeUsersCSV(true);
    }
    // Función cerrar modal mostrar tipo de usuarios eanistas y empresas b2b
    const handleCloseModalTypeUsersCSV = () => {
        setShowModalTypeUsersCSV(false);
    }
    //Catálogo de cursos existentes en learnworlds y revisar los añadidos en B2B
    const catalogFull = () => {
        const responsecatalog = handleFetch({
            url: `catalog?$limit=500&$skip=0`,
            method: 'GET',
        });
        responsecatalog
            .then(async (r) => {
                const responsech = handleFetch({
                    url: `bo/b2bcatalog?$limit=500&company=${params.id}`,
                    method: 'GET',
                });
                responsech
                    .then((rc) => {
                        setCourses(rc.data)
                        let newArray = r.data.filter(item1 => {
                            return !rc.data.some(item2 => item1.title === item2['course.title']);
                          });
                          setFullcatalog(newArray);
                    },
                        error => {
                            console.log(error.errors);
                            if (error.errors) {
        
                            }
                        })
            },
                error => {
                    console.log(error.errors);
                })
    }
    // consulta de endpoint de planes para obtener información de plan individual, se recibe parametro llamado planID
    useEffect(() => {
        if (params.id) {
            const responsed = handleFetch({
                url: `bo/companiesb2b/${params.id}`,
                method: 'GET',
                headers: { Authorization: `Bearer ${authToken}` }
            });
            responsed
                .then((r) => {
                    setIsEditing(true)
                    setUsers(r)
                    setValue("id", r.id);
                    setValue("name", r.name);
                    setValue("description", r.description);
                    setValue("image", r.image);
                    setValue("startsAt", moment.utc(r.startsAt).format('YYYY-MM-DD'));
                    setValue("endsAt", moment.utc(r.endsAt).format('YYYY-MM-DD'));
                    setValue("limitusers", r.limitusers);
                    setValue("urlb2b", r.urlb2b);
                    setValue("limitusersseg", '0');
                    setValue("firstName", 'aux');
                    setValue("lastName", 'aux');
                    setValue("userEmail", 'aux@aux.com');
                    setValue("documentType", 'CC');
                    setValue("userDocument", '11111');
                    setValue("descriptionplan", 'aux');
                    setValue("duration", '0');
                    setValue("price", '0');
                    setValue("title", 'aux');
                    setValue("namesegment", 'aux');
                    setValue("descriptionsegment", 'aux');
                    setValue("typesegment", '0');
                    setValue("plansegment", '0');
                    setValue("pricecourse", '0');
                },
                    error => {
                        navigate('/admin/b2b');
                    })
            refreshCatalog()
            refreshUsers()
            refreshCompanies()
            refreshSegments()
            refreshPlans()
            catalogFull()
        }else{
            setValue("firstName", 'aux');
            setValue("lastName", 'aux');
            setValue("userEmail", 'aux@aux.com');
            setValue("documentType", 'CC');
            setValue("userDocument", '11111');
            setValue("descriptionplan", 'aux');
            setValue("duration", '0');
            setValue("price", '0');
            setValue("title", 'aux');
            setValue("namesegment", 'aux');
            setValue("descriptionsegment", 'aux');
            setValue("typesegment", '0');
            setValue("plansegment", '0');
            setValue("limitusersseg", '0');
            setValue("pricecourse", '0');
        }
    }, [])
    // Función submit para editar compañia
    const onSubmit = (data) => {

        const method = isEditing ? 'PATCH' : 'POST';
        const urlCompany = 'bo/companiesb2b' + (isEditing ? `/${params.id}` : '');
        if (!isEditing) {
            data.startsAt = moment().format('YYYY-MM-DD');
            data.endsAt = moment().format('YYYY-MM-DD');
        }
        if(isEditing && data.limitusers < countUsersSegments){
            setToastProperties({
                show: true,
                type: 'error',
                message: `Error al guardar información del B2B.<br>El límite de usuarios no puede ser inferior a la suma de usuarios existentes en las segmentaciones.`,
                position: { horizontal: 'left' },
            });
        }else{
            handleFetch({
                url: urlCompany,
                method: method,
                data: data,
                headers: { Authorization: `Bearer ${authToken}` },
            })
                .then(() => {
                    navigate('/admin/b2b', {
                        state: {
                            toastProperties: {
                                show: true,
                                type: 'success',
                                title: 'Operación exitosa',
                                message: 'El B2B se guardó correctamente.',
                                position: { horizontal: 'left' },
                            }
                        }
                    });
                })
                .catch(() => {
                    setToastProperties({
                        show: true,
                        type: 'error',
                        message: 'Error al guardar información del B2B. Intenta nuevamente.',
                        position: { horizontal: 'left' },
                    });
                });
        }
    }
    const clearAll = () => {
        setValue("id", '');
        setValue("firstName", '');
        setValue("lastName", '');
        setValue("userEmail", '');
        setValue("documentType", '');
        setValue("userDocument", '');
        setValue("type", '');
        setValue("descriptionplan", '');
        setValue("duration", '0');
        setValue("price", '0');
        setValue("title", '');
        setValue("namesegment", '');
        setValue("descriptionsegment", '');
        setValue("typesegment", '0');
        setValue("plansegment", '0');
        setValue("limitusersseg", '0');
        setValue("pricecourse", '0');
        setValue("canCertificate", false);
    }

    // Función mostrar modal en caso de registro usuario b2b
    const handleOpenModalCreateUser = async () => {
        setValue("firstName", '');
        setValue("lastName", '');
        setValue("userEmail", '');
        setValue("documentType", '');
        setValue("userDocument", '');
        setValue("descriptionplan", 'aux');
        setValue("duration", '0');
        setValue("price", '0');
        setValue("title", 'aux');
        setValue("namesegment", 'aux');
        setValue("descriptionsegment", 'aux');
        setValue("typesegment", '0');
        setValue("plansegment", '0');
        setValue("limitusersseg", '0');
        setValue("urlb2b", 'aux');
        setValue("pricecourse", '0');
        setValue("canCertificate", false);
        setShowModalCreateUser(true);
    }
    // Función mostrar modal en caso de registro plan b2b
    const handleOpenModalCreatePlan = async () => {
        setValue("firstName", 'aux');
        setValue("lastName", 'aux');
        setValue("userEmail", 'aux@aux.com');
        setValue("documentType", 'CC');
        setValue("userDocument", '11111');
        setValue("namesegment", 'aux');
        setValue("descriptionsegment", 'aux');
        setValue("urlb2b", 'aux');
        setValue("typesegment", '1');
        setValue("plansegment", '0');
        setValue("descriptionplan", '');
        setValue("duration", '');
        setValue("price", '');
        setValue("title", '');
        setValue("limitusersseg", '0');
        setValue("pricecourse", '0');
        setValue("canCertificate", false);
        setShowModalCreatePlan(true);
    }
    // Función mostrar modal para editar curso añadido a B2B
    const handleOpenModalEditCourse =  (data, event) => {
        console.log("DATA",data)
        event.preventDefault();
        setEditPlan(true);
        setIdEditPlan(data.id);
        setValue("firstName", 'aux');
        setValue("lastName", 'aux');
        setValue("userEmail", 'aux@aux.com');
        setValue("documentType", 'CC');
        setValue("userDocument", '11111');
        setValue("namesegment", 'aux');
        setValue("descriptionsegment", 'aux');
        setValue("urlb2b", 'aux');
        setValue("typesegment", '1');
        setValue("plansegment", '0');
        setValue("descriptionplan", 'aux');
        setValue("duration", '0');
        setValue("price", '0');
        setValue("title", data['course.title']);
        setValue("limitusersseg", '0');
        setValue("pricecourse", data.price);
        setValue("canCertificate", data.canCertificate);
        setShowModalEditCourse(true);
    }
    // Función mostrar modal en caso de editar plan b2b
    const handleOpenModalEditPlan = (id, event) => {
        event.preventDefault();
        const plan = plans.find(
            (item) => parseInt(item.id) === id,
        );
        setEditPlan(true);
        setIdEditPlan(id);
        setValue("firstName", 'aux');
        setValue("lastName", 'aux');
        setValue("userEmail", 'aux@aux.com');
        setValue("documentType", 'CC');
        setValue("userDocument", '11111');
        setValue("namesegment", 'aux');
        setValue("descriptionsegment", 'aux');
        setValue("typesegment", '1');
        setValue("descriptionplan",plan.description);
        setValue("durationPeriod",plan.durationPeriod);
        setValue("duration", plan.duration);
        setValue("price", plan.price);
        setValue("title", plan.title);
        setValue("limitusersseg", '0');
        setValue("urlb2b", 'aux');
        setValue("pricecourse", '0');
        setValue("canCertificate", false);
        setShowModalCreatePlan(true);
    }
    // Función mostrar modal en caso de editar plan b2b
    const handleOpenModalEditSegment = (id, event) => {
        event.preventDefault();
        const segmentdata = segment.find(
            (item) => parseInt(item.id) === id,
        );
        setEditSegment(true);
        setIdEditSegment(id);
        setValue("firstName", 'aux');
        setValue("lastName", 'aux');
        setValue("userEmail", 'aux@aux.com');
        setValue("documentType", 'CC');
        setValue("userDocument", '11111');
        setValue("urlb2b", 'aux');
        setValue("namesegment", segmentdata.name);
        setValue("descriptionsegment", segmentdata.description);
        setValue("limitusersseg", segmentdata.limitusers);
        setValue("typesegment", segmentdata.type);
        setValue("plansegment", segmentdata.planId);
        setValue("descriptionplan",'aux');
        setValue("durationPeriod",'0');
        setValue("duration", '0');
        setValue("price", '0');
        setValue("title", 'aux');
        setValue("pricecourse", '0');
        setValue("canCertificate", false);
        setShowModalCreateSegment(true);
    }
    // Función mostrar modal en caso de registro de usuarios b2b por lote
    const handleOpenModalCreateUserBatch = async () => {
        setShowModalCreateUserBatch(true);
    }
    // Función mostrar modal para registrar segmento en b2b
    const handleOpenModalCreateSegment = async () => {
        setValue("firstName", 'aux');
        setValue("lastName", 'aux');
        setValue("userEmail", 'aux@aux.com');
        setValue("documentType", 'CC');
        setValue("userDocument", '11111');
        setValue("namesegment", '');
        setValue("descriptionsegment", '');
        setValue("typesegment", '');
        setValue("plansegment", '');
        setValue("descriptionplan", 'aux');
        setValue("duration", '0');
        setValue("price", '0');
        setValue("title", 'aux');
        setValue("limitusersseg", '0');
        setValue("urlb2b", 'aux');
        setValue("pricecourse", '0');
        setValue("canCertificate", false);
        setShowModalCreateSegment(true);
    }
    // Función mostrar modal en caso de agregar curso a B2B
    const handleOpenModal = async () => {
        ordenarFullcatalog();
        setSelectAll(false);
        setShowModalUpdated(true);
    }
    const [selectedCursos, setSelectedCursos] = useState([]);
    const handleCursoChange = (cursoId) => {
        setSelectedCursos((prevSelectedCursos) => {
        if (prevSelectedCursos.includes(cursoId)) {
            return prevSelectedCursos.filter((id) => id !== cursoId);
        } else {
            return [...prevSelectedCursos, cursoId];
        }
        });
    };
    const [selectAll, setSelectAll] = useState(false);
    const handleSelectAll = () => {
        let updatedItems = fullcatalog.map((item) => (item?.courseId));
        setSelectedCursos(selectAll!==true?updatedItems:[]);
        setSelectAll(!selectAll);
      };
    const [selectDeleteAll, setSelectDeleteAll] = useState(false);
    const handleSelectDeleteAll = () => {
        let updatedDeleteItems = courses.map((item) => (item?.id));
        setSelectedDeleteCursos(selectDeleteAll!==true?updatedDeleteItems:[]);
        setSelectDeleteAll(!selectDeleteAll);
      };
    // Función mostrar modal en caso de eliminar cursos a B2B
    const handleOpenModalDelete = async () => {
        ordenarCourses();
        setSelectDeleteAll(false);
        setShowModalDelete(true);
    }
    // Función cerrar modal en caso de eliminar cursos a B2B
    const handleCloseDeleteModal = () => {
        setShowModalDelete(false);
    }
    // Función mostrar modal para sincronización con SAP
    const handleOpenModalSincSap = async () => {
        setShowModalSincSap(true);
    }
    // Función cerrar modal para sincronización con SAP
    const handleCloseModalSincSap = () => {
        setShowModalSincSap(false);
    }
    const handleSincSap = async (event) => {
        event.preventDefault();
        setWaiting(true)
        await handleFetch({
            url: 'bo/syncsap',
            method: 'POST',            
            headers: { Authorization: `Bearer ${authToken}` }
        }).then(async (r) => {            
            setToastProperties({
                show: true,
                type: 'success',
                title: 'Operación exitosa',
                message: 'Se ha sincronizado la Base de datos Eanista con SAP Exitosamente.',
                position: { horizontal: 'left' },
            });
        }).catch(() => {
            setToastProperties({
                show: true,
                type: 'error',
                title: 'Error de sincronización',
                message: 'Error al sincronizar la Base de datos Eanista con SAP.',
                position: { horizontal: 'left' },
            });
        });
        setWaiting(false)
        refreshUsers();
        setShowModalSincSap(false);
    }
    // Función mostrar modal en caso de vaciar BD Eanista
    const handleOpenModalEmptyDBEanist = async () => {
        setShowModalEmptyDBEanist(true);
    }
    // Función cerrar modal en caso de eliminar cursos a B2B
    const handleCloseModalEmptyDBEanist = () => {
        setShowModalEmptyDBEanist(false);
    }
    const handleEmptyDBEanist = async (event) => {
        event.preventDefault();
        handleFetch({
            url: 'bo/eanxuser',
            method: 'DELETE',
        }).then(() => {})
            .catch(() => {
                
        });
        setToastProperties({
            show: true,
            type: 'success',
            title: 'Operación exitosa',
            message: 'Se ha vaciado la Base de datos Eanista Exitosamente.',
            position: { horizontal: 'left' },
        });
        refreshUsers();
        setShowModalEmptyDBEanist(false);
    }
    const [selectedDeleteCursos, setSelectedDeleteCursos] = useState([]);
    const handleDeleteCursoChange = (cursoId) => {
        setSelectedDeleteCursos((prevSelectedDeleteCursos) => {
        if (prevSelectedDeleteCursos.includes(cursoId)) {
            return prevSelectedDeleteCursos.filter((id) => id !== cursoId);
        } else {
            return [...prevSelectedDeleteCursos, cursoId];
        }
        });
    };
    // Handle para eliminar cursos al B2B en lote
    const handleDeleteCourseLote = async (event) => {
        event.preventDefault();
        setWaiting(true)
        try {
            for (let i = 0; i < selectedDeleteCursos.length; i++) {
                await handleFetch({
                    url: `bo/b2bcatalog/${selectedDeleteCursos[i]}`,
                    method: 'DELETE',
                })
                    .then((response) => {
                        console.log(response.data);
                    })
                    .catch(() => {
                        setToastProperties({
                            show: true,
                            type: 'error',
                            message: 'Error al eliminar los cursos del B2B. Intenta nuevamente.',
                            position: { horizontal: 'left' },
                        });
                    });
            }
          } catch (error) {
            console.error(error);
          }
          setToastProperties({
            show: true,
            type: 'success',
            title: 'Operación exitosa',
            message: 'Se han eliminado los cursos del B2B Exitosamente.',
            position: { horizontal: 'left' },
            });
        catalogFull();
        setSelectedDeleteCursos([]);
        refreshCatalog();
        setWaiting(false)
        setShowModalDelete(false);
    }
    // Handle para asignar curso al B2B
    const handleAssignCourse = async (formValues) => {
        //event.preventDefault();
        setWaiting(true)
        let duration = 0;
        try {
            for (let i = 0; i < selectedCursos.length; i++) { 
            fullcatalog.forEach((data) => {
                if(data.courseId === selectedCursos[i]){
                    duration = data.duration;
                }
            });
              await handleFetch({
                url: `bo/b2bcatalog`,
                method: 'POST',
                data: {
                    companyId: params.id,
                    courseId: selectedCursos[i],
                    duration: duration,
                    price: formValues.pricecourse,
                    canCertificate: true
                },
                })
                .then((response) => {
                    console.log(response);
                })
                .catch((error) => {
                    setToastProperties({
                        show: true,
                        type: 'error',
                        message: 'Error al agregar cursos al B2B. Intenta nuevamente.',
                        position: { horizontal: 'left' },
                    });
                });
            }
          } catch (error) {
            console.error(error);
          }
          setToastProperties({
            show: true,
            type: 'success',
            title: 'Operación exitosa',
            message: 'Se ha añadido curso exitosamente al B2B.',
            position: { horizontal: 'left' },
        });
        setSelectedCursos([]);
        refreshCatalog();
        setWaiting(false)
        setShowModalUpdated(false);
    }
    // Handle para asignar usuario al B2B
    const handleCreateUser = (formValues) => {
        let url = parseInt(params.id)!==1 ? `bo/b2buser`: `bo/eanxuser`
        handleFetch({
            url: url,
            method: 'POST',
            data: getRequestData(formValues),
        })
            .then(() => {
                setToastProperties({
                    show: true,
                    type: 'success',
                    title: 'Operación exitosa',
                    message: 'Se ha añadido el usuario exitosamente al B2B.',
                    position: { horizontal: 'left' },
                });
                clearAll();
                refreshUsers();
                setShowModalCreateUser(false);
            })
            .catch(() => {
                setToastProperties({
                    show: true,
                    type: 'error',
                    message: 'Error al agregar usuario al B2B. Intenta nuevamente.',
                    position: { horizontal: 'left' },
                });
            });
    }

    // Handle para asignar segmentación al B2B
    const handleCreateSegment = (formValues) => {
        const typeExists = segment.some((item) => parseInt(item.type) === parseInt(formValues.type));
        if((parseInt(getValues('limitusersseg'))>=maxUsersAdd)){
            setToastProperties({
                show:true,
                type:'error',
                message: "El número de usuarios debe ser menor al máximo disponible",
                position: {horizontal:'left'},
            }) 
        }else if (typeExists) {
            setToastProperties({
                show:true,
                type:'error',
                message: 'El valor Tipo de usuario ya existe en el uno de los segmentos',
                position: {horizontal:'left'},
            })
        }else{
            handleFetch({
                url: 'bo/segment' + (editSegment ? `/${idEditSegment}` : ''),
                method: editSegment ? 'PATCH' : 'POST', 
                data: getRequestDataSegment(formValues),
                headers: {Authorization: `Bearer ${authToken}`} 
            })
            .then(r=>{
                setToastProperties({
                    show:true,
                    type:'success',
                    title: 'Operación exitosa',
                    message: 'La segmentación se guardó correctamente.',
                    position: {horizontal:'left'},
                })
                refreshSegments();
            })
            .catch((error) => {
                console.log(error);
                let message = 'Error al guardar la segmentación. Intenta nuevamente.'
                if(error?.errors[1]){
                    message = 'El tipo de usuario ya existe en esta segmentación. Intenta nuevamente.'
                }
                    setToastProperties({
                        show:true,
                        type:'error',
                        message: message,
                        position: {horizontal:'left'},
                    })                  
            });
            clearAll();
            setShowModalCreateSegment(false);
        }
    }
    // Handle para asignar plan al B2B
    const handleCreatePlan = (formValues) => {
        handleFetch({
                url: 'bo/plans' + (editPlan ? `/${idEditPlan}` : ''),
                method: editPlan ? 'PATCH' : 'POST', 
                data: getRequestDataPlan(formValues),
                headers: {Authorization: `Bearer ${authToken}`} 
            })
            .then(r=>{
                setToastProperties({
                    show:true,
                    type:'success',
                    title: 'Operación exitosa',
                    message: 'El plan se guardó correctamente.',
                    position: {horizontal:'left'},
                })
                refreshPlans();
            })
            .catch((error) => {
                console.log(error);
                if(error.code===409){
                   setToastProperties({
                    show:true,
                    type:'error',
                    message: error.message,
                    position: {horizontal:'left'},
                   })
                }else{
                    setToastProperties({
                        show:true,
                        type:'error',
                        message: 'Error al guardar el plan. Intenta nuevamente.',
                        position: {horizontal:'left'},
                        })
                    }
            });
            clearAll();
            setShowModalCreatePlan(false);
    }
    // Handle para editar curso del B2B
    const handleEditCourse = (formValues) => {
        handleFetch({
                url: 'bo/b2bcatalog' + (editPlan ? `/${idEditPlan}` : ''),
                method: 'PATCH',
                data: {
                    price: formValues.pricecourse,
                    canCertificate: formValues.canCertificate
                }
            })
            .then(r=>{
                setToastProperties({
                    show:true,
                    type:'success',
                    title: 'Operación exitosa',
                    message: 'El curso se guardó correctamente.',
                    position: {horizontal:'left'},
                })
                refreshCatalog();
            })
            .catch((error) => {
                console.log(error);
                if(error.code===409){
                   setToastProperties({
                    show:true,
                    type:'error',
                    message: error.message,
                    position: {horizontal:'left'},
                   })
                }else{
                    setToastProperties({
                        show:true,
                        type:'error',
                        message: 'Error al guardar el curso. Intenta nuevamente.',
                        position: {horizontal:'left'},
                        })
                    }
            });
            clearAll();
            setShowModalEditCourse(false);
    }
    const handleSelectTipeDocumentChange = (selectedOption) => {
        if (selectedOption.target.value === 'DIE' || selectedOption.target.value === 'PA' || selectedOption.target.value === 'TI') {
            setShowAge(true);
        } else {
            setShowAge(false);
        }
        if (selectedOption.target.value === 'PA') {
            setDocPattern(alphanumericPattern);
            setDocMessage(alphanumericMessage);
        } else {
            setDocPattern(numericPattern);
            setDocMessage(numericMessage);
        }
        //trigger('userDocument');
    }
    // Header para historial de planes
    const headers = [
        {
            "name": "FECHA CREACIÓN",
            "width": "15%"
        },
        {
            "name": "NOMBRE DEL PLAN",
            "width": "30%"
        },
        {
            "name": "DURACIÓN",
            "width": "15%"
        },
        {
            "name": "PRECIO",
            "width": "15%"
        },
        {
            "name": "ACCIONES",
            "width": "20%"
        }
    ];
    // Header para historial de cursos
    const headercourses = [
        {
            "name": "CREACIÓN INICIO",
            "width": "12%"
        },
        {
            "name": "NOMBRE DEL CURSO",
            "width": "30%"
        },
        {
            "name": "INSTRUCTOR",
            "width": "16%"
        },
        {
            "name": "DURACIÓN",
            "width": "12%"
        },
        {
            "name": "PRECIO",
            "width": "12%"
        },
        {
            "name": "ACCIONES",
            "width": "18%"
        }
    ];
    // Header para segmentación de usuarios
    const headersegment = [
        {
            "name": "FECHA CREACIÓN",
            "width": "12%"
        },
        {
            "name": "NOMBRE SEGMENTACIÓN",
            "width": "25%"
        },
        {
            "name": "PLAN ASIGNADO",
            "width": "20%"
        },
        {
            "name": "TIPO DE USUARIO",
            "width": "12%"
        },
        {
            "name": "N° USUARIO",
            "width": "12%"
        },
        {
            "name": "ACCIONES",
            "width": "20%"
        }
    ];
    // Header para listado de usuarios
    const headersusers = [
        {
            "name": "NOMBRE COMPLETO",
            "sort_name": "title",
            "width": "20%"
        },
        {
            "name": "CORREO ELECTRÓNICO",
            "sort_name": "enabled",
            "width": "25%"
        },
        {
            "name": "NÚMERO DE DOCUMENTO",
            "sort_name": "document",
            "width": "20%"
        },
        {
            "name": "TIPO DE USUARIO",
            "sort_name": "segment",
            "width": "20%"
        },
        {
            "name": "FECHA INSCRIPCIÓN",
            "sort_name": "highlighted",
            "width": "15%"
        }
    ];

    // Función para mostrar duración de cursos
    const formatDuration = (duration) => {
        if (duration === null) return null;
        const hours = Math.floor(duration / 60);
        const minutes = duration % 60;
        return `${hours}h ${minutes}m`;
    }
    const handleCloseModal = () => {
        setShowModalUpdated(false);
    }
    const handleCloseModalCreateSegment = () => {
        setShowModalCreateSegment(false);
    }
    const handleCloseModalCreatePlan = () => {
        setShowModalCreatePlan(false);
        clearAll();
    }
    const handleCloseModalEditCourse = () => {
        setShowModalEditCourse(false);
        clearAll();
    }
    const handleCloseModalCreateUser = () => {
        setShowModalCreateUser(false);        
        clearAll();
    }
    const handleCloseModalCreateUserBatch = () => {
        setShowModalCreateUserBatch(false);
    }
    // Función para retornar datos obtenidos en formulario
    const getRequestData = (formValues) => {
        return {
            name: formValues.firstName,
            lastname: formValues.lastName,
            email: formValues.userEmail,
            identificationType: formValues.documentType,
            identificationNumber: formValues.userDocument,
            type: parseInt(formValues.typesegment)!==0 ? formValues.typesegment : formValues.type,
            companyId: params.id
        }
    }  
    // Función para retornar datos obtenidos en formulario
    const getRequestDataPlan = (formValues) => {
        return {
            title: formValues.title,
            duration: formValues.duration,
            durationPeriod: formValues.durationPeriod,
            price: formValues.price,
            durationText: 'Plan creado para B2B de '+formValues.name,
            description: formValues.descriptionplan,
            certifications: 0,
            enabled: false,
            highlighted: false,
            wait_period: null,
            duration_text: 'Plan de '+formValues.name,
            call_to_action_text: 'Plan de '+formValues.name,
            call_to_action_style: 0,
            canCertificate: true,
            type: 1,
            typeplan: 4,
            companyId: params.id,
            dateActivate: new Date(),
            dateDesactivate: '2030-12-31'
        }
    }
    // Función para retornar datos obtenidos en formulario
    const getRequestDataSegment = (formValues) => {
        return {
            name: formValues.namesegment,
            description: formValues.descriptionsegment,
            type: formValues.typesegment,
            planId: formValues.plansegment,
            companyId: params.id,
            limitusers: formValues.limitusersseg,
            urlb2b: formValues.urlb2b
        }
    }  
    const getDuration = plan => {
        let d = plan.duration.toString();
        switch(plan.durationPeriod) {
          case DURATION_DAYS: // Días
            d+=" día"+((plan.duration>1)?"s":"");
            break;
          case DURATION_WEEKS: // Semanas
            d+=" semana"+((plan.duration>1)?"s":"");
            break;
          case DURATION_MONTHS: // Meses
            d+=" mes"+((plan.duration>1)?"es":"");
            break;
          case DURATION_YEARS: // Años
            d+=" año"+((plan.duration>1)?"s":"");
            break;
          default:
            break;
        }
        return d;
      };
    const handleDeleteCourse = (id, event) => {
        event.preventDefault();
        handleFetch({
            url: `bo/b2bcatalog/${id}`,
            method: 'DELETE',
        })
            .then(() => {
                setToastProperties({
                    show: true,
                    type: 'success',
                    title: 'Operación exitosa',
                    message: 'Se ha eliminado el curso del B2B Exitosamente.',
                    position: { horizontal: 'left' },
                });
                catalogFull();
                refreshCatalog();
                setShowModalUpdated(false);
            })
            .catch(() => {
                setToastProperties({
                    show: true,
                    type: 'error',
                    message: 'Error al eliminar el curso del B2B. Intenta nuevamente.',
                    position: { horizontal: 'left' },
                });
            });
        setShowModalUpdated(false);
    }
    const handleDeletePlan = (id, event) => {
        event.preventDefault();
        handleFetch({
            url:`bo/plans/${id}`,
            method: 'DELETE',            
            headers: {Authorization: `Bearer ${authToken}`} 
        }).then(() => {
            setToastProperties({
                show: true,
                type: 'success',
                title: 'Operación exitosa',
                message: 'Se ha eliminado el plan del B2B Exitosamente.',
                position: { horizontal: 'left' },
            });
            refreshPlans();
        })
        .catch(() => {
            setToastProperties({
                show: true,
                type: 'error',
                message: 'Error al eliminar el plan del B2B. Intenta nuevamente.',
                position: { horizontal: 'left' },
            });
        });
    }
    const handleDeleteSegment = (id, event) => {
        event.preventDefault();
        handleFetch({
            url: `bo/segment/${id}`,
            method: 'DELETE',            
            headers: {Authorization: `Bearer ${authToken}`} 
        })
            .then(() => {
                setToastProperties({
                    show: true,
                    type: 'success',
                    title: 'Operación exitosa',
                    message: 'Se ha eliminado la segmentación del B2B Exitosamente.',
                    position: { horizontal: 'left' },
                });
                refreshSegments();
            })
            .catch(() => {
                setToastProperties({
                    show: true,
                    type: 'error',
                    message: 'Error al eliminar la segmentación seleccionada. Intenta nuevamente.',
                    position: { horizontal: 'left' },
                });
            });
    }
    const handleCSVUpload = async (data) => {
        let band = false     
        const updatedValues = [];
        const errorValues = [];
        if(data[0].includes('name') && data[0].includes('lastname') && data[0].includes('email') && data[0].includes('identificationType') 
        && data[0].includes('identificationNumber') && data[0].includes('type')){
            if(parseInt(params.id)!==1 && data[0].includes('companyId') ){
                band=true;
            }else if(parseInt(params.id)===1){
                band=true;
            }else{
                setToastProperties({
                    show: true,
                    type: 'error',
                    message: 'Error en formato del CSV, revisa que el formato sea el correcto.',
                    position: { horizontal: 'left' },
                });
            }
            if(band===true){
                setToastProperties({
                    show: true,
                    type: 'success',
                    title: 'Formato del CSV es Correcto',
                    message: 'Se está procesando la carga del archivo, por favor espere',
                    position: { horizontal: 'left' },
                });
                try {
                    for (let i = 1; i < data.length; i++) {
                      const row = data[i];
                      await handleFetch({
                        url: parseInt(params.id)!==1 ? `bo/b2buser`: `bo/eanxuser`,
                        method: 'POST',
                        data: parseInt(params.id)!==1 ? { name: row[0],
                                lastname: row[1],
                                email: row[2],
                                identificationType: row[3],
                                identificationNumber: row[4],
                                type: row[5] || 1,
                                companyId: row[6] } : 
                                { name: row[0],
                                    lastname: row[1],
                                    email: row[2],
                                    identificationType: row[3],
                                    identificationNumber: row[4],
                                    type: row[5] || 2}
                        })
                        .then((response) => {
                            console.log(response);
                            updatedValues.push({
                                name: response.name,
                                lastname: response.lastname,
                                email: response.email,
                                operation: 'USUARIO REGISTRADO EXITOSAMENTE',
                            });
                        })
                        .catch((error) => {
                            console.log("ERROR",error)
                            if(error?.errors[0]?.message === "email must be unique"){
                                setToastProperties({
                                    show: true,
                                    type: 'error',
                                    message: 'Existen usuarios en el CSV ya registrados en Base de datos.',
                                    position: { horizontal: 'left' },
                                });
                            }else{
                                errorValues.push({
                                    name: row[0],
                                    lastname: row[1],
                                    email: row[2],
                                    operation: 'ERROR AL REGISTRAR USUARIO',
                                  });
                                setToastProperties({
                                    show: true,
                                    type: 'error',
                                    message: 'Error al agregar usuarios al B2B. Intenta nuevamente.',
                                    position: { horizontal: 'left' },
                                });
    
                            }
                        });
                    }
                  } catch (error) {
                    console.error(error);
                  }
                const csvData = [...updatedValues, ...errorValues];
                const csvContent = Papa.unparse(csvData, {
                quotes: true,
                quoteChar: '"',
                });
                const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'usuarios_lote_asignados.csv');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                refreshUsers();
                setShowModalCreateUserBatch(false);
            }
        }
      }
    // Tipos de usuarios
    const usertypes = [
        {
            "id": "2",
            "name": "Estudiantes Eanistas",
        }, {
            "id": "3",
            "name": "Docente de Planta",
        }, {
            "id": "4",
            "name": "Docente de Cátedra",
        }, {
            "id": "5",
            "name": "Colaborador",
        }, {
            "id": "6",
            "name": "Egresado",
        }
    ];
    const ContentList = ({ list, title }) => {
        return (
            <>
                <div className='text-center'>
                    {title}
                </div>
                {list?.map((b, index) => {
                    return (
                        <div className='row mb-1' key={index}>
                            <div className='col-3 B2BAdmin__text-description'>ID: {b.type ? b.type: b.id}</div>
                            <div className='col-9 B2BAdmin__text-description'>{b.name}</div>
                        </div>
                    )
                })}
            </>
        )
    }
    return (
        <>
            <Link to='/admin/b2b' className='px-4 px-sm-5 text_primary fw-bold'>Volver a B2B</Link>
            <Toast {...toastProperties} closeCallback={afterCloseToast} ></Toast>
            <Modal
                open={showModalUpdated}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className='B2B__modal-base ProfilePage__outline-none p-4'>
                    <form onSubmit={handleSubmit(handleAssignCourse)}>
                        <div className='row justify-content-center align-items-center'>
                            <div className='col-12'>
                                <div className='AcceptDialog__body'>Agregar Cursos a Empresa B2B</div>
                                <div className='B2BAdmin__framecourses'>
                                    <div className='d-flex'>
                                        <input
                                            type="checkbox"
                                            checked={selectAll}
                                            onChange={handleSelectAll}
                                        /> <label className='mx-2 fw-bold fs-8 B2BAdmin__divcourse'>Seleccionar Todos</label>
                                    </div>
                                    {fullcatalog.map((curso) => (
                                        <div key={curso.courseId} className='d-flex'>
                                        <input
                                            type="checkbox"
                                            id={curso.courseId}
                                            checked={selectedCursos.includes(curso.courseId)}
                                            onChange={() => handleCursoChange(curso.courseId)}
                                        />
                                        <label htmlFor={curso.courseId} className='mx-2 fs-8 B2BAdmin__divcourse'>
                                            {curso.name}
                                        </label>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className='mt-3 mb-3'>
                                    <div className='row'>
                                        <div className="col-6">
                                        <label className="form-label auth__form-label">Costo Actual</label>
                                        <div className="input-group">
                                            <input 
                                                placeholder={CONSTANTS.LABEL_PLAN_PRICE_PLACEHOLDER}
                                                type="number"
                                                {...register("pricecourse",{
                                                    required:CONSTANTS.MESSAGE_PLAN_PRICE_REQUIRED,
                                                    pattern: {
                                                        value: CONSTANTS.PATTERN_FLOAT,
                                                        message: CONSTANTS.MESSAGE_PLAN_PRICE_INVALID
                                                    }})}  
                                                className={`form-control PlanFormContainer__input ${errors.pricecourse?'PlanFormContainer__input--error':''}`}
                                            >
                                            </input>
                                            <span className="input-group-text">COP</span>
                                        </div>
                                        </div>
                                        <div className="col-6">
                                            <label className="form-label auth__form-label">Certificable</label>
                                            <select {...register("canCertificate")} className="form-select PlanFormContainer__input">
                                                <option value='true'>SI</option>
                                                <option value='false'>NO</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            <div className='ProfilePage__modal-button-container d-flex justify-content-center'>
                                <div className='row w-100'>
                                    <div className="col-12 col-md-6"><button className='B2B__ActionButton ActionButton--primary' type="submit" disabled={waiting}>Agregar cursos {waiting && <>&nbsp;<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></>}</button></div>
                                    <div className="col-12 col-md-6"><button className='B2B__ActionButton ActionButton--secondary' onClick={handleCloseModal}>Cancelar</button></div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal>

            <Modal
                open={showModalDelete}
                onClose={handleCloseDeleteModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className='B2B__modal-base ProfilePage__outline-none p-4'>
                    <form onSubmit={handleDeleteCourseLote}>
                        <div className='row justify-content-center align-items-center'>
                            <div className='col-12'>
                                <div className='AcceptDialog__body'>Eliminar Cursos a Empresa B2B</div>
                                <div className='B2BAdmin__framecourses'>
                                    <div className='d-flex'>
                                        <input
                                            type="checkbox"
                                            checked={selectDeleteAll}
                                            onChange={handleSelectDeleteAll}
                                        /> <label className='mx-2 fw-bold fs-8 B2BAdmin__divcourse'>Seleccionar todos</label>
                                    </div>
                                    {courses.map((curso) => (
                                        <div key={curso.courseId} className='d-flex'>
                                        <input
                                            type="checkbox"
                                            id={curso?.id}
                                            checked={selectedDeleteCursos.includes(curso.id)}
                                            onChange={() => handleDeleteCursoChange(curso.id)}
                                        />
                                        <label htmlFor={curso.courseId} className='mx-2 fs-8 B2BAdmin__divcourse'>
                                            {curso['course.name']}
                                        </label>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className='ProfilePage__modal-button-container d-flex justify-content-center'>
                                <div className='row w-100'>
                                    <div className="col-12 col-md-6"><button className='B2B__ActionButton ActionButton--primary' type="submit"  disabled={waiting}>Eliminar cursos {waiting && <>&nbsp;<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></>}</button></div>
                                    <div className="col-12 col-md-6"><button className='B2B__ActionButton ActionButton--secondary' onClick={handleCloseDeleteModal}>Cancelar</button></div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal>

            <Modal
                open={showModalCreatePlan}
                onClose={handleCloseModalCreatePlan}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className='B2B__modal-base ProfilePage__outline-none p-4'>
                    <form onSubmit={handleSubmit(handleCreatePlan)}>
                        <div className='row justify-content-center align-items-center'>
                            <div className='col-12'>
                                <div className='AcceptDialog__body'>Agregar Plan a Empresa B2B</div>
                                <div className='mb-3'>
                                    <label className="form-label auth__form-label">Título <span className='auth__required-input'>*</span></label>
                                    <input 
                                        placeholder={CONSTANTS.LABEL_PLAN_TITLE_PLACEHOLDER}
                                        {...register("title",{required:CONSTANTS.MESSAGE_PLAN_TITLE_REQUIRED})} 
                                        className={`form-control PlanFormContainer__input ${errors.title?'PlanFormContainer__input--error':''}`}>
                                    </input>
                                    {errors.title && (
                                        <span role="alert" className='auth__inputError'>
                                            {errors.title.message}
                                        </span>
                                    )}
                                </div>
                                <div className='mb-3'>
                                    <div className='row'>
                                        <div className="col-6">
                                            <label className="form-label auth__form-label">Duración</label>
                                            <input 
                                                placeholder={CONSTANTS.LABEL_PLAN_DURATION_PLACEHOLDER}
                                                type="number"
                                                {...register("duration",{
                                                    required:CONSTANTS.MESSAGE_PLAN_DURATION_REQUIRED,
                                                    pattern: {
                                                        value: CONSTANTS.PATTERN_FLOAT,
                                                        message: CONSTANTS.MESSAGE_PLAN_DURATION_INVALID
                                                    }})}  
                                                className={`form-control PlanFormContainer__input ${errors.duration?'PlanFormContainer__input--error':''}`}
                                            ></input>
                                        </div>
                                        <div className="col-6">
                                            <label className="form-label auth__form-label">Tiempo</label>
                                            <select {...register("durationPeriod")} className="form-select PlanFormContainer__input">
                                                <option value='1'>Días</option>
                                                <option value='2'>Semanas</option>
                                                <option value='3'>Meses</option>
                                                <option value='4'>Años</option>
                                            </select>
                                        </div>
                                                                                    
                                        {errors.duration && (
                                                <span role="alert" className='auth__inputError'>
                                                    {errors.duration.message}
                                                </span>
                                            )}
                                    </div>
                                </div>
                                <div className='mb-3'>
                                    <label className="form-label auth__form-label">Descripción</label>
                                    <textarea 
                                        placeholder={CONSTANTS.LABEL_PLAN_DESCRIPTION_PLACEHOLDER}
                                        {...register("descriptionplan",
                                        {required:CONSTANTS.MESSAGE_PLAN_DESCRIPTION_REQUIRED})}
                                        className={`form-control PlanFormContainer__input ${errors.descriptionplan?'PlanFormContainer__input--error':''}`}>
                                    </textarea>
                                    {errors.descriptionplan && (
                                        <span role="alert" className='auth__inputError'>
                                            {errors.descriptionplan.message}
                                        </span>
                                    )}
                                </div>
                                <div className='mb-3'>
                                    <label className="form-label auth__form-label">Costo Actual</label>
                                    <div className="input-group">
                                        <input 
                                            placeholder={CONSTANTS.LABEL_PLAN_PRICE_PLACEHOLDER}
                                            type="number"
                                            {...register("price",{
                                                required:CONSTANTS.MESSAGE_PLAN_PRICE_REQUIRED,
                                                pattern: {
                                                    value: CONSTANTS.PATTERN_FLOAT,
                                                    message: CONSTANTS.MESSAGE_PLAN_PRICE_INVALID
                                                }})}  
                                            className={`form-control PlanFormContainer__input ${errors.price?'PlanFormContainer__input--error':''}`}
                                        >
                                        </input>
                                        <span className="input-group-text">COP</span>
                                    </div>
                                    {errors.price && (
                                        <span role="alert" className='auth__inputError'>
                                            {errors.price.message}
                                        </span>
                                    )}
                                </div>
                            </div>
                            <div className='ProfilePage__modal-button-container d-flex justify-content-center'>
                                <div className='row w-100'>
                                    <div className="col-12 col-md-6"><button className='B2B__ActionButton ActionButton--primary' type="submit">Agregar plan</button></div>
                                    <div className="col-12 col-md-6"><button className='B2B__ActionButton ActionButton--secondary' onClick={handleCloseModalCreatePlan}>Cancelar</button></div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal>

            <Modal
                open={showModalCreateSegment}
                onClose={handleCloseModalCreateSegment}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className='B2B__modal-base ProfilePage__outline-none p-4'>
                    <form onSubmit={handleSubmit(handleCreateSegment)}>
                        <div className='row justify-content-center align-items-center'>
                            <div className='col-12'>
                                <div className='AcceptDialog__body'>Agregar Segmentación para B2B</div>
                                <div className='mb-3'>
                                    <label className="form-label auth__form-label">Nombre <span className='auth__required-input'>*</span></label>
                                    <input 
                                        placeholder={CONSTANTS.LABEL_SEGMENT_NAME_PLACEHOLDER}
                                        {...register("namesegment",{required:CONSTANTS.MESSAGE_NAME_SEGMENT_REQUIRED})} 
                                        className={`form-control PlanFormContainer__input ${errors.namesegment?'PlanFormContainer__input--error':''}`}>
                                    </input>
                                    {errors.namesegment && (
                                        <span role="alert" className='auth__inputError'>
                                            {errors.namesegment.message}
                                        </span>
                                    )}
                                </div>
                                <div className='mb-3'>
                                    <label className="form-label auth__form-label">Descripción</label>
                                    <textarea 
                                        placeholder={CONSTANTS.LABEL_SEGMENT_DESCRIPTION_PLACEHOLDER}
                                        {...register("descriptionsegment",
                                        {required:CONSTANTS.MESSAGE_PLAN_DESCRIPTION_REQUIRED})}
                                        className={`form-control PlanFormContainer__input ${errors.descriptionsegment?'PlanFormContainer__input--error':''}`}>
                                    </textarea>
                                    {errors.descriptionsegment && (
                                        <span role="alert" className='auth__inputError'>
                                            {errors.descriptionsegment.message}
                                        </span>
                                    )}
                                </div>
                                <div className='mb-3'>
                                    <label className="form-label auth__form-label">Tipo de usuario</label>
                                    <input 
                                        type="number"
                                        {...register("typesegment",{                                            
                                            required:CONSTANTS.MESSAGE_TYPE_SEGMENT_REQUIRED,
                                            pattern: {
                                                value: CONSTANTS.PATTERN_FLOAT,
                                                message: CONSTANTS.MESSAGE_PLAN_DURATION_INVALID
                                            }})}  
                                        className={`form-control PlanFormContainer__input ${errors.typesegment?'PlanFormContainer__input--error':''}`}
                                    ></input>
                                    {errors.typesegment && (
                                        <span role="alert" className='auth__inputError'>
                                            {errors.typesegment.message}
                                        </span>
                                    )}
                                </div>
                                <div className='mb-3'>
                                    <label className="form-label auth__form-label">Límite de usuarios para segmentación <span className='auth__required-input'>(Máximo a añadir {maxUsersAdd})</span></label>
                                    <input 
                                        type="number"
                                        {...register("limitusersseg",{                                            
                                            required:CONSTANTS.MESSAGE_LIMIT_NUMBER_USERS_REQUIRED,
                                            pattern: {
                                                value: CONSTANTS.PATTERN_FLOAT,
                                                message: CONSTANTS.MESSAGE_PLAN_DURATION_INVALID
                                            }})}  
                                        className={`form-control PlanFormContainer__input ${errors.limitusersseg?'PlanFormContainer__input--error':''}`}
                                    ></input>
                                    {errors.limitusersseg && (
                                        <span role="alert" className='auth__inputError'>
                                            {errors.limitusersseg.message}
                                        </span>
                                    )}
                                </div>
                                <div className='mb-3'>
                                    <label className="form-label auth__form-label">Plan para segmentación</label>
                                    <select
                                        defaultValue=""
                                        className={`form-select auth__select 
                                ${errors.typesegment ? "auth__input--error" : ""}`}
                                        aria-label="Default select example"
                                        name='plansegment'
                                        {...register("plansegment", {
                                            required:CONSTANTS.MESSAGE_PLAN_SEGMENT_REQUIRED,
                                            onChange: handleSelectTipeDocumentChange
                                        })}
                                    >
                                        <option value="">Seleccione un plan para esta segmentación</option>
                                        {
                                            plans.length !== 0 &&
                                            plans.sort((a, b) => a?.title?.localeCompare(b?.title))
                                            .map((data, index) => {
                                                    return(
                                                        <option value={data?.id} key={data?.id}>{data?.title}</option>
                                                    );
                                                })
                                        }
                                    </select>
                                    {errors.plansegment && (
                                        <span role="alert" className='auth__inputError'>
                                            {errors.plansegment.message}
                                        </span>
                                    )}
                                </div>
                            </div>
                            <div className='ProfilePage__modal-button-container d-flex justify-content-center'>
                                <div className='row w-100'>
                                    <div className="col-12 col-md-6"><button className='B2B__ActionButton ActionButton--primary' type="submit">Agregar segmentación</button></div>
                                    <div className="col-12 col-md-6"><button className='B2B__ActionButton ActionButton--secondary' onClick={handleCloseModalCreateSegment}>Cancelar</button></div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal>
            <Modal
                open={showModalCreateUser}
                onClose={handleCloseModalCreateUser}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className='B2B__modal-base ProfilePage__outline-none p-4'>
                    <form onSubmit={handleSubmit(handleCreateUser)}>
                        <div className='row justify-content-center align-items-center'>
                            <div className='col-12'>
                                <div className='AcceptDialog__body'>Agregar Usuario a Empresa B2B</div>
                                <div className="mb-3">
                                    <label className='auth__form-label'>Nombres <span className='auth__required-input'>*</span></label>
                                    <input
                                        type='text'
                                        name='firstName'
                                        className={`form-control auth__input 
                                ${errors.firstName ? "auth__input--error" : ""}`}
                                        placeholder={CONSTANTS.LABEL_NAME_PLACEHOLDER}
                                        {...register("firstName", {
                                            required: CONSTANTS.MESSAGE_NAME_REQUIRED,
                                            pattern: {
                                                value: namePattern,
                                                message: CONSTANTS.MESSAGE_ONLY_ALPHABET,
                                            }
                                        })}
                                    />
                                    {errors.firstName && (
                                        <span role="alert" className='auth__inputError'>
                                            {errors.firstName.message}
                                        </span>
                                    )}
                                </div>
                                <div className="mb-3">
                                    <label className='auth__form-label'>Apellidos <span className='auth__required-input'>*</span></label>
                                    <input
                                        type='text'
                                        name='lastName'
                                        className={`form-control auth__input 
                                ${errors.lastName ? "auth__input--error" : ""}`}
                                        placeholder={CONSTANTS.LABEL_LASTNAME_PLACEHOLDER}
                                        {...register("lastName", {
                                            required: CONSTANTS.MESSAGE_LASTNAME_REQUIRED,
                                            pattern: {
                                                value: namePattern,
                                                message: CONSTANTS.MESSAGE_ONLY_ALPHABET,
                                            }
                                        })}
                                    />
                                    {errors.lastName && (
                                        <span role="alert" className='auth__inputError'>
                                            {errors.lastName.message}
                                        </span>
                                    )}
                                </div>
                                
                                <div className="mb-3">
                                    <label className='auth__form-label'>Correo <span className='auth__required-input'>*</span></label>
                                    <input
                                        type='text'
                                        name='userEmail'
                                        className={`form-control auth__input 
                                ${errors.userEmail ? "auth__input--error" : ""}`}
                                        placeholder={CONSTANTS.LABEL_EMAIL_PLACEHOLDER}
                                        {...register("userEmail", {
                                            required: CONSTANTS.MESSAGE_EMAIL_REQUIRED,
                                            pattern: {
                                                value: CONSTANTS.PATTERN_EMAIL,
                                                message: CONSTANTS.MESSAGE_EMAIL_INVALID
                                            }
                                        })}
                                    />
                                    {errors.userEmail && (
                                        <span role="alert" className='auth__inputError'>
                                            {errors.userEmail.message}
                                        </span>
                                    )}
                                </div>
                                <div className="mb-3">
                                    <label className='auth__form-label'>Tipo de documento <span className='auth__required-input'>*</span></label>
                                    <select
                                        defaultValue=""
                                        className={`form-select auth__select 
                                ${errors.documentType ? "auth__input--error" : ""}`}
                                        aria-label="Default select example"
                                        name='documentType'
                                        {...register("documentType", {
                                            required: CONSTANTS.MESSAGE_DOCUMENT_TYPE_REQUIRED,
                                            onChange: handleSelectTipeDocumentChange
                                        })}
                                    >
                                        <option value="">Selecciona el tipo de documento</option>
                                        <option value="CC">Cédula de Ciudadanía</option>
                                        <option value="CE">Cédula de Extranjería</option>
                                        <option value="DIE">Documento de Identidad Extranjera</option>
                                        <option value="PA">Pasaporte</option>
                                        <option value="TI">Tarjeta de Identidad</option>
                                        <option value="PEP">Permiso Especial de Permanencia</option>
                                    </select>
                                    {errors.documentType && (
                                        <span role="alert" className='auth__inputError'>
                                            {errors.documentType.message}
                                        </span>
                                    )}
                                </div>
                                {
                                    showAge ? (
                                        <div className='show-form-age'>
                                            <div className="form-check">
                                                <input className="form-check-input auth__form-check"
                                                    type="checkbox"
                                                    value=""
                                                    {...register("olderThan12", { required: CONSTANTS.MESSAGE_OLDER_THAN_12_REQUIRED })}
                                                />
                                                <label className='form-check-label auth__form-label' style={{ fontSize: "12px" }}>
                                                    Declaro ser mayor de 12 años.
                                                </label>
                                            </div>
                                            <div>
                                                <label className='form-check-label auth__form-label' style={{ fontSize: "12px", paddingBottom: "12px" }}>
                                                    Si eres menor de 12 años requieres autorización expresa de tus padres o tutores,
                                                    pídeles que se comuniquen con nosotros <Link to='/contactanos' className='text_primary'>aquí.</Link>
                                                </label>
                                            </div>
                                            {errors.olderThan12 && (
                                                <div role="alert" className='auth__inputError'>
                                                    {errors.olderThan12.message}
                                                </div>
                                            )}
                                        </div>
                                    ) : null
                                }
                                <div className="mb-3">
                                    <label className='auth__form-label'>Número de documento <span className='auth__required-input'>*</span></label>
                                    <input
                                        type='text'
                                        name='userDocument'
                                        className={`form-control auth__input 
                                ${errors.userDocument ? "auth__input--error" : ""}`}
                                        placeholder={CONSTANTS.LABEL_DOCUMENT_PLACEHOLDER}
                                        {...register("userDocument", {
                                            required: CONSTANTS.MESSAGE_DOCUMENT_REQUIRED,
                                            pattern: {
                                                value: docPattern,
                                                message: docMessage
                                            }
                                        })}
                                    />
                                    {errors.userDocument && (
                                        <span role="alert" className='auth__inputError'>
                                            {errors.userDocument.message}
                                        </span>
                                    )}
                                </div>
                                {parseInt(params.id) === 1 && <div className="mb-3">
                                    <label className='auth__form-label'>Tipo de usuario <span className='auth__required-input'>*</span></label>
                                    <select
                                        defaultValue=""
                                        className={`form-select auth__select 
                                ${errors.documentType ? "auth__input--error" : ""}`}
                                        aria-label="Default select example"
                                        name='type'
                                        {...register("type", {
                                            onChange: handleSelectTipeDocumentChange
                                        })}
                                    >
                                        <option value="2">Estudiante Eanista</option>
                                        <option value="3">Docente de Planta</option>
                                        <option value="4">Docente de Cátedra</option>
                                        <option value="5">Colaborador</option>
                                        <option value="6">Egresado</option>
                                    </select>
                                    {errors.type && (
                                        <span role="alert" className='auth__inputError'>
                                            {errors.type.message}
                                        </span>
                                    )}
                                </div>}
                                {parseInt(params.id) !== 1 && <div className='mb-3'>
                                    <label className="form-label auth__form-label">Tipo de usuario (Para segmentación de cursos)</label>
                                    <select
                                        defaultValue=""
                                        className={`form-select auth__select 
                                ${errors.typesegment ? "auth__input--error" : ""}`}
                                        aria-label="Default select example"
                                        name='typesegment'
                                        {...register("typesegment", {
                                            onChange: handleSelectTipeDocumentChange
                                        })}
                                    >
                                        <option value="">Seleccione un tipo de usuario</option>
                                        {
                                            segment.length !== 0 &&
                                            segment.map((data, index)=>{
                                                    return(
                                                        <option value={data?.type} key={data?.type}>{data?.name}</option>
                                                    );
                                                })
                                        }
                                    </select>
                                    {errors.typesegment && (
                                        <span role="alert" className='auth__inputError'>
                                            {errors.typesegment.message}
                                        </span>
                                    )}
                                </div>}
                            </div>
                            <div className='ProfilePage__modal-button-container d-flex justify-content-center'>
                                <div className='row w-100'>
                                    <div className="col-12 col-md-6"><button className='B2B__ActionButton ActionButton--primary' type="submit">Agregar usuario</button></div>
                                    <div className="col-12 col-md-6"><button className='B2B__ActionButton ActionButton--secondary' onClick={handleCloseModalCreateUser}>Cancelar</button></div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal>

            <Modal
                open={showModalCreateUserBatch}
                onClose={handleCloseModalCreateUserBatch}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className='B2B__modal-base ProfilePage__outline-none p-4'>
                    <div className='row justify-content-center align-items-center'>
                        <div className='col-12'>
                            <div className='AcceptDialog__body'>Agregar Usuarios Por Lote a Empresa B2B</div>
                            <div className="mt-2 mb-2">Haga clic en <a href={apiConfig.mercadoPago.returnUrl.replace('cuenta-creada',params.id!==1?'csv-b2b-lote.csv':'csv-eanistas-lote.csv')} className="text_primary fw-bold B2BAdmin__link-to-csv">CSV</a> para descargar la plantilla en la que podrá incluir a los usuarios</div>
                        </div>
                        <div className='col-12'>
                            <div className="mt-1 mb-3 B2BAdmin__text-description">Para ver el listado de tipos de usuario y Códigos por Empresa haz <Link to={{}} className="text_primary fw-bold" onClick={handleOpenModalTypeUsersCSV}>click aquí</Link></div>
                        </div>
                        <div className='col-12'>
                            <CSVReader onFileLoaded={handleCSVUpload} />
                        </div>
                        <div className='B2BAdmin__text-description mt-3'><span className='auth__required-input'>* Esta ventana emergente se cerrará al finalizar la carga</span></div>
                    </div>
                </div>
            </Modal>

            <Modal
                open={showModalSincSap}
                onClose={handleCloseModalSincSap}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className='B2B__modal-base ProfilePage__outline-none p-4'>
                    <form onSubmit={handleSincSap}>
                        <div className='row justify-content-center align-items-center'>
                            <div className='col-12'>
                                <div className='AcceptDialog__body'>Sincronizar Base de Datos Eanista con SAP</div>
                                <div className="mt-2 mb-2 B2BAdmin__text-description"><span className='auth__required-input'>Es recomendable vaciar la base de datos eanista antes de cargar un nuevo lote, debe estar completamente seguro efectuar esta acción.</span></div>
                                <div className='mt-3 mb-3 text-center'>¿Desea continuar con la operación?</div>
                                
                            </div>
                            <div className='ProfilePage__modal-button-container d-flex justify-content-center'>
                                <div className='row w-100'>
                                    <div className="col-12 col-md-6"><button className='B2B__ActionButton ActionButton--primary' type="submit" disabled={waiting}>{waiting ? <>Sincronizando&nbsp;<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></>: 'SI'}</button></div>
                                    <div className="col-12 col-md-6"><button className='B2B__ActionButton ActionButton--secondary' onClick={handleCloseModalSincSap}>NO</button></div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal>
            <Modal
                open={showModalEmptyDBEanist}
                onClose={handleCloseModalEmptyDBEanist}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className='B2B__modal-base ProfilePage__outline-none p-4'>
                    <form onSubmit={handleEmptyDBEanist}>
                        <div className='row justify-content-center align-items-center'>
                            <div className='col-12'>
                                <div className='AcceptDialog__body'>Vaciar Base de Datos Eanista</div>
                                <div className="mt-2 mb-2 B2BAdmin__text-description"><span className='auth__required-input'>Es recomendable vaciar la base de datos eanista solo cuando sea necesario cargar un nuevo lote, debe estar completamente seguro efectuar esta acción.</span></div>
                                <div className='mt-3 mb-3 text-center'>¿Desea continuar con la operación?</div>
                                
                            </div>
                            <div className='ProfilePage__modal-button-container d-flex justify-content-center'>
                                <div className='row w-100'>
                                    <div className="col-12 col-md-6"><button className='B2B__ActionButton ActionButton--primary' type="submit">SI</button></div>
                                    <div className="col-12 col-md-6"><button className='B2B__ActionButton ActionButton--secondary' onClick={handleCloseModalEmptyDBEanist}>NO</button></div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal>
            <Modal
                open={showModalTypeUsersCSV}
                onClose={handleCloseModalTypeUsersCSV}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className='B2B__modal-base ProfilePage__outline-none p-4'>
                    
                        <div className='row justify-content-center align-items-center'>
                            <div className='col-12'>
                                <div className='AcceptDialog__body'>Tipos de Usuarios y Compañias B2B</div>
                            </div>
                            <div className='row'>
                                <div className='col-6'>
                                    <ContentList list={parseInt(params?.id)===1 ? usertypes : segment} title="type"/>
                                </div>
                                <div className='col-6'>
                                    <ContentList list={companies?.filter((item) => parseInt(item.id) === parseInt(params.id))} title="companyId"/>
                                </div>
                            </div>
                            <div className='ProfilePage__modal-button-container d-flex justify-content-center'>
                                <div className='row w-100'>
                                    <div className="col-12"><button className='B2B__ActionButton ActionButton--secondary' onClick={handleCloseModalTypeUsersCSV}>CERRAR</button></div>
                                </div>
                            </div>
                        </div>
                    
                </div>
            </Modal>
            <Modal
                open={showModalEditCourse}
                onClose={handleCloseModalEditCourse}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className='B2B__modal-base ProfilePage__outline-none p-4'>
                    <form onSubmit={handleSubmit(handleEditCourse)}>
                        <div className='row justify-content-center align-items-center'>
                            <div className='col-12'>
                                <div className='AcceptDialog__body'>Editar precio de curso</div>
                                <div className='mb-3'>
                                    <label className="form-label auth__form-label">Título <span className='auth__required-input'>*</span></label>
                                    <input 
                                        disabled
                                        placeholder={CONSTANTS.LABEL_PLAN_TITLE_PLACEHOLDER}
                                        {...register("title",{required:CONSTANTS.MESSAGE_PLAN_TITLE_REQUIRED})} 
                                        className={`form-control PlanFormContainer__input ${errors.title?'PlanFormContainer__input--error':''}`}>
                                    </input>
                                    {errors.title && (
                                        <span role="alert" className='auth__inputError'>
                                            {errors.title.message}
                                        </span>
                                    )}
                                </div>
                                <div className='row mb-3'>
                                        <div className="col-6">
                                        <label className="form-label auth__form-label">Costo Actual</label>
                                        <div className="input-group">
                                            <input 
                                                placeholder={CONSTANTS.LABEL_PLAN_PRICE_PLACEHOLDER}
                                                type="number"
                                                {...register("pricecourse",{
                                                    required:CONSTANTS.MESSAGE_PLAN_PRICE_REQUIRED,
                                                    pattern: {
                                                        value: CONSTANTS.PATTERN_FLOAT,
                                                        message: CONSTANTS.MESSAGE_PLAN_PRICE_INVALID
                                                    }})}  
                                                className={`form-control PlanFormContainer__input ${errors.pricecourse?'PlanFormContainer__input--error':''}`}
                                            >
                                            </input>
                                            <span className="input-group-text">COP</span>
                                        </div>
                                        {errors.pricecourse && (
                                        <span role="alert" className='auth__inputError'>
                                            {errors.pricecourse.message}
                                        </span>
                                    )}
                                        </div>
                                        <div className="col-6">
                                            <label className="form-label auth__form-label">Certificable</label>
                                            <select {...register("canCertificate")} className="form-select PlanFormContainer__input">
                                                <option value='true'>SI</option>
                                                <option value='false'>NO</option>
                                            </select>
                                        </div>
                                    </div>
                            </div>
                            <div className='ProfilePage__modal-button-container d-flex justify-content-center'>
                                <div className='row w-100'>
                                    <div className="col-12 col-md-6"><button className='B2B__ActionButton ActionButton--primary' type="submit">Editar curso</button></div>
                                    <div className="col-12 col-md-6"><button className='B2B__ActionButton ActionButton--secondary' onClick={handleCloseModalEditCourse}>Cancelar</button></div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal>
            <div className='text-center'><h1>Perfil de empresa</h1> </div>
            <div className='d-flex justify-content-center'>
                <div className='UserFormContainer row m-2 p-3 col-10 col-md-10'>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className='mb-3'>
                            <div className='row'>
                                <div className="col-12 col-md-6">
                                    <label className="form-label UserFormContainer__label">Nombre</label>
                                    <input
                                        name='name'
                                        disabled={parseInt(params.id)===1}
                                        placeholder={CONSTANTS.LABEL_USERB2B_PLACEHOLDER}
                                        {...register("name", { required: CONSTANTS.MESSAGE_NAMEB2B_REQUIRED })}
                                        className={`form-control UserFormContainer__input ${errors.name ? 'UserFormContainer__input--error' : ''}`}>
                                    </input>
                                    <ErrorMessage message={errors.name ? errors.name.message : ''} />
                                </div>
                                <div className="col-12 col-md-6">
                                    <label className="form-label UserFormContainer__label">Descripción</label>
                                    <input
                                        name='description'
                                        disabled={parseInt(params.id)===1}
                                        placeholder={CONSTANTS.LABEL_DESCRIPTION_PLACEHOLDER}
                                        {...register("description", { required: CONSTANTS.MESSAGE_DESCRIPTION_REQUIRED })}
                                        className={`form-control UserFormContainer__input ${errors.description ? 'UserFormContainer__input--error' : ''}`}>
                                    </input>
                                    <ErrorMessage message={errors.description ? errors.description.message : ''} />
                                </div>
                            </div>
                        </div>
                        <div className='mb-3'>
                            <div className='row'>
                                <div className="col-12 col-md-6">
                                    <label className="form-label UserFormContainer__label">Logo de la empresa</label>
                                    <label className="form-label UserFormContainer__sublabel"><img src={users?.image ? users?.image : imagesb2b('./placeholder-logo.png')} width="150" height="100%" border="0" alt="LOGO B2B"/></label>
                                </div>
                                <div className="col-12 col-md-6">
                                    <label className="form-label UserFormContainer__label">URL Imágen Logo de la Empresa</label>
                                    <input
                                        name='image'
                                        disabled={parseInt(params.id)===1}
                                        placeholder={CONSTANTS.LABEL_URLIMAGE_PLACEHOLDER}
                                        {...register("image", { required: CONSTANTS.MESSAGE_IMAGE_REQUIRED })}
                                        className={`form-control UserFormContainer__input ${errors.image ? 'UserFormContainer__input--error' : ''}`}>
                                    </input>
                                    <ErrorMessage message={errors.image ? errors.image.message : ''} />
                                </div>
                            </div>
                        </div>
                        <div className='mb-3'>
                            <div className='row'>
                                <div className="col-12 col-md-6">
                                    <label className="form-label UserFormContainer__label">Fecha de Inicio Contrato</label>
                                    <input
                                        name='startsAt'
                                        type='date'
                                        disabled={parseInt(params.id)===1}
                                        placeholder={CONSTANTS.LABEL_USERNAME_PLACEHOLDER}
                                        {...register("startsAt", { required: CONSTANTS.MESSAGE_STARTCONTRACT_REQUIRED })}
                                        className={`form-control UserFormContainer__input ${errors.startsAt ? 'UserFormContainer__input--error' : ''}`}>
                                    </input>
                                    <ErrorMessage message={errors.startsAt ? errors.startsAt.message : ''} />
                                </div>
                                <div className="col-12 col-md-6">
                                    <label className="form-label UserFormContainer__label">Fecha Fin de Contrato</label>
                                    <input
                                        name='endsAt'
                                        type='date'
                                        disabled={parseInt(params.id)===1}
                                        placeholder={CONSTANTS.LABEL_USERNAME_PLACEHOLDER}
                                        {...register("endsAt", { required: CONSTANTS.MESSAGE_ENDCONTRACT_REQUIRED })}
                                        className={`form-control UserFormContainer__input ${errors.endsAt ? 'UserFormContainer__input--error' : ''}`}>
                                    </input>
                                    <ErrorMessage message={errors.endsAt ? errors.endsAt.message : ''} />
                                </div>
                            </div>
                        </div>
                        <div className='mb-3'>
                            <div className='row'>
                                <div className="col-12 col-md-6">
                                    <label className="form-label UserFormContainer__label">Límite de usuarios</label>
                                    <input
                                        name='limitusers'
                                        type='number'
                                        disabled={parseInt(params.id)===1}
                                        placeholder={CONSTANTS.MESSAGE_LIMIT_NUMBER_USERS_REQUIRED}
                                        {...register("limitusers", { required: CONSTANTS.MESSAGE_PLAN_DURATION_INVALID })}
                                        className={`form-control UserFormContainer__input ${errors.limitusers ? 'UserFormContainer__input--error' : ''}`}>
                                    </input>
                                    <ErrorMessage message={errors.limitusers ? errors.limitusers.message : ''} />
                                </div>
                                <div className="col-12 col-md-6">
                                    <label className="form-label UserFormContainer__label">URL asignada a B2B</label>
                                    <input
                                        name='urlb2b'
                                        type='text'
                                        disabled={parseInt(params.id)===1}
                                        placeholder={CONSTANTS.LABEL_URLLINK_B2B_PLACEHOLDER}
                                        {...register("urlb2b", { required: CONSTANTS.MESSAGE_LINKB2B_REQUIRED })}
                                        className={`form-control UserFormContainer__input ${errors.urlb2b ? 'UserFormContainer__input--error' : ''}`}>
                                    </input>
                                    <ErrorMessage message={errors.urlb2b ? errors.urlb2b.message : ''} />
                                </div>
                            </div>
                        </div>
                        <div className='mb-3'>
                        <div className='B2BAdmin__text-description mt-3'><span className='auth__required-input'>* Es obligatorio luego de creado el B2B entregar las plantillas de correos para cargar en los servicios.</span></div>
                        </div>
                        {params.id &&
                            <>
                            <div className='mb-3 mt-4'>
                                    <div className='row'>
                                        <div className="col-12 col-md-6">
                                            <label className="form-label PlanFormContainer__label">Planes del B2B</label>
                                        </div>
                                        <div className="col-12 col-md-6 text-end">
                                        { parseInt(params.id)!==1 && <Link to={{}} className="FilterAdmin__option-button" onClick={handleOpenModalCreatePlan}>
                                                <span>Asignar Plan a B2B</span>
                                            </Link> }
                                        </div>
                                    </div>
                                </div>
                                <div className='mb-3' style={{ width: "100%", marginTop: "17px", overflowX: "auto" }}>
                                    <div className='mb-3'>
                                        <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
                                        <table className='UserAdmin__table' >
                                            <thead className='UserAdmin__subtable-header' style={{ height: "52px" }}>
                                                <tr style={{ cursor: "pointer" }}>
                                                    {headers.map((header, index) => {
                                                        return (
                                                            <th key={index} style={{ width: header.width, paddingLeft: "16px", textAlign: "center" }}>{header.name}</th>
                                                        )
                                                    })}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    plans.length !== 0 ?
                                                    plans.sort((a, b) => a.title.localeCompare(b.title))
                                                    .map((data, index) => {
                                                            return (
                                                                <tr className={`UserAdmin__table-row ${index % 2 === 0 ? "UserAdmin__table-pair-row" : "UserAdmin__table-odd-row"}`} key={index}>
                                                                    <td style={{ paddingLeft: "16px", textAlign: "center" }}>{moment.utc(data?.createdAt).format('DD/MM/YYYY')}</td>
                                                                    <td style={{ paddingLeft: "16px", textAlign: "center" }}>{data.title}</td>
                                                                    <td style={{ paddingLeft: "16px", textAlign: "center" }}>{getDuration(data)}</td>
                                                                    <td style={{ paddingLeft: "16px", textAlign: "center" }}>{data.price} COP</td>
                                                                    <td style={{ paddingLeft: "16px", textAlign: "center" }}><Link to={'#'} className="FilterAdmin__option-button" onClick={(event) => handleOpenModalEditPlan(data.id, event)}><i className='fa-solid fa-pen UserAdmin__color-red'></i></Link> <Link to={'#'} className="FilterAdmin__option-button" onClick={(event) => handleDeletePlan(data.id, event)}><i className='fa-solid fa-trash UserAdmin__color-red'></i></Link></td>
                                                                </tr>
                                                            );
                                                        })
                                                        : <tr>
                                                            <td colSpan="7" style={{ padding: "16px", textAlign: "center" }}><strong>No existen registros</strong></td>
                                                        </tr>
                                                }
                                            </tbody>
                                        </table>
                                        </div>
                                    </div>
                                </div>
                                
                            { parseInt(params.id) !== 1 && <>
                            <div className='mb-3'>
                                <div className='row'>
                                    <div className="col-12 col-md-6">
                                        <label className="form-label PlanFormContainer__label">Segmentación de usuarios para B2B</label>
                                    </div>
                                    <div className="col-12 col-md-6 text-end">
                                        <div className='d-flex flex-column flex-lg-row justify-content-end'>
                                            <Link to={{}} className="FilterAdmin__option-button" onClick={plans.length !== 0 && handleOpenModalCreateSegment} disabled>
                                                <span>Agregar Segmentación</span>
                                            </Link>
                                            <Link to={plans.length !== 0 && segment.length !== 0 && '/admin/b2b/segmentacion'} state={{ dataB2B: { id: users.id, name: users.name } }} className="FilterAdmin__option-button" onClick={plans.length !== 0 && segment.length !== 0 && handleOpenModalCreateSegment}>
                                                <span>Agregar cursos a Segmentación</span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                                <div className='' style={{ width: "100%", marginTop: "17px", overflowX: "auto" }}>
                                    <div className='mb-3'>
                                        <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
                                        <table className='UserAdmin__table' >
                                            <thead className='UserAdmin__subtable-header' style={{ height: "52px" }}>
                                                <tr style={{ cursor: "pointer" }}>
                                                    {headersegment.map((header, index) => {
                                                        return (
                                                            <th key={index} style={{ width: header.width, paddingLeft: "16px", textAlign: "center" }}>{header.name}</th>
                                                        )
                                                    })}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    segment.length !== 0 ?
                                                        segment.map((data, index) => {
                                                            const planName = plans.find(
                                                                (item) => parseInt(item.id) === data?.planId,
                                                            );
                                                            return (
                                                                <tr className={`UserAdmin__table-row ${index % 2 === 0 ? "UserAdmin__table-pair-row" : "UserAdmin__table-odd-row"}`} key={index}>
                                                                    <td style={{ paddingLeft: "16px", textAlign: "center" }}>{moment.utc(data?.createdAt).format('DD/MM/YYYY')}</td>
                                                                    <td style={{ paddingLeft: "16px", textAlign: "center" }}>{data?.name}</td>
                                                                    <td style={{ paddingLeft: "16px", textAlign: "center" }}>{planName?.title}</td>
                                                                    <td style={{ paddingLeft: "16px", textAlign: "center" }}>{data?.type}</td>
                                                                    <td style={{ paddingLeft: "16px", textAlign: "center" }}>{data?.limitusers}</td>
                                                                    <td style={{ paddingLeft: "16px", textAlign: "center" }}><Link to={'#'} className="FilterAdmin__option-button" onClick={(event) => handleOpenModalEditSegment(data.id, event)}><i className='fa-solid fa-pen UserAdmin__color-red'></i></Link> <Link to={'#'} className="FilterAdmin__option-button" onClick={(event) => handleDeleteSegment(data.id, event)}><i className='fa-solid fa-trash UserAdmin__color-red'></i></Link></td>
                                                                </tr>
                                                            );
                                                        })
                                                        : <tr>
                                                            <td colSpan="6" style={{ padding: "16px", textAlign: "center" }}><strong>No existen registros</strong></td>
                                                        </tr>
                                                }
                                            </tbody>
                                        </table>
                                        </div>
                                    </div>
                                </div></> }
                                { parseInt(params.id) === 1 && <>
                            <div className='mb-3 mt-4'>
                                <div className='row'>
                                    <div className="col-12 col-md-6">
                                        <label className="form-label PlanFormContainer__label">Cursos inscritos para empresa B2B</label>
                                    </div>
                                    <div className="col-12 col-md-6 text-end">
                                        <div className='d-flex flex-column flex-lg-row justify-content-end'>
                                            <Link to={'/admin/b2b/courseondemand'} className="FilterAdmin__option-button">
                                                <span>Cursos Pagos</span>
                                            </Link>
                                            <Link to={{}} className="FilterAdmin__option-button" onClick={handleOpenModal}>
                                                <span>Agregar Cursos a B2B</span>
                                            </Link>
                                            <Link to={{}} className="FilterAdmin__option-button" onClick={handleOpenModalDelete}>
                                                <span>Eliminar Cursos a B2B</span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                                <div className='' style={{ width: "100%", marginTop: "17px", overflowX: "auto" }}>
                                    <div className='mb-3'>
                                        <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
                                        <table className='UserAdmin__table' >
                                            <thead className='UserAdmin__subtable-header' style={{ height: "52px" }}>
                                                <tr style={{ cursor: "pointer" }}>
                                                    {headercourses.map((header, index) => {
                                                        return (
                                                            <th key={index} style={{ width: header.width, paddingLeft: "16px", textAlign: "center" }}>{header.name}</th>
                                                        )
                                                    })}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    courses.length !== 0 ?
                                                        courses.map((data, index) => {
                                                            return (
                                                                <tr className={`UserAdmin__table-row ${index % 2 === 0 ? "UserAdmin__table-pair-row" : "UserAdmin__table-odd-row"}`} key={index}>
                                                                    <td style={{ paddingLeft: "16px", textAlign: "center" }}>{moment.utc(data?.createdAt).format('DD/MM/YYYY')}</td>
                                                                    <td style={{ paddingLeft: "16px", textAlign: "center" }}>{data?.['course.title']}</td>
                                                                    <td style={{ paddingLeft: "16px", textAlign: "center" }}>{data?.['course.instructorData']?.name}</td>
                                                                    <td style={{ paddingLeft: "16px", textAlign: "center" }}>{formatDuration(data?.duration)}</td>
                                                                    <td style={{ paddingLeft: "16px", textAlign: "center" }}>{data?.price}</td>
                                                                    <td style={{ paddingLeft: "16px", textAlign: "center" }}><Link to={'#'} className="FilterAdmin__option-button" onClick={(event) => handleOpenModalEditCourse(data, event)}><i className='fa-solid fa-pen UserAdmin__color-red'></i></Link> <Link to={'#'} className="FilterAdmin__option-button" onClick={(event) => handleDeleteCourse(data.id, event)}><i className='fa-solid fa-trash UserAdmin__color-red'></i></Link></td>
                                                                </tr>
                                                            );
                                                        })
                                                        : <tr>
                                                            <td colSpan="5" style={{ padding: "16px", textAlign: "center" }}><strong>No existen registros</strong></td>
                                                        </tr>
                                                }
                                            </tbody>
                                        </table>
                                        </div>
                                    </div>
                                </div></> }
                                
                                <div className='mb-3 mt-4'>
                                    <div className='row'>
                                        <div className="col-12 col-lg-5">
                                            <label className="form-label PlanFormContainer__label">Usuarios preinscritos autorizados para este B2B</label>
                                        </div>
                                        <div className="col-12 col-lg-7 B2BAdmin__buttons-group d-flex">
                                            <Link to={{}} className="FilterAdmin__option-button" onClick={plans.length !== 0 && segment.length !== 0 && handleOpenModalCreateUser}>
                                                <span>Agregar Usuario</span>
                                            </Link>
                                            <Link to={{}} className="FilterAdmin__option-button" onClick={plans.length !== 0 && segment.length !== 0 && handleOpenModalCreateUserBatch}>
                                                <span>Agregar LT</span>
                                            </Link>
                                            <Link to='/admin/b2b/usuarios' state={{ dataB2B: { id: users.id, name: users.name } }} className="FilterAdmin__option-button">
                                                <span>Ver Usuarios</span>
                                            </Link>
                                            {parseInt(params.id) === 1 && <Link to={{}} className="FilterAdmin__option-button" onClick={handleOpenModalSincSap}>
                                                <span>Sinc SAP</span>
                                            </Link>}
                                            {parseInt(params.id) === 1 && <Link to={{}} className="FilterAdmin__option-button" onClick={handleOpenModalEmptyDBEanist}>
                                                <span>Vaciar BD Eanista</span>
                                            </Link>}
                                        </div>
                                    </div>
                                </div>
                                <div className='' style={{ width: "100%", marginTop: "17px", overflowX: "auto" }}>
                                    <div className='mb-3'>
                                        <table className='UserAdmin__table' >
                                            <thead className='UserAdmin__subtable-header' style={{ height: "52px" }}>
                                                <tr style={{ cursor: "pointer" }}>
                                                    {headersusers.map((header, index) => {
                                                        return (
                                                            <th key={index} style={{ width: header.width, paddingLeft: "16px", textAlign: "center" }}>{header.name}</th>
                                                        )
                                                    })}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    usersb2b.length !== 0 ?
                                                        usersb2b.map((data, index) => {
                                                            const segmentName = segment.find(
                                                                (item) => parseInt(item.type) === parseInt(data?.type),
                                                            );
                                                            return (
                                                                <tr className={`UserAdmin__table-row ${index % 2 === 0 ? "UserAdmin__table-pair-row" : "UserAdmin__table-odd-row"}`} key={index}>
                                                                    <td className="overflow" style={{ paddingLeft: "16px", textAlign: "center" }}>{data.name} {data.lastname}</td>
                                                                    <td className="overflow" style={{ paddingLeft: "16px", textAlign: "center" }}>{data.email}</td>
                                                                    <td className="overflow" style={{ paddingLeft: "16px", textAlign: "center" }}>{data.identificationType} {data.identificationNumber}</td>
                                                                    <td className="overflow" style={{ paddingLeft: "16px", textAlign: "center" }}>[{data.type}] {segmentName?.name}</td>
                                                                    <td style={{ paddingLeft: "16px", textAlign: "center" }}>{new Date(data.createdAt).toISOString().split('T')[0]}</td>
                                                                </tr>
                                                            );
                                                        })
                                                        : <tr>
                                                            <td colSpan="5" style={{ padding: "16px", textAlign: "center" }}><strong>No existen registros</strong></td>
                                                        </tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </>}
                        <SubmitFormButton
                            main_title="Guardar"
                            wait_title="Guardando..."
                            loading={fetchData.loading}>
                        </SubmitFormButton>
                    </form>
                    <ErrorMessage message={fetchData.error ? ((!!fetchData.error.code) ? CONSTANTS.MESSAGE_PLAN_ERROR : CONSTANTS.MESSAGE_GENERIC_ERROR) : ''} />
                </div>
            </div>
        </>
    );
}