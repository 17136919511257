import React, { useEffect } from 'react'
import { SimpleFooter } from '../../footer/Footer'
import { AdminHeader } from '../plans/AdminHeader'
import { ReportsIndividualCoursesAdminSection } from "./ReportsIndividualCoursesAdminSection";
import { useNavigate } from 'react-router-dom';
import { useAuthToken } from '../../../hooks/useAuthToken';
/**
 * Creado por Equilibrio Agency 2022<br>
 * Página para mostrar contenido de sección usuarios
 * @return {HTMLElement} Elementos HTML de cada componente
 * @interface AdminHeader
 */
export const ReportsIndividualCoursesPage = () => {
  const { getSessionUser } = useAuthToken(true);
  const navigate = useNavigate();
  useEffect(()=> {
    const sessionUser = getSessionUser();
    if(sessionUser.type !== 0 && sessionUser.type !== 1){
      navigate("/admin/usuarios");
    }
  },[]);
  return (
    <>
      <AdminHeader />
      <div style={{marginTop: "100px"}}></div>
      <ReportsIndividualCoursesAdminSection></ReportsIndividualCoursesAdminSection>
      <SimpleFooter></SimpleFooter>
    </>
  )
}
