import React, { useEffect } from 'react'
/**
 * Creado por Equilibrio Agency 2024<br>
 * Página para redireccionar a sitio genially.com interactive-content-lecciones-aprendidas
 * @return {HTMLElement} Elementos HTML de cada componente
 * @interface CenitIncidentePage
 */
export const CenitIncidentePage = () => {
  useEffect(()=> {
    window.location.replace('https://view.genially.com/669ff695dc98966d5805f265/interactive-content-lecciones-aprendidas-cenit');
  },[]);
  return (
    <>
    </>
  )
}
