import React, { useEffect, useState } from 'react'
import { useFetch } from '../../../hooks/useFetch';
import { Pagination } from '../../pagination';
import { useLocation, Link } from 'react-router-dom';
import Select from "react-select";
import { Filters } from '../filters/Filters';
import { useAuthToken } from '../../../hooks/useAuthToken';
import { AcceptDialog, Toast } from '../../controls/UIControls';
import Modal from '@mui/material/Modal';

const landingImages = require.context('../../../assets/images', true);
/**
 * Creado por Equilibrio Agency 2023<br>
 * Listado sección certificados
 * @interface CertificationSection
 */
export const CertificationSection = () => {

    const [certificates, setCertificates] = useState([]);
    const [certificateTemplates, setCertificateTemplates] = useState([]);
    const [waiting, setWaiting] = useState(false);
    const [certificatesFull, setCertificatesFull] = useState([]);
    const [total, setTotal] = useState(1);
    const [data, handleFetch] = useFetch();
    const [toastProperties, setToastProperties] = useState({ show: false });
    const [showModalSync, setShowModalSync] = useState(false);
    const [showModalNewRegister, setShowModalNewRegister] = useState(false);
    const [fullcatalog, setFullcatalog] = useState([]);
    const [selectedOptionTheme, setSelectedOptionTheme] = useState('');
    const [selectedOption, setSelectedOption] = useState('');
    const [selectedVar, setSelectedVar] = useState({});
    const location = useLocation();
    const { pathname } = location;

    // Handle cerrar toast
    const afterCloseToast = () => {
        setToastProperties({ show: false });
    }
    const [dataFilter, setDataFilter] = useState({
        name: '',
        type: '',
        since: '',
        active: '',
    })
    const { authToken, getSessionUser } = useAuthToken(true);
    const sessionUser = getSessionUser();
    const isAdmin = sessionUser.type === 0 ||  sessionUser.type === 1;
    const catalogFull = () => {
        const responsecatalog = handleFetch({
            url: `catalog?$limit=500&$skip=0`,
            method: 'GET',
        });
        responsecatalog
            .then(async (r) => {
                setFullcatalog(r.data);
            },
            error => {
                console.log(error.errors);
            })
    }
    // Consulta de endpoint listado de usuarios
    useEffect(() => {
        catalogFull();
        setToastProperties(location.state?.toastProperties || { show: false });
        let filtername = dataFilter.name !== '' ? `&name=` + dataFilter.name : ''
        let filtertype = dataFilter.type !== '' ? `&type=` + dataFilter.type : ''
        let filtersince = dataFilter.since !== '' ? "&since=" + dataFilter.since : ''
        let filteractive = dataFilter.active !== '' ? "&active=" + dataFilter.active : ''
        const response = handleFetch({
            url: `bo/certificates?${filtername}${filtertype}${filtersince}${filteractive}`,
            method: 'GET',
            headers: { Authorization: `Bearer ${authToken}` }
        });
        response
            .then((r) => {
                setCertificates(r.data)
                setTotal(r.total)
            },
                error => {
                    console.log(error.errors);
                })
        const responsecsv = handleFetch({
            url: `bo/certificates?$limit=50000${filtername}${filtertype}${filtersince}${filteractive}`,
            method: 'GET',
            headers: { Authorization: `Bearer ${authToken}` }
        });
        responsecsv
            .then((r) => {
                setCertificatesFull(r.data)
            },
                error => {
                    console.log(error.errors);
                })
        const responsec = handleFetch({
            url: `bo/certificatetemplates`,
            method: 'GET',
            headers: { Authorization: `Bearer ${authToken}` }
        });
        responsec
            .then((r) => {
                setCertificateTemplates(r.data)
            },
                error => {
                    console.log(error.errors);
                })
    }, [total])
    // Handle para registrar información de campos en filtros
    const handleChangeFilter = ({ target }) => {
        setDataFilter({
            ...dataFilter,
            [target.name]: target.value
        })
    }
    // Función para activar filtros, recibe parametros del mismo
    const handleSubmit = async (e) => {
        e.preventDefault();
        let filtername = dataFilter.name !== '' ? `&name=` + dataFilter.name : ''
        let filtertype = dataFilter.type !== '' ? `&type=` + dataFilter.type : ''
        let filtersince = dataFilter.since !== '' ? "&since=" + dataFilter.since : ''
        let filteractive = dataFilter.active !== '' ? "&active=" + dataFilter.active : ''
        const response = handleFetch({
            url: `bo/certificates?${filtername}${filtertype}${filtersince}${filteractive}`,
            method: 'GET',
            headers: { Authorization: `Bearer ${authToken}` }
        });
        response
            .then((r) => {
                setCertificates(r.data)
            },
                error => {
                    console.log(error.errors);
                })
        const responsecsv = handleFetch({
            url: `bo/certificates?$limit=50000${filtername}${filtertype}${filtersince}${filteractive}`,
            method: 'GET',
            headers: { Authorization: `Bearer ${authToken}` }
        });
        responsecsv
            .then((r) => {
                setCertificatesFull(r.data)
            },
                error => {
                    console.log(error.errors);
                })
    }
    const vars = [
        {
            "name": "certificates",
            "title": "Certificados",
        },
        {
            "name": "badges",
            "title": "Insignias",
        },
        {
            "name": "courses",
            "title": "Cursos",
        },
        {
            "name": "users",
            "title": "Progreso de usuarios",
        },
        {
            "name": "",
            "title": "Error",
        }
    ]
    // Función mostrar modal para activar sincronización de certificados, insignias, cursos e usuarios
    const handleOpenModalSync = (id) => {
        setSelectedVar(vars.find(variant => variant.name === id) || vars[4]);
        setShowModalSync(true);
        setSelectedOption(id);
    }
    // Función ocultar modal para activar sincronización de certificados, insignias, cursos e usuarios
    const handleCloseModalSync = () => {
        setShowModalSync(false);
        setSelectedOption('');
        setSelectedVar({});
    }
    const handleSelectCourse = (selectedOption) => {
        setSelectedOption(selectedOption);
    };
    const handleSelectTheme = (selectedOption) => {
        setSelectedOptionTheme(selectedOption);
    };
    // Función mostrar modal para activar sincronización de certificados, insignias, cursos e usuarios
    const handleOpenModalNewRegister = (id) => {
        setShowModalNewRegister(true);
    }
    // Función ocultar modal para activar sincronización de certificados, insignias, cursos e usuarios
    const handleCloseModalNewRegister = () => {
        setShowModalNewRegister(false);
    }
    // Handle para sicronizar cursos, certificados e insignias
    const handleSync = async (event) => {
        setWaiting(true)
        event.preventDefault();
        await handleFetch({
            url: `bo/sync${selectedOption}`,
            method: 'POST',
            data: {},
            headers: { Authorization: `Bearer ${authToken}` },
        })
            .then(async () =>  {
                setToastProperties({
                    show: true,
                    type: 'success',
                    title: 'Operación exitosa',
                    message: 'Se ha completado la sincronización exitosamente.',
                    position: { horizontal: 'left' },
                });
                setShowModalSync(false);
            })
            .catch(() => {
                setToastProperties({
                    show: true,
                    type: 'error',
                    message: 'Error al realizar la sincronización. Intenta nuevamente.',
                    position: { horizontal: 'left' },
                });
            });
            setShowModalSync(false);
            setWaiting(false)
    }
    // Handle para sicronizar cursos, certificados e insignias
    const handleAddRegister = async (event) => {
        setWaiting(true)
        event.preventDefault();
        await handleFetch({
            url: `bo/certificates`,
            method: 'POST',
            data: { courseId: selectedOption?.value, certificationTemplateId: selectedOptionTheme?.value },
            headers: { Authorization: `Bearer ${authToken}` },
        })
            .then(async () =>  {
                setToastProperties({
                    show: true,
                    type: 'success',
                    title: 'Operación exitosa',
                    message: 'Se ha completado la sincronización exitosamente.',
                    position: { horizontal: 'left' },
                });
                setShowModalSync(false);
            })
            .catch(() => {
                setToastProperties({
                    show: true,
                    type: 'error',
                    message: 'Error al realizar la sincronización. Intenta nuevamente.',
                    position: { horizontal: 'left' },
                });
            });
            setShowModalSync(false);
            setWaiting(false)
    }
    let newDate = new Date()
    newDate = new Date(newDate.setDate(newDate.getDate())).toISOString().split('T')[0]
    const types = ['Cápsula', 'Curso', 'Plan de estudio', 'Insignia'];
    const colors = ['Negro', 'Azúl', 'Verde'];
    return (
        <>
            <div className='row justify-content-center'>
                <div className='col-10'>
                    <div className='row justify-content-center'>
                        <div className='col-12 text-center'>
                            <h1 style={{ fontSize: "36px", fontWeight: "700", lineHeight: "46.8px", letterSpacing: "-1%" }}>Certificados EanX</h1>
                        </div>
                        <div className='col-12 d-flex justify-content-center'>
                            <div className='LandingHeader__text-and-border'>
                                <Link to={'../admin/certieanx'} className={`${pathname == '/admin/certieanx' ? 'LandingHeader__text-black-color-active' : 'LandingHeader__text-black-color'}`}>Certificados EanX</Link>
                            </div>
                            <div className='LandingHeader__text-and-border'>
                                <Link to={'../admin/plantillascertieanx'} className={`${pathname == '/admin/plantillascertieanx' ? 'LandingHeader__text-black-color-active' : 'LandingHeader__text-black-color'}`}>Plantillas EanX</Link>
                            </div>
                            <div className='LandingHeader__text-no-border'>
                                <Link to={'../admin/certigenerados'} className={`${pathname == '/admin/certigenerados' ? 'LandingHeader__text-black-color-active' : 'LandingHeader__text-black-color'}`}>Certificados Generados</Link>
                            </div>
                        </div>
                    </div>
                    <div className='row justify-content-center' style={{ marginTop: "30px" }}>
                        <Filters
                            downloadCSV={true}
                            filename={"listcertifications.csv"}
                            onSubmit={handleSubmit}
                            data={certificatesFull}
                        >
                            <div className='FilterAdmin__options'>
                                <span className='FilterAdmin__input-title'>Nombre de curso</span>
                                <div>
                                    <input type='text' name='name' value={dataFilter?.name || ''} onChange={handleChangeFilter} placeholder="Nombre" className={`FilterAdmin__input`} />
                                </div>
                            </div>
                            <div className='FilterAdmin__options'>
                                <span className='FilterAdmin__input-title'>Tipo de reconocimiento</span>
                                <div>
                                    <select
                                        className={`form-select FilterAdmin__input`}
                                        name='type'
                                        value={dataFilter?.type || ''}
                                        onChange={handleChangeFilter}
                                    >
                                        <option value="" selected>Todos</option>
                                        <option value="1">Certificado</option>
                                        <option value="2">Insignia</option>
                                    </select>
                                </div>
                            </div>
                            <div className='FilterAdmin__options'>
                                <span className='FilterAdmin__input-title'>Estado</span>
                                <div>
                                    <select
                                        className={`form-select FilterAdmin__input`}
                                        name='active'
                                        value={dataFilter?.active || ''}
                                        onChange={handleChangeFilter}
                                    >
                                        <option value="" selected>Todos</option>
                                        <option value="1">Activos</option>
                                        <option value="0">Inactivos</option>
                                    </select>
                                </div>
                            </div>
                            <div className='FilterAdmin__options'>
                                <span className='FilterAdmin__input-title'>Fecha</span>
                                <div>
                                    <input type="date" name="since" value={dataFilter?.since || ''} onChange={handleChangeFilter} max={newDate} className={`FilterAdmin__input`} />
                                </div>
                            </div>
                        </Filters>
                    </div>
                    <div className='row justify-content-center'>
                        <div className='' style={{ width: "90%", marginTop: "10px", textAlign: "right" }}>
                            { isAdmin && <div className='d-flex flex-column flex-lg-row justify-content-end'>
                                <Link to={{}} className="FilterAdmin__option-button" onClick={(event) => handleOpenModalNewRegister(event)}>
                                    <span>Añadir Certificado a Curso</span>
                                </Link>
                                <Link to={{}} className="FilterAdmin__option-button" onClick={(event) => handleOpenModalSync('courses', event)}>
                                    <span>Sincronizar Cursos</span>
                                </Link>
                                <Link to={{}} className="FilterAdmin__option-button" onClick={(event) => handleOpenModalSync('users', event)}>
                                    <span>Sincronizar Usuarios LW</span>
                                </Link>
                            </div> }
                        </div>
                    </div>
                    <div className='row justify-content-center'>
                        <div className='' style={{ width: "90%", marginTop: "1px", overflowX: "auto" }}>
                            {
                                data.loading ?
                                    <Loading />
                                    :
                                    <UsersTable certificates={certificates} total={total} dataFilter={dataFilter} certificateTemplates={certificateTemplates} />
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                open={showModalSync}
                onClose={handleCloseModalSync}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className='B2B__modal-base ProfilePage__outline-none p-4'>
                    <form onSubmit={handleSync}>
                        <div className='row justify-content-center align-items-center'>
                            <div className='col-12'>
                                <div className='AcceptDialog__body'>Sincronizar {selectedVar?.title}</div>
                                <div className="mt-2 mb-2 B2BAdmin__text-description"><span className='auth__required-input'>Esta operación va a modificar información sensible de la base de datos, debe estar completamente seguro de efectuar esta acción.</span></div>
                                <div className='mt-3 mb-3 text-center'>¿Desea continuar con la operación?</div>
                                
                            </div>
                            <div className='ProfilePage__modal-button-container d-flex justify-content-center'>
                                <div className='row w-100'>
                                    <div className="col-12 col-md-6"><button className='B2B__ActionButton ActionButton--primary' disabled={waiting}>Si {waiting && <>&nbsp;<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></>}</button></div>
                                    <div className="col-12 col-md-6"><button className='B2B__ActionButton ActionButton--secondary' onClick={handleCloseModalSync}>NO</button></div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal>
            <Modal
                open={showModalNewRegister}
                onClose={handleCloseModalNewRegister}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className='B2B__modal-base ProfilePage__outline-none p-4'>
                    <form onSubmit={handleAddRegister}>
                        <div className='row justify-content-center align-items-center'>
                        <div className='col-12'>
                                <div className='AcceptDialog__body'>Asignar Plantilla de Certificado a Curso</div>
                                <Select
                                    className="mb-2"
                                    value={selectedOption ? selectedOption : ''}
                                    options={
                                        fullcatalog?.length !== 0 &&
                                        fullcatalog?.map((data) => ({
                                                value: data.slug,
                                                label: data.title
                                            }))
                                    }
                                    onChange={handleSelectCourse}
                                />
                                <Select
                                    className="mb-2"
                                    value={selectedOptionTheme || ''}
                                    options={
                                        certificateTemplates?.length !== 0
                                            ? certificateTemplates.map((data) => {
                                                const typeText = types[data.type];
                                                const colorText = colors[data.color];
                                                return {
                                                    value: data.template,
                                                    label: `[${typeText}] [${colorText}] - ${data.template}`
                                                };
                                            })
                                            : []
                                    }
                                    onChange={handleSelectTheme}
                                />
                            </div>
                            <div className='ProfilePage__modal-button-container d-flex justify-content-center'>
                                <div className='row w-100'>
                                    <div className="col-12 col-md-6"><button className='B2B__ActionButton ActionButton--primary' disabled={waiting}>Si {waiting && <>&nbsp;<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></>}</button></div>
                                    <div className="col-12 col-md-6"><button className='B2B__ActionButton ActionButton--secondary' onClick={handleCloseModalNewRegister}>NO</button></div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal>
            <Toast {...toastProperties} closeCallback={afterCloseToast} ></Toast>
        </>
    )
}
/**
 * Creado por Equilibrio Agency 2022<br>
 * Función para listar usuarios
 * @param {JSON} certificates Información de usuarios
 * @param {string} total Cantidad total de usuarios
 * @function UsersTable
 */
const UsersTable = ({ certificates, total, dataFilter, certificateTemplates }) => {

    const [page, setPage] = useState(0);
    // Header para listado de usuarios
    const headers = [
        {
            "name": "NOMBRE CURSO",
            "sort_name": "title",
            "width": "35%"
        },
        {
            "name": "PLANTILLA DE CERTIFICADO",
            "sort_name": "template",
            "width": "25%"
        },
        {
            "name": "RECONOCIMIENTO",
            "sort_name": "type",
            "width": "15%"
        },
        {
            "name": "ESTADO",
            "sort_name": "type",
            "width": "8%"
        },
        {
            "name": "FECHA CREACIÓN",
            "sort_name": "date",
            "width": "12%"
        }
    ];

    const [certificatesSorted, setCertificatesSorted] = useState(certificates)
    const [totalSorted, setTotalSorted] = useState(total)
    const [userHovered, setUserHovered] = useState(Array(certificates.length).fill(false))
    const [toastProperties, setToastProperties] = useState({ show: false });
    const [showModalChangeStatus, setShowModalChangeStatus] = useState(false);
    const [selectedOption, setSelectedOption] = useState('');
    const [selectedId, setSelectedId] = useState(0);
    const [dialogProperties, setDialogProperties] = useState({ open: false });
    const [dataSelectFilter, setSelectFilter] = useState('')
    const types = ['Cápsula', 'Curso', 'Plan de estudio', 'Insignia'];
    const colors = ['Negro', 'Azúl', 'Verde'];
    const handleChangeFilter = (event) => {
        const selectedValue = event.target.value;
        setSelectFilter(selectedValue);
    }
    // Handle cerrar toast
    const afterCloseToast = () => {
        setToastProperties({ show: false });
    }

    useEffect(() => {
        setCertificatesSorted(certificates)
        setTotalSorted(total)
    }, [certificates, total])

    const resetState = () => {
        setUserHovered(Array(certificates.length).fill(false))
    }

    const handleActiveRow = (index) => {
        let auxArray = Array(certificates.length).fill(false);
        auxArray[index] = true
        setUserHovered(auxArray)
    }

    const [data, handleFetch] = useFetch();
    const { authToken } = useAuthToken(true);
    // Handle para paginación
    const handlePageClick = (event) => {
        let result = 50 * event.selected;
        let filtername = dataFilter.name !== '' ? `&name=` + dataFilter.name : ''
        let filtertype = dataFilter.type !== '' ? `&type=` + dataFilter.type : ''
        let filtersince = dataFilter.since !== '' ? "&since=" + dataFilter.since : ''
        let filteractive = dataFilter.active !== '' ? "&active=" + dataFilter.active : ''
        setPage(event.selected)
        const response = handleFetch({
            url: `bo/certificates?&$skip=${result}${filtername}${filtertype}${filtersince}${filteractive}`,
            method: 'GET',
            headers: { Authorization: `Bearer ${authToken}` }
        });
        response
            .then((r) => {
                setCertificatesSorted(r.data)
                setTotalSorted(r.total)
            },
                error => {
                    console.log(error.errors);
                    if (error.errors) {

                    }
                })
    };
    // Función handle para selección de selector
    const handleSelectPlanChange = (event) => {
        const selectedValue = event.target.value;
        setSelectedOption(selectedValue);
    };
    // Función mostrar modal para cambiar estado de certificados e insignias
    const handleOpenModalChangeStatus = (id) => {
        setShowModalChangeStatus(true);
        setSelectedId(id);
    }
    // Función ocultar modal para cambiar estado de certificados e insignias
    const handleCloseModalChangeStatus = () => {
        setShowModalChangeStatus(false);
        setSelectedId(0);
    }
    // Handle para cambiar estado de certificados e insignias (Activar / Desactivar)
    const handleChangeStatus = (event) => {
        event.preventDefault();
        handleFetch({
            url: `bo/certificates/${selectedId}`,
            method: 'PATCH',
            data: { active: parseInt(selectedOption), certificationTemplateId: dataSelectFilter },
            headers: { Authorization: `Bearer ${authToken}` },
        })
            .then(() => {
                setToastProperties({
                    show: true,
                    type: 'success',
                    title: 'Operación exitosa',
                    message: 'Se ha cambiado el estado exitosamente.',
                    position: { horizontal: 'left' },
                });
                setShowModalChangeStatus(false);
                handleFetch({
                    url: 'bo/certificates', // URL para obtener la lista actualizada
                    method: 'GET',
                    headers: { Authorization: `Bearer ${authToken}` },
                }).then((response) => {
                    setCertificatesSorted(response.data);
                    setTotalSorted(response.total);
                }).catch(() => {
                    setToastProperties({
                        show: true,
                        type: 'error',
                        message: 'Error al refrescar la lista de certificados. Intenta nuevamente.',
                        position: { horizontal: 'left' },
                    });
                });
            })
            .catch(() => {
                setToastProperties({
                    show: true,
                    type: 'error',
                    message: 'Error al cambiar el estado del certificado / insignia. Intenta nuevamente.',
                    position: { horizontal: 'left' },
                });
            });
        setShowModalChangeStatus(false);
    }
    return (
        <>
            <Toast {...toastProperties} closeCallback={afterCloseToast} ></Toast>
            <Modal
                open={showModalChangeStatus}
                onClose={handleCloseModalChangeStatus}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className='B2B__modal-base ProfilePage__outline-none p-4'>
                    <form onSubmit={handleChangeStatus}>
                        <div className='row justify-content-center align-items-center'>
                            <div className='col-12'>
                                <div className='AcceptDialog__body'>Cambiar Estado (Certificado / Insignia)</div>
                                <select 
                                    name="selectedOption" 
                                    className="form-select PlanFormContainer__input mb-3" 
                                    value={dataSelectFilter || ''} 
                                    onChange={handleChangeFilter}
                                >
                                    <option value="" selected>Seleccionar plantilla</option>
                                    {
                                        certificateTemplates?.length !== 0 &&
                                        certificateTemplates?.map((data) => {
                                            const typeText = types[data.type];
                                            const colorText = colors[data.color];
                                            return (
                                                <option value={data.template} key={data.id}>
                                                    [{typeText}] [{colorText}] - {data.template}
                                                </option>
                                            );
                                        })
                                    }
                                </select>


                                
                                <select name="selectedOption" className="form-select PlanFormContainer__input mb-3" onChange={handleSelectPlanChange}>
                                    <option value='' key=''>Selecciona un estado</option>
                                    <option value='1' key='1'>Activar</option>
                                    <option value='0' key='0'>Desactivar</option>
                                </select>
                            </div>
                            <div className='ProfilePage__modal-button-container d-flex justify-content-center'>
                                <div className='row w-100'>
                                    <div className="col-12 col-md-6"><button className='B2B__ActionButton ActionButton--primary' type="submit">Cambiar Estado</button></div>
                                    <div className="col-12 col-md-6"><button className='B2B__ActionButton ActionButton--secondary' onClick={handleCloseModalChangeStatus}>Cancelar</button></div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal>
            <AcceptDialog {...dialogProperties}></AcceptDialog>
            <table className='UserAdmin__table' >
                <thead className='UserAdmin__table-header' style={{ height: "52px" }}>
                    <tr style={{ cursor: "pointer" }}>
                        {headers.map((header, index) => {
                            return (
                                <th key={index} className="overflow" style={{ width: header.width, paddingLeft: "16px" }}>{header.name}</th>
                            )
                        })}
                        <th style={{ width: "13%" }}></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        certificatesSorted?.length != 0 ?
                        certificatesSorted?.map((cert, index) => {
                                const template = certificateTemplates.find(t => t.template === cert.certificationTemplateId);
                                const typeText = template ? types[template.type] : '';
                                const colorText = template ? colors[template.color] : '';
                                return (
                                    <tr onMouseLeave={resetState} onMouseEnter={() => { handleActiveRow(index) }} className={`UserAdmin__table-row ${index % 2 === 0 ? "UserAdmin__table-pair-row" : "UserAdmin__table-odd-row"}`} key={index}>
                                        <td className="overflow" style={{ paddingLeft: "16px" }}>{cert['course.title']}</td>
                                        <td className="overflow" style={{ paddingLeft: "16px" }}>[{typeText}] [{colorText}] - {cert.certificationTemplateId}</td>
                                        <td className="overflow" style={{ paddingLeft: "16px", textAlign: "center" }}>{cert.type == 1 ? 'Certificado' : 'Insignia'}</td>
                                        <td className="overflow" style={{ paddingLeft: "16px", textAlign: "center" }}>{cert.active == 1 ? 'Activo' : 'Inactivo'}</td>
                                        <td className="overflow" style={{ paddingLeft: "16px", textAlign: "center" }}>{new Date(cert.createdAt).toISOString().split('T')[0]}</td>
                                        {
                                            userHovered[index] ?
                                                <td>
                                                    <div className='d-flex justify-content-center'>
                                                        <div className='UserAdmin__button-container' >
                                                            <Link to={{}} onClick={(event) => handleOpenModalChangeStatus(cert.courseId, event)}>
                                                                <i className='fa-solid fa-pen UserAdmin__color-primary'></i>
                                                            </Link>

                                                        </div>
                                                    </div>
                                                </td>
                                                :
                                                <td></td>
                                        }
                                    </tr>
                                );
                            })
                            :
                            <tr>
                                <td colSpan="5" style={{ padding: "16px", textAlign: "center" }}><strong>No se encontraron registros</strong></td>
                            </tr>
                    }
                </tbody>
            </table>

            <Pagination
                itemsPerPage={50}
                totalElements={total}
                onChangePage={handlePageClick}
                forcePage={page}
            />
        </>
    );
}

/**
 * Creado por Equilibrio Agency 2022<br>
 * Función Loading
 * @function Loading
 */
const Loading = (props) => {
    const loadingImage = landingImages('./icons/loading_spin_ean.svg');
    return (
        <div className='d-flex justify-content-center' style={{ minHeight: "300px" }}>
            <div className='row text-center position-absolute justify-content-center'>
                <img alt='Loader' src={loadingImage} />
            </div>
        </div>
    );
}