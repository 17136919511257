import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';

import { useAuthToken } from '../../hooks/useAuthToken';
import { useUserPlan } from '../../hooks/useUserPlan';
import { PLAN_TYPE_EAN_STUDENT_TACHER_COLLABORATOR } from '../../assets/constants';
import { Toast, AcceptDialog, BannerRemainingDays } from '../controls/UIControls';

const handleGoStore = (url, e) => {
    e.preventDefault();
    window.open(url, '_top')
  }
export const CarouselCintilloCard = () => {

  const [eanuserDialog,setEanuserDialog] = useState(false);
  const {authToken, isLogged} = useAuthToken();
  const {checkUserPlan} = useUserPlan();
  const [eanActivePlan, setEanActivePlan] = useState(false);
  const showEanDialog = e => {
    e.preventDefault();
    setEanuserDialog(true);
}
  useEffect(() => {
    if(isLogged()) {
      checkUserPlan(authToken)
      .then (hasPlan => {
       if(hasPlan && hasPlan.planType==PLAN_TYPE_EAN_STUDENT_TACHER_COLLABORATOR) {
               setEanActivePlan(true);
           }
      });
     }
  }, []);
    return (
      <div className='container-fluid landing__promotionB2BCompanies'>
        <div className="carousel-top">
          <div className="carousel-top-message">
                <div className='container'>
                  <div className='row align-items-center justify-content-between text-center'>
                      <div className='col-12 col-md-7 col-lg-7 text-lg-end text-center'>
                          <span className='landing__promotion-text'>¿Necesitas formación para tu equipo empresarial?</span>
                      </div>
                      <div className='col-12 col-md-5 col-lg-5 text-lg-start text-center'>
                          <Link to='/empresas' className='landing__promotion-button py-2 px-4'>EanX para empresas aquí</Link>
                      </div>
                  </div>
              </div>
          </div>
          <div className="carousel-top-message">
          <AcceptDialog 
          open={eanuserDialog}
          message="Ya disfrutas de un plan eanista."
          style='info'
          afterClose={()=>setEanuserDialog(false)}
          ></AcceptDialog>
          <div className='container'>
            <div className='row align-items-center justify-content-between text-center'>
                <div className='col-12 col-md-7 col-lg-7 text-lg-end text-center'>
                    <span className='landing__promotion-text'>¿Eres comunidad eanista? </span>
                </div>
                <div className='col-12 col-md-5 col-lg-5 text-lg-start text-center'>
                <Link onClick={eanActivePlan?showEanDialog:null} to='/verificar-datos' className='col-11 col-md-4 col-lg-5 landing__promotion-button'>Valida Tus Datos</Link>
                </div>
            </div>
        </div>

          </div>
        </div>
        
      </div>
  )
}
