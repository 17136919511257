import { Alert, Backdrop, CircularProgress, Collapse } from '@mui/material'
import React, { useState } from 'react'
import { useEffect } from 'react';
import { Link, useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import { useGetPlan } from '../../hooks/useGetPlan';
import { useGetCourse } from '../../hooks/useGetCourse';
import { useFetch } from '../../hooks/useFetch';
import { Toast, MessageDialog, AuthFormContainerPage } from '../controls/UIControls';
import { PLAN_TYPE_EAN_STUDENT_TACHER_COLLABORATOR } from '../../assets/constants';

const landingImages = require.context('../../assets/images', true);
/**
 * Creado por Equilibrio Agency 2022<br>
 * Sección cuenta creada
 * @function AccountCreatedPage
 */
export const AccountCreatedPage = () => {
    const [showAlert, setShowAlert] = useState(false);
    const [toastProperties, setToastProperties] = useState({ show: false });
    const [planAssociated, setPlanAssociated] = useState(true);
    const [planAssociatedMessage, setPlanAssociatedMessage] = useState('');
    const [getPlanStarted, setGetPlanStarted] = useState(false);
    const [processing, setProcessing] = useState(false);
    const location = useLocation();
    const  [searchParams] = useSearchParams();
    const {getPlan, associatePlan, mpPreference} = useGetPlan();
    const {getCourse, associateCourse, mpPreferenceCourse} = useGetCourse();
    const [namePlan, setNamePlan] = useState('');
    const [pricePlan, setPricePlan] = useState('');
    const [nameCourse, setNameCourse] = useState('');
    const [data, handleFetch] = useFetch();

    const {user, plan} = location.state?location.state:{plan:{}, user:{}};

    const COURSE_ASSOCIATED = 'El curso fue asociado correctamente a tu cuenta.';
    const COURSE_NOT_ASSOCIATED = 'Ocurrió un error al asociar el curso seleccionado a tu cuenta.';
    const COURSE_PURCHASED = 'La compra de tu curso se realizó con éxito.';
    const COURSE_NOT_PURCHASED = 'El pago de tu curso fue rechazado.';
    const PLAN_ASSOCIATED = 'El plan fue asociado correctamente a tu cuenta.';
    const PLAN_NOT_ASSOCIATED = 'Ocurrió un error al asociar el plan seleccionado a tu cuenta.';
    const PLAN_PURCHASED = 'La compra de tu plan se realizó con éxito.';
    const PLAN_NOT_PURCHASED = 'El pago de tu plan fue rechazado.';

    //const planAssociated = location.state?.planAssociated
    const [buttonProperties, setButtonProperties] = useState({label:'Ir al login', linkProps: {to:'/ingreso'}});

    const navigate = useNavigate();
    const [dialogProperties, setDialogProperties] = useState({open:false});
    const afterCloseErrorDialog = () => {
        setDialogProperties({open:false});
        navigate("/ingreso");
    }
    // Handle cerrar toast
    const afterCloseToast = () => {
        setToastProperties({ show: false });
    }
    // Handle para gestionar estado de registro
    const handleGetPlanResponse = status => {
        setGetPlanStarted(true);
        switch(status) {
            case 'assigned':
                setPlanAssociated(true);
                setPlanAssociatedMessage(PLAN_ASSOCIATED);
                setShowAlert(true);
                break;
            case 'not_assigned':
                setPlanAssociated(false);
                setPlanAssociatedMessage(PLAN_NOT_ASSOCIATED);
                setShowAlert(true);
                break;
            case 'purchase':
                setShowAlert(false);
                break;
            case 'approved':
                setPlanAssociated(true);
                setPlanAssociatedMessage(PLAN_PURCHASED);
                setShowAlert(true);
            case 'rejected':
                setPlanAssociated(false);
                setPlanAssociatedMessage(PLAN_NOT_PURCHASED);
                setShowAlert(true);
                }
    };
    // Handle para gestionar estado de registro
    const handleGetCourseResponse = status => {
        setGetPlanStarted(true);
        switch(status) {
            case 'assigned':
                setPlanAssociated(true);
                setPlanAssociatedMessage(COURSE_ASSOCIATED);
                setShowAlert(true);
                break;
            case 'not_assigned':
                setPlanAssociated(false);
                setPlanAssociatedMessage(COURSE_NOT_ASSOCIATED);
                setShowAlert(true);
                break;
            case 'purchase':
                setShowAlert(false);
                break;
            case 'approved':
                setPlanAssociated(true);
                setPlanAssociatedMessage(COURSE_PURCHASED);
                setShowAlert(true);
            case 'rejected':
                setPlanAssociated(false);
                setPlanAssociatedMessage(COURSE_NOT_PURCHASED);
                setShowAlert(true);
                }
    };
    // Función para gestión de respuesta en pago
    const checkPurchaseResponse = () => {
        if(!getPlanStarted) {
            const status = searchParams.get('status');
            const userPlanData = searchParams.get('external_reference');
            const paymentId = searchParams.get('payment_id');
            const merchandOrderId = searchParams.get('merchant_order_id');
            if( [status, userPlanData, paymentId, merchandOrderId].every(p => p!==null))
                setGetPlanStarted(true);
                getNamePlan();
        }
    }
    // Función para asociar el plan en caso de pago aprobado
    const processAnswer = async () => {
        //Almaceno valor de external_reference para asignación el plan si es necesaria
        const status = searchParams.get('status');
        const userPlanData = searchParams.get('external_reference');
        const paymentId = searchParams.get('payment_id');
        const merchandOrderId = searchParams.get('merchant_order_id');
        const requestId = searchParams.get('requestId');
        if(status=='approved') {
            const userPlan = userPlanData.split('-');
            const isNumeric = !isNaN(userPlan[1]);
            const firstDashIndex = userPlanData.indexOf('-');
            const textSegment = userPlanData.slice(firstDashIndex + 1);
            if(isNumeric){
                    // Se asigna el plan
                    let promise = new Promise(resolve => {
                        handleFetch({
                            url: `bo/plans?id=${parseInt(userPlan[1])}`,
                            method: 'GET',
                        })
                        .then((r) => {
                            resolve({name: r[0].title, price: r[0].price})
                        },()=>resolve(false))
                    })
                    promise.then((value) => {
                        window.dataLayer.push({ ecommerce: null });
                        window.dataLayer.push({
                        event: "purchase",
                        ecommerce: {
                            transaction_id: `${paymentId}-${merchandOrderId}`,
                            value: value?.price,
                            tax: "0.00",
                            shipping: "0.00",
                            currency: "COP",
                            items: [{
                            item_name: value?.name,
                            item_id: parseInt(userPlan[1]),
                            location_id: "Online Store",
                            price: value?.price,
                            item_brand: "eanX",
                            quantity: 1
                            }]
                        }
                        });
                    });
                    associatePlan({id: parseInt(userPlan[0])}, {id: parseInt(userPlan[1])},`${merchandOrderId}` )
                .then (handleGetPlanResponse);
            }else{
                // Se asigna el curso
                associateCourse({id: parseInt(userPlan[0])}, {id: textSegment},`${merchandOrderId}` )
                .then (handleGetCourseResponse);
            }     
        } else {
            handleGetPlanResponse(status);
        }
    }
    // Función mostrar modal en caso de registro usuario eanista
    const setEanPlanContent = () => {
        var date = new Date();
        var quarter =  parseInt(date.getMonth() / 6 ) + 1 ;
        let finalCourse = quarter === 1 ? '30 de junio' : '31 de diciembre';
        setDialogProperties({open:true,
            title: 'Bienvenido Eanista',
            image: true,
            message:'Por ser parte de nuestra comunidad tienes acceso a EanX' +
                ' y todos sus beneficios hasta el final de este semestre (<b>'+finalCourse+'</b>). Aprende y evoluciona.',
            messagetwo: 'Recuerda que al inicio de cada semestre deberás activar tu cuenta nuevamente.',
            afterClose: afterCloseErrorDialog,
            button_text: 'Continuar',
            style:'ok'});

    }
    // Función para asignar plan
    const startPlanAssociation = () => {
        setProcessing(true);

        getPlan(user,plan,false)
        .then(handleGetPlanResponse)
        .finally(()=>{setProcessing(false);});

        if(plan.type === PLAN_TYPE_EAN_STUDENT_TACHER_COLLABORATOR) {
            setEanPlanContent();
        }

    }
    // Función de asignación de plan manual
    const handleClick = (e) => {
        e.preventDefault();
        startPlanAssociation();
    }


    useEffect(()=> {
        if(!getPlanStarted) {
            if(plan.price==0 || plan.type === PLAN_TYPE_EAN_STUDENT_TACHER_COLLABORATOR) {
                startPlanAssociation();
            } else {
                setButtonProperties({label:'Continuar...', linkProps: {to:'/', onClick: handleClick}});
            }
        } else {
            processAnswer();
            setButtonProperties({label:'Ir al login', linkProps: {to:'/ingreso'}})
        }
    },[getPlanStarted, mpPreference]);
    useEffect(() => {
        setToastProperties(location.state?.toastProperties || { show: false });
    }, [location.state]);
    const properties = {
        title: "Tu cuenta ha sido registrada exitosamente",
        header: (<div>
                    <img 
                        alt='Ean logo' 
                        src={landingImages('./check.png')} 
                        style={{width: "100px", height: "auto"}} 
                        className="mx-auto d-block auth__img-check mb-3"
                    />
                </div>),
        content: (<Link {...buttonProperties.linkProps} replace>
                    <button className="auth__btn-form">{buttonProperties.label}</button>
                    </Link>
                    )
    }

    const propertiespurchase = {
        title: `Tu compra del ${namePlan?"plan ":"curso "} ${namePlan?namePlan:nameCourse} ha sido registrada exitosamente`,
        header: (<div>
                    <img
                        alt='Ean logo'
                        src={landingImages('./check.png')}
                        style={{width: "100px", height: "auto"}}
                        className="mx-auto d-block auth__img-check mb-3"
                    />
                </div>),
        content: (<Link {...buttonProperties.linkProps} replace>
                    <button className="auth__btn-form">{buttonProperties.label}</button>
                    </Link>
                    )
    }
    // Función para obtener nombre de plan
    const getNamePlan = () => {
        const userPlanData = searchParams.get('external_reference');
        const { plan } = location.state?location.state:{plan:{}};
        const userPlan = userPlanData ? userPlanData.split('-') : '';
        let idplan = userPlanData ? parseInt(userPlan[1]) : parseInt(plan.id);
        const parts = userPlanData && userPlanData.split('-');
        const isNumeric = parts && !isNaN(parts && parts[1]);
        const firstDashIndex = userPlanData && userPlanData.indexOf('-');
        const textSegment = userPlanData && userPlanData.slice(firstDashIndex + 1);
        let idCourse = '';
        if(!isNumeric){
            idCourse = userPlan[1];
        }
        const status = searchParams.get('status');
        if(status==='approved'&&namePlan!=="") {
            if(isNumeric){
            const response = handleFetch({
                url: `bo/plans?$limit=500&id=${idplan}`,
                method: 'GET',
            });
            response
                .then((r) => {
                        setNamePlan(r[0].title)
                        setPricePlan(r[0].price)
                    },
                    error => {
                        console.log(error.errors);
                    })
            }else{
                const response = handleFetch({
                url: `catalog?&courseId=${textSegment}`,
                method: 'GET',
            });
            response
                .then((r) => {
                        setNameCourse(r.data[0].title)
                    },
                    error => {
                        console.log(error.errors);
                    })
            }
        }
    }
    checkPurchaseResponse();

    return (
            <>
            <Toast {...toastProperties} closeCallback={afterCloseToast} ></Toast>
            <MessageDialog {...dialogProperties}></MessageDialog>
            <Backdrop
            sx={{ color: '#fff'}}
            open={processing}
            >
            <CircularProgress color="inherit" />
            </Backdrop>
            <Collapse in={showAlert}>
                <Alert severity={planAssociated?"success":"error"} onClose={()=>{setShowAlert(false)}}>
                    {planAssociatedMessage}
                </Alert>
            </Collapse>
                { location?.state?.plan?.price == 0 ?
                    <AuthFormContainerPage {...properties}></AuthFormContainerPage> :
                    <AuthFormContainerPage {...propertiespurchase}></AuthFormContainerPage>
                }
            </>
            )
}