import React, { useState, useEffect, useRef  } from 'react';
import { LandingHeaderB2B } from './LandingHeaderB2B'
import { Footer } from '../footer/Footer';
import { Link } from 'react-router-dom';
import Marquee from "react-fast-marquee";
import WebChatZoomCrm from '../../assets/zoom-messenger-react/lib/addBubble.js'
import Modal from '@mui/material/Modal';
const landingImages = require.context('../../assets/images', true);

export const LandingHomePage = () => {
    const [alturaIframe, setAlturaIframe] = useState(window.innerHeight);

    const [courses, setCourses] = useState(0);
    const [countries, setCountries] = useState(0);
    const [years, setYears] = useState(0);
    const [isIntersecting, setIsIntersecting] = useState(false);
    const targetRef = useRef(null);
    const [pageHeight, setPageHeight] = useState(0);
    const [doublePageHeight, setDoublePageHeight] = useState(0);
    const headerHeight = 200;
    const secondSectionRef = useRef(null);
    useEffect(() => {
        window.scrollTo(0,0);
        const limitCourses = 110;
        const limitCountries = 8;
        const limitYears = 5;
        const duration = 6000;
        const stepsCourses = 109;
        const stepsCountries = 7;
        const stepsYears = 4;

        const stepValueCourses = Math.ceil(limitCourses / stepsCourses);
        const stepValueCountries = Math.ceil(limitCountries / stepsCountries);
        const stepValueYears = Math.ceil(limitYears / stepsYears);
        const stepDuration = Math.ceil(duration / stepsCourses);

        let coursesCount = 0;
        let countriesCount = 0;
        let yearsCount = 0;

        const intervalId = setInterval(() => {
            coursesCount += stepValueCourses;
            countriesCount += stepValueCountries;
            yearsCount += stepValueYears;

            setCourses(coursesCount > limitCourses ? limitCourses : coursesCount);
            setCountries(countriesCount > limitCountries ? limitCountries : countriesCount);
            setYears(yearsCount > limitYears ? limitYears : yearsCount);

            if (coursesCount >= limitCourses) clearInterval(intervalId);
        }, stepDuration);

        return () => {
            clearInterval(intervalId);
        };
    }, [isIntersecting]);

    const handleIntersection = (entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                setIsIntersecting(true);
            }
        });
    };

    useEffect(() => {
        const observer = new IntersectionObserver(handleIntersection);
        observer.observe(targetRef.current);

        return () => {
            observer.disconnect();
        };
    }, []);
    useEffect(() => {
        const ajustarAlturaIframe = () => {
        setAlturaIframe(window.innerHeight);
        };
        window.addEventListener('resize', ajustarAlturaIframe);
        return () => {
        window.removeEventListener('resize', ajustarAlturaIframe);
        };
    }, []);

    useEffect(() => {
        const updatePageHeight = () => {
        const newPageHeight = window.innerHeight;
        setPageHeight(newPageHeight);
        setDoublePageHeight(newPageHeight * 2);
        };

        window.addEventListener('resize', updatePageHeight);
        updatePageHeight();

        return () => {
        window.removeEventListener('resize', updatePageHeight);
        };
    }, []);

    const handleLinkClick = () => {
        if (secondSectionRef.current) {
        const rect = secondSectionRef.current.getBoundingClientRect();
        const yOffset = rect.top + window.pageYOffset - headerHeight;
        window.scrollTo({ top: yOffset, behavior: 'smooth' });
        }
    };
    const [showModal, setShowModal] = useState(false);
    const handleCloseModal = () => {
        setShowModal(false);
    }
    return (
    <><Modal
            open={showModal}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className='modal-base ProfilePage__outline-none p-4'>
                <div className='mb-3'>Webinar: IA, un aliado más en tu empresa</div><button className='button-modal fw-bold' onClick={handleCloseModal}>x</button>
            <a href="https://events.teams.microsoft.com/event/c845c7ba-e725-436c-927d-1bc632e24a37@c06fb559-1b68-4b84-a14f-47d0d837a5ab" target="_blank"><img src={'https://eanx.zoomcrm.co/documents/Lightbox_webinar-1725291181.jpg'} border="0"/></a>
            </div>
        </Modal>
        <div id="contWebChatZoomCrm">
        <WebChatZoomCrm
            elementId="contWebChatZoomCrm"
            backgroundColor="#3BAC53"
            color="#FFFFFF"
            socialGatewayUrl="https://socialgateway.zoomcem.com/modules/zoomWebChat/zoomMessengerLog.php?params=N3hMWEdVWndFVytYQnE4cU4xSm1pMlhRaHVvSXVPRmVuNjQrdGFvS0o4cVdaZXA1SG80azh6WDc2U3g5T3RMODdEME84bmdqNkZqMjNta1BIN1Rza3BhWHdzVDRSdERBUG1PSTlFWGcwM3BrVE5KU213ODUxWTc3ZEk4UmNKejhjN0NEd3RpQ1FQSTdPRHFNbWpZRjVtc1pvUmhyMUJUL2dsQldWeXhUUDVqSXdySWxtZ3YxVmxlMEJYVElLdkdMUnEva1B6YXBFQ3NUNzk0a2lKRkdFanIyaTAvQ01ibVRsVDFXKzlmNmJ1T0czcnM2d01zb1FhTDJyUC9XUDZicjZwQ0cvUUpCaTlCclM1RWY3eE5vYVNodGlTeXc5QlRJaTBtbFVldGs5S2FSWmd6VVRxS2dQQlFhNnpJSXV3UHZBNi81RkpuNytrYVU2Nm1nbzQ4dVJReS9RdlVxWEFZdUgrbFdtN2lZUlgzRXRnTGhiZWR4RzdBWkphNW41NDJFOEl0M2RHUXJRQTV1aGF0L1IwS290ZVVDUzl2TWQ0MVFTNDdoTFQxRDR2Z0VGVW1aWDYydSs1R2FiMHJaaHlkUlVOcWk4UlM4ZU01ZzdRUGYxV0ptVXpraEt0aUkyQzdSZmhQOGxJR0t1c0IyVmxVNWcxK1BraWFDYjcxUzJkdnA2Nko1RzBYNi9CU0FNZWhEc2FZcVRsdm05WUVWWWFnTFB1bHc3WXdDYmU3NWEvQjU2aVBVbTgyeFUrQzZNd3hZUXplbStvSXpBakhWQlB0M1N2b1dWeS8xSFFSdklkL0pwMEhMajJpWHJhVWxiZ2hYdU51c2dQMFcvaTdaMHNBS0p5aVNMMnpYeHByYVhQV08wWXlMVHR3VWNGR1c3U0FwWEE5emswVzdoMzBBbUxEOTMvdTBKSmxUdHlSdWEyVDZZSjVqb2Z6aHN3c1UzNit0eiszMWc4SGZCWnoreW9HUGloRFR4KzNnWXh1VjZPUkllbXhFUURkcm1hY2hiV1JpTXl6dzAzbFpVdGhqSWdub2JtcEZqZ2FYY0lQRXBtcjhkY2w0cnh0UHprWkRRR3ZCYktlR2l2VFAwREk0b3VxWExWZEVBL2FUYkJaK09jWTg5MUZ0d1dVeDhYckxFQjhKU2d4R2FJcGdjUlNSU0VkcWtCYk5XdFNqQjdTclJLM0t6L0xib0pqOHIwZVYwd2JxZGFCREtoaHlmN1BhbDVGSnZqZ1M3Y1kvcVptcnJFVGlBcjFJSGw5UUN2NjJHYVFDZE4reFBTUUNRSFEwN3VyTEQvaUprYUZzMDZ6azJTRTZ2VHo3aGdtY3ZOczQySXh5R0o4dVdOMG4zV3h1cWdDbTBUMnF3V2hwZXBSTkU4RmFRbWxGWTJlY0t1Wi9vRjBWNlYreE1UbjQ3Z2tudndmM2YxU1hMTFdNdmtkZW9nNEJOYWExaGZ1RDdrOU5tMmFRSi9WQ3h4Q3BaVW42eGdUQVRQeXlmVkEwOEdyazN5elJuS0NoS2RJdXI2OENIazVvK2JjM2Q3R2ZBclQrSWpQT2V6VkFEb0pFc0Zoc0NXeVZvT2t1NnkrMzUxZnNrc2tZRjlPQStoK045ditZWkVhdlUzenJjT0VDbWtsNlpRVWhTTDNqZVE9PQ=="/>
        </div>
        <LandingHeaderB2B />
        <div className='container-fluid landing__HomeSection'>
        <div className='container my-5'>
                <div className='row justify-content-center align-items-center'>
                    <div className='col-xl-5 col-lg-5 col-md-10 col-12'>
                        <div className='row'>
                            <div className='col-12 landingb2b__title-top-banner'>
                                CURSOS DIGITALES QUE SE ADAPTAN A TU EMPRESA
                            </div>
                        </div>
                        <div className='row my-4'>
                            <div className='col-12 landingb2b__paragraph-text landingb2b__white mb-2'>
                            Diseñamos el producto que necesitas para brindar conocimientos y habilidades adicionales que impulsen el desarrollo de tu equipo de trabajo.
                            </div>
                        </div>
                        <div className='mb-3'>
                            <Link to='/empresas/contacto' className='landingb2b__button-primary'>AGENDAR MI CONSULTORÍA</Link>
                        </div>
                    </div>
                    <div className='col-6 d-flex pe-0 d-lg-grid'>
                        <img alt='Cursos digitales que se adaptan a tu empresa' src={landingImages('./landingB2B/ImageBannerHome.png')} className="img-fluid" width="auto" height="80%" />
                    </div>
                </div>
            </div>
        </div>

        <div className="contenedor">
            <div className="landing__circle">
            <Link to='#!' onClick={handleLinkClick}><i className="fa-solid fa-chevron-down landing_chevron"></i></Link>
            </div>
        </div>
        
        <div className='container-fluid landing__HomeServicesSection'>
            <div className='row justify-content-center text-center'>
                <div className='col-9 col-md-12 mb-4'>
                    <p className='landingb2b__h3' ref={secondSectionRef}>¿BUSCAS UN SERVICIO A LA MEDIDA DE TU EMPRESA?</p>
                    <p className='landingb2b__paragraph-text'>Agenda tu consultoría gratuita y recibe una licencia de cortesía para que disfrutes de nuestro catálogo de cursos.</p>
                </div>
            </div>
        </div>
        <div className='container-fluid d-flex justify-content-center landing__bgservices'>
            <div className='container'>
            <div className='row'>
                <div className='col-12 p-5 mt-3 pt-4'>
                    <div className='row justify-content-center text-center'>
                        <div className='col-12 col-md-6 col-lg-4 px-2'>
                            <img className='landingb2b__servicios-eanx-b2b' alt='Formación a la medida' src={landingImages('./landingB2B/formacion.png')} width="150" height="100" />
                            <p className='landing__title-servicios-b2b'>FORMACIÓN A LA MEDIDA</p>
                            <p className='landingb2b__paragraph'>Creamos cursos a la medida de tu empresa, sobre
                                el tema específico que requieras para la formación
                                de tus colaboradores. Nuestra oferta incluye el
                                diseño instruccional, la producción, postproducción
                                audiovisual y la configuración en plataforma.</p>
                        </div>
                        <div className='col-12 col-md-6 col-lg-4 px-3'>
                            <img className='landingb2b__servicios-eanx-b2b' alt='Licencias' src={landingImages('./landingB2B/licencias.png')} width="150" height="150" />
                            <p className='landing__title-servicios-b2b'>LICENCIAS</p>
                            <p className='landingb2b__paragraph'>Catálogo por suscripción para acceder a más
                                de 130 cursos cortos certificados organizados
                                en 8 academias diferentes, para que tus
                                colaboradores adquieran nuevas habilidades
                                o actualicen sus conocimientos.</p>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>


        <div className='container-fluid landing__HomeServicesSection'>
            <div className='row justify-content-center text-center'>
                <div className='col-12 mb-4'>
                    <p className='landingb2b__h3'>¿QUÉ NOS HACE LA MEJOR OPCIÓN?</p>
                </div>
            </div>
        </div>
        <div className='container-fluid mx-0 px-0'>
            <div className='justify-content-center text-center'>
                <iframe width="100%" height={alturaIframe} src="https://www.youtube.com/embed/-ygFuMabYuU?si=CAzcCChyzXjXvjs-" title="YouTube video player" frameborder="0" allowfullscreen allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
            </div>
        </div>
        <div className='container-fluid landing__HomeServicesSection'>
            <div className='row justify-content-center text-center'>
                <div className='col-12 mb-4'>
                    <p className='landingb2b__h3'>CASOS DE ÉXITO</p>
                </div>
            </div>
        </div>
        <div className='container-fluid d-flex justify-content-center landing__bestoptions py-5'>
            <div className='col-12 py-3'>
                <div className='d-flex flex-column flex-md-row justify-content-center align-items-center'>
                <Marquee>
                    <div className="wrapper text-center">
                        <img alt='Armada de Colombia' src={landingImages('./landingB2B/armada-de-colombia.png')} width="100" height="100" />
                    </div>
                    <div className="wrapper text-center">
                        <img alt='Aris Mining' src={landingImages('./landingB2B/aris-mining.png')} width="120" height="90" />
                    </div>
                    <div className="wrapper text-center">
                        <img alt='ICETEX' src={landingImages('./landingB2B/icetex.png')} width="150" height="50" />
                    </div>
                    <div className="wrapper text-center">
                        <img alt='USAID' src={landingImages('./landingB2B/usaid.png')} width="150" height="70" />
                    </div>
                    <div className="wrapper text-center">
                        <img alt='Colsubsidio' src={landingImages('./landingB2B/colsubsidio.png')} width="150" height="60" />
                    </div>
                    <div className="wrapper text-center">
                        <img alt='Minesa' src={landingImages('./landingB2B/minesa.png')} width="100" height="80" />
                    </div>
                    <div className="wrapper text-center">
                        <img alt='Enel' src={landingImages('./landingB2B/enel.png')} width="120" height="60" />
                    </div>
                </Marquee>
                </div>
            </div>
        </div>
        <div className='container-fluid landing__HomeServicesSection'>
            <div className='row justify-content-center text-center'>
                <div className='col-12 mb-4'>
                    <p className='landingb2b__h3'>CONOCE A EANX</p>
                </div>
            </div>
        </div>
        <div className='container-fluid d-flex justify-content-center py-5'>
            <div className='col-10'>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-sm-6 col-lg-4 mb-3 mt-4">
                        <div className="text-center">
                            <div className="text-center mx-auto title-one-conoce-eanx">100%</div>
                            <div className="text-center mx-auto text-conoce-eanx">En línea, autónomo y asincrónico</div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-4 mb-3 mt-4">
                        <div className="text-center">
                            <div className="text-center mx-auto text-conoce-eanx">Certificación</div>
                            <div className="text-center mx-auto title-two-conoce-eanx">Blockchain</div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-4 mb-3 mt-4">
                        <div className="text-center">
                            <div className="text-center mx-auto text-conoce-eanx">Respaldo de la</div>
                            <div className="text-center mx-auto title-two-conoce-eanx">Universidad Ean</div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-4 mb-3 mt-4">
                        <div ref={targetRef} className="text-center">
                            <div className="text-center mx-auto text-conoce-eanx">Catálogo de</div>
                            <div className="text-center mx-auto title-one-conoce-eanx">+{courses} cursos</div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-4 mb-3 mt-4">
                        {/*<div className="text-center">
                            <div className="text-center mx-auto text-conoce-eanx">Clientes en</div>
                            <div className="text-center mx-auto title-one-conoce-eanx">+{countries} países</div>
                        </div>*/}
                    </div>
                    <div className="col-12 col-sm-6 col-lg-4 mb-3 mt-4">
                        <div className="text-center">
                            <div className="text-center mx-auto title-one-conoce-eanx">{years} años</div>
                            <div className="text-center mx-auto text-conoce-eanx">Impactando la educación en LATAM</div>
                        </div>
                    </div>
                </div>
                </div>

            </div>
        </div>

        <div className='container-fluid landingb2b__container-schedule-consulting'>
            <div className='row justify-content-center text-center pb-5'>
                <div className='col-10 col-md-3 col-lg-4 col-xl-4 landingb2b__schedule-consulting'>
                    <Link to='/empresas/contacto'>AGENDAR MI CONSULTORÍA</Link>
                </div>
            </div>
        </div>
        <Footer />
    </>
  )
}
