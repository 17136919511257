import React, { useState } from 'react';
import { CSVLink } from 'react-csv';
import { useAuthToken } from '../../../hooks/useAuthToken';
import Modal from '@mui/material/Modal';
/**
 * Creado por Equilibrio Agency 2022<br>
 * Función para filtrar usuarios en backoffice
 * @param {HTMLElement} children Contenido a mostrar, principalmente inputs para los filtros
 * @param {Event} onSubmit Función submit para enviar formulario de filtro
 * @param {boolean} downloadCSV Valor que activa o desactiva la opción de descargar archivo CSV
 * @param {JSON} data Datos recibidos del endpoint para transformalos y enviarlos al CSV
 * @param {string} filename Nombre asignado al archivo CSV
 * @function Filters
 */
export const Filters = ({children, onSubmit, downloadCSV, data, filename}) =>{
    const { getSessionUser}= useAuthToken(true);
    const sessionUser = getSessionUser();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const optionsArray = data?.length > 0 ? Object.keys(data[0]) : [];
    const cleanData = () => {
        let aux = data;
        for (let i = 0; i < aux.length; i++){
            aux[i].dataTreatmentAcceptedBy = aux[i].dataTreatmentAcceptedBy.replaceAll(',',';');
            aux[i].termsAcceptedBy = aux[i].termsAcceptedBy.replaceAll(',',';');
        }
        return aux;
    }
    let newdata = filename == "listusers.csv" ? cleanData() : data;

    const handleOpenModal = async () => {
        setIsModalOpen(true);
        setSelectedOptions([]);
    }
    const handleCloseModal = () => {
        setIsModalOpen(false);
    }
    const handleOptionChange = (key) => {
        setSelectedOptions((prev) =>
            prev.includes(key) ? prev.filter(option => option !== key) : [...prev, key]
        );
    };

    const handleDownloadFilteredCSV = () => {
        const filtered = data.map(item =>
            selectedOptions.reduce((acc, key) => {
                acc[key] = item[key];
                return acc;
            }, {})
        );
        setFilteredData(filtered);
    };
    return (
        <div className='FilterAdmin__filters'>
            <div className='row justify-content-center text-start text-md-center mt-2'>
                <form onSubmit={onSubmit}>
                    <div className='FilterAdmin__input-group'>
                        {children}
                    </div>
                    <div className='FilterAdmin__buttons-group'>
                        {downloadCSV && sessionUser?.type !== 3 && (
                            <CSVLink
                                data={newdata}
                                filename={filename}
                                className='FilterAdmin__downloadcsv-button'
                            >
                                <i className='fa-solid fa-download' style={{ marginRight: '10px' }}></i> Descargar CSV
                            </CSVLink>
                        )}
                        {downloadCSV && sessionUser?.type !== 3 && (<button
                            type="button"
                            className="FilterAdmin__search-button"
                            onClick={handleOpenModal}
                        >
                            <i className='fa-solid fa-sliders' style={{ marginRight: "10px" }}></i>
                            Filtrar y Descargar
                        </button>)}
                        <button className="FilterAdmin__search-button" type="submit">
                            <i className='fa-solid fa-search' style={{ marginRight: '15px' }}></i>
                            <span>Buscar</span>
                        </button>
                    </div>
                </form>
            </div>
            <Modal
                open={isModalOpen}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className='B2B__modal-base ProfilePage__outline-none p-4'>
                    <form onSubmit={handleDownloadFilteredCSV}>
                        <div className='row justify-content-center align-items-center'>
                            <div className='col-12'>
                                <div className='AcceptDialog__body'>Filtrar y descargar CSV</div>
                                <div className="mt-2 mb-2">Selecciona las opciones para el CSV</div>
                                    <div className="FilterAdmin__modal">
                                        <div className="FilterAdmin__modal-content">
                                            <ul className="FilterAdmin__options-list">
                                                {optionsArray.map(key => (
                                                    <li key={key}>
                                                        <label className='overflow_options'>
                                                            <input
                                                                type="checkbox"
                                                                value={key}
                                                                checked={selectedOptions.includes(key)}
                                                                onChange={() => handleOptionChange(key)}
                                                            /> {key}
                                                        </label>
                                                    </li>
                                                ))}
                                            </ul>
                                            <hr />
                                            <ul className="FilterAdmin__options-list">
                                                {selectedOptions.map((key, index) => (
                                                    <li key={key}>
                                                        <label className='overflow_options'>
                                                            [{index+1}] {key}
                                                        </label>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            <div className='ProfilePage__modal-button-container d-flex justify-content-center'>
                                <div className='row w-100'>
                                    <div className="col-12 col-md-6">{filteredData?.length === 0 ? 
                                        <button className="B2B__ActionButton ActionButton--primary"
                                            onClick={handleDownloadFilteredCSV}>Aplicar y Generar CSV</button>
                                    : <CSVLink
                                                data={filteredData}
                                                filename={`filtered_${filename}`}
                                                className="B2B__ActionButton ActionButton--primary pt-2"
                                            >Descargar CSV Filtrado
                                            </CSVLink> }
                                            </div>
                                    <div className="col-12 col-md-6"><button className='B2B__ActionButton ActionButton--secondary' onClick={handleCloseModal}>Cancelar</button></div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal>
        </div>
    );
};
