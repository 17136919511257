import React, { useEffect, useState, Suspense  } from 'react'
import { LandingHeader } from './LandingHeader'
import WebChatZoomCrm from '../../assets/zoom-messenger-react/lib/addBubble.js'
import { Footer } from '../footer/Footer';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { Toast, AcceptDialog, BannerRemainingDays } from '../controls/UIControls';
import { useAuthToken } from '../../hooks/useAuthToken';
import { useUserPlan } from '../../hooks/useUserPlan';
import { PLAN_TYPE_EAN_STUDENT_TACHER_COLLABORATOR } from '../../assets/constants';
import { useFetch } from '../../hooks/useFetch';
import { useCookies } from 'react-cookie';
import Modal from '@mui/material/Modal';
import CarouselHomeLanding from './CarouselHomeLanding';
import { CarouselCintilloCard } from './CarouselCintilloCard.js';

const landingImages = require.context('../../assets/images', true);

const CourseCategorySection = React.lazy(() => import('./CourseCategorySection'));
const PlansSection = React.lazy(() => import('./PlansSection'));
const BestCoursesSection = React.lazy(() => import('./BestCoursesSection'));
//const CarouselHomeLanding = React.lazy(() => import('./CarouselHomeLanding'));
const SuccessfulCasesSection = React.lazy(() => import('./SuccessfulCasesSection'));

export const LandingPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [toastProperties, setToastProperties] = useState({ show: false });
    const [eanuserDialog,setEanuserDialog] = useState(false);
    const {authToken, isLogged} = useAuthToken();
    const {checkUserPlan} = useUserPlan();
    const [eanActivePlan, setEanActivePlan] = useState(false);
    const [data, handleFetch] = useFetch();    
    const [showModal, setShowModal] = useState(false);
    const [cookies, setCookie] = useCookies(['modalShown']);
    const [dataLanding, setDataLanding] = useState();
    const [dataCarousel, setDataCarousel] = useState();
    // Handle cerrar toast
    const afterCloseToast = () => {
        setToastProperties({ show: false });
    }
    useEffect(() => {
        handleFetch({
            url:'bo/carouselhome?&carouselActive=true',
            method: 'GET'
            })
            .then (async responsec => {
                    setDataCarousel(responsec.data)
            }); 
        if(isLogged()) {
            checkUserPlan(authToken)
            .then (hasPlan => {
             if(hasPlan && hasPlan.planType==PLAN_TYPE_EAN_STUDENT_TACHER_COLLABORATOR) {
                     setEanActivePlan(true);
                 }
            });
           }
           }, []);
    useEffect(() => {
        setToastProperties(location.state?.toastProperties || { show: false });
    }, [location.state]);
    
    useEffect(() => {
        if (!cookies.modalShown) {
        window.scrollTo(0, 0);
        handleFetch({
            url:'bo/landing',
            method: 'GET'
            })
            .then (async response => {
                if(response.data[0]){
                    setDataLanding(response.data[0])
                    setShowModal(response.data[0].activeModal)
                }
            });
            setCookie('modalShown', 'true', { path: '/', expires: new Date(new Date().getTime() + 24 * 60 * 60 * 1000) });
        }
    }, [cookies, setCookie]);

    const handleScrollPlanSection = ()=>{
        navigate('/planes');
    }

    const showEanDialog = e => {
        e.preventDefault();
        setEanuserDialog(true);
    }
    window.addEventListener('scroll', function(){
        if(this.window.scrollY>100){
            const elements = this.document.getElementsByClassName('landing__whatsapp-button-static')
            for (let i = 0; i < elements.length; i++) {
                const element = elements[i];
                element.classList.add("landing__whatsapp-button")
                element.classList.remove("landing__whatsapp-button-static")
            }
        }
    });
    const handleCloseModal = () => {
        setShowModal(false);
    }
    const handleGoStore = (url, e) => {
        e.preventDefault();
        window.open(url, '_top')
      }
      const sortedCarouselData = Array.isArray(dataCarousel)
      ? [...dataCarousel].sort((a, b) => a?.carouselOrder - b?.carouselOrder)
      : [];
    return (
    <>
    {dataLanding && <Modal
                open={showModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className='modal-base ProfilePage__outline-none p-4'>
                    { dataLanding.imageModal && <><div className='mb-3'>{dataLanding.titleModal}</div><button className='button-modal fw-bold' onClick={handleCloseModal}>x</button></> }
                <Link to={{}} onClick={(e) => handleGoStore(dataLanding.linkModal, e)}><img src={dataLanding.imageModal && /^https:\/\//.test(dataLanding.imageModal)?dataLanding.imageModal:landingImages(`./${dataLanding.imageModal}`)} border="0"/></Link>
                </div>
            </Modal> }
            <Toast {...toastProperties} closeCallback={afterCloseToast} ></Toast>
        <BannerRemainingDays />
        <LandingHeader />
        <div className='container-fluid landing__CarouselHomeSection'>
            <CarouselCintilloCard />
            { dataCarousel && <CarouselHomeLanding dataCarousel={sortedCarouselData} /> }
        </div>
        
        {/*<div className='landing__hero'>
            <div className='landing__container-hero' style={{position: 'relative'}}>
                {/*<div className='landing__container-hero-text'>
                    <p className='landing__hero-text'>Educación sin barreras</p>
                </div>
                <div className='landing__container-hero-button'>
                    <div className='landing__hero-button'>
                        <a href="#planes">Ver Planes</a>
                    </div>
                </div>
                <img alt='banner' className="d-lg-block d-none"  src={landingImages('./somos-eanx-banner.jpg')} width="100%" />
                <img alt='banner'  className="d-none d-md-block d-lg-none"  src={landingImages('./somos-eanx-banner-tablet.jpg')} width="100%" />
                <img alt='banner'  className="d-block d-md-none d-lg-none"  src={landingImages('./somos-eanx-banner-phone.jpg')} width="100%" />*/}
                {/*<div className='landing__container-promo-button'>
                    <a href="#planes"><div className='landing__promo-button'> </div></a>
                </div>
                <img alt='banner' className="d-lg-block d-none"  src={landingImages('./blackfriday-banner.jpg')} width="100%" />
                <img alt='banner'  className="d-none d-md-block d-lg-none"  src={landingImages('./blackfriday-banner.jpg')} width="100%" />
                <img alt='banner'  className="d-block d-md-none d-lg-none"  src={landingImages('./blackfriday-banner-mobile.jpg')} width="100%" />
            
            </div>
        </div>*/}
        <div id="contWebChatZoomCrm">
        <WebChatZoomCrm
            elementId="contWebChatZoomCrm"
            backgroundColor="#3BAC53"
            color="#FFFFFF"
            socialGatewayUrl="https://socialgateway.zoomcem.com/modules/zoomWebChat/zoomMessengerLog.php?params=N3hMWEdVWndFVytYQnE4cU4xSm1pMlhRaHVvSXVPRmVuNjQrdGFvS0o4cVdaZXA1SG80azh6WDc2U3g5T3RMODdEME84bmdqNkZqMjNta1BIN1Rza3BhWHdzVDRSdERBUG1PSTlFWGcwM3BrVE5KU213ODUxWTc3ZEk4UmNKejhjN0NEd3RpQ1FQSTdPRHFNbWpZRjVtc1pvUmhyMUJUL2dsQldWeXhUUDVqSXdySWxtZ3YxVmxlMEJYVElLdkdMUnEva1B6YXBFQ3NUNzk0a2lKRkdFanIyaTAvQ01ibVRsVDFXKzlmNmJ1T0czcnM2d01zb1FhTDJyUC9XUDZicjZwQ0cvUUpCaTlCclM1RWY3eE5vYVNodGlTeXc5QlRJaTBtbFVldGs5S2FSWmd6VVRxS2dQQlFhNnpJSXV3UHZBNi81RkpuNytrYVU2Nm1nbzQ4dVJReS9RdlVxWEFZdUgrbFdtN2lZUlgzRXRnTGhiZWR4RzdBWkphNW41NDJFOEl0M2RHUXJRQTV1aGF0L1IwS290ZVVDUzl2TWQ0MVFTNDdoTFQxRDR2Z0VGVW1aWDYydSs1R2FiMHJaaHlkUlVOcWk4UlM4ZU01ZzdRUGYxV0ptVXpraEt0aUkyQzdSZmhQOGxJR0t1c0IyVmxVNWcxK1BraWFDYjcxUzJkdnA2Nko1RzBYNi9CU0FNZWhEc2FZcVRsdm05WUVWWWFnTFB1bHc3WXdDYmU3NWEvQjU2aVBVbTgyeFUrQzZNd3hZUXplbStvSXpBakhWQlB0M1N2b1dWeS8xSFFSdklkL0pwMEhMajJpWHJhVWxiZ2hYdU51c2dQMFcvaTdaMHNBS0p5aVNMMnpYeHByYVhQV08wWXlMVHR3VWNGR1c3U0FwWEE5emswVzdoMzBBbUxEOTMvdTBKSmxUdHlSdWEyVDZZSjVqb2Z6aHN3c1UzNit0eiszMWc4SGZCWnoreW9HUGloRFR4KzNnWXh1VjZPUkllbXhFUURkcm1hY2hiV1JpTXl6dzAzbFpVdGhqSWdub2JtcEZqZ2FYY0lQRXBtcjhkY2w0cnh0UHprWkRRR3ZCYktlR2l2VFAwREk0b3VxWExWZEVBL2FUYkJaK09jWTg5MUZ0d1dVeDhYckxFQjhKU2d4R2FJcGdjUlNSU0VkcWtCYk5XdFNqQjdTclJLM0t6L0xib0pqOHIwZVYwd2JxZGFCREtoaHlmN1BhbDVGSnZqZ1M3Y1kvcVptcnJFVGlBcjFJSGw5UUN2NjJHYVFDZE4reFBTUUNRSFEwN3VyTEQvaUprYUZzMDZ6azJTRTZ2VHo3aGdtY3ZOczQySXh5R0o4dVdOMG4zV3h1cWdDbTBUMnF3V2hwZXBSTkU4RmFRbWxGWTJlY0t1Wi9vRjBWNlYreE1UbjQ3Z2tudndmM2YxU1hMTFdNdmtkZW9nNEJOYWExaGZ1RDdrOU5tMmFRSi9WQ3h4Q3BaVW42eGdUQVRQeXlmVkEwOEdyazN5elJuS0NoS2RJdXI2OENIazVvK2JjM2Q3R2ZBclQrSWpQT2V6VkFEb0pFc0Zoc0NXeVZvT2t1NnkrMzUxZnNrc2tZRjlPQStoK045ditZWkVhdlUzenJjT0VDbWtsNlpRVWhTTDNqZVE9PQ=="/>
        </div>
        <div className='container mt-5'>
            <div className='row justify-content-center text-center'>
                <div className='col-12 col-md-8 col-lg-9 mb-4'>
                    <p className='landing__h3 landing__our-courses-margin-bottom'>Explora nuestro catálogo de cursos</p>
                    <p className='landing__paragraph-text'>Una experiencia de aprendizaje más allá de las barreras, desarrolla habilidades con educación de
                                                            calidad, expertos reconocidos en su campo, disponibilidad 24/7, experiencia 100% autónoma y en
                                                            línea. Tú decides qué estudiar, para eso tenemos 8 academias diseñadas especialmente para ti.
                                                            Encuentra aquí una sugerencia de cursos y temáticas que se adaptan a tus necesidades.</p>
                </div>
            </div>
        </div>
        <div className='container'>
        <Suspense fallback={<div>Loading...</div>}>
            <CourseCategorySection />
        </Suspense>
            
            <div id="planes"></div>
        </div>
        
        <Suspense fallback={<div>Loading...</div>}>
            <PlansSection />
        </Suspense>

        <div className='container-fluid landing__bestCourseSection'>
            <div className='row justify-content-center text-center'>
                <div className='col-12 col-md-8 col-lg-6 '>
                    <p className='landing__h3 my-4'>Cursos Recomendados</p>
                </div>
            </div>
            <Suspense fallback={<div>Loading...</div>}>
                <BestCoursesSection />
            </Suspense>
            <div className='row justify-content-center text-center pb-5'>
                <div className='col-11 col-sm-4 col-md-3 col-lg-2 landing__button-all-courses' onClick={()=>{navigate('/cursos')}}>
                    Ver Todos los Cursos
                </div>
            </div>

        </div>

        <div className='container mt-5'>
            <div className='row justify-content-center text-center'>
                <div className='col-12 col-md-8 col-lg-6 '>
                    <p className='landing__h3'>¿Por qué EanX?</p>
                </div>
            </div>
        </div>
        <div className='container d-flex justify-content-center'>
            <div className='row'>
                <div className='col-12 p-5 pt-2'>
                    <div className='row justify-content-center text-center'>
                        <div className='col-12 col-md-6 col-lg-3 px-3'>
                            <img className='landing__icon-por-que-ean' alt='Ícono de sombrero de graduación.' src={landingImages('./icons/Desarrollo-de-habilidades.png')} width="100" height="100" />
                            <p className='landing__title-por-que-ean'>Desarrolla habilidades</p>
                            <p className='landing__paragraph-por-que-ean'>Nuestros cursos tienen un enfoque práctico. Aprende a hacer algo y ejecútalo en tu vida personal o profesional</p>
                        </div>
                        <div className='col-12 col-md-6 col-lg-3 px-3'>
                            <img className='landing__icon-por-que-ean' alt='Ícono profesor apuntando a la pizarra.' src={landingImages('./icons/Nuestros-Expertos.png')} width="100" height="100" />
                            <p className='landing__title-por-que-ean'>Nuestros Expertos</p>
                            <p className='landing__paragraph-por-que-ean'>Aprende de los mejores y de lo que saben hacer mejor</p>
                        </div>
                        <div className='col-12 col-md-6 col-lg-3 px-3'>
                            <img className='landing__icon-por-que-ean' alt='Ícono de reloj.' src={landingImages('./icons/A-tu-propio-ritmo.png')} width="100" height="100" />
                            <p className='landing__title-por-que-ean'>A tu propio ritmo</p>
                            <p className='landing__paragraph-por-que-ean'>Aprende cuando quieras y donde quieras, nuestros cursos están disponibles 24/7</p>
                        </div>
                    <div className='col-12 col-md-6 col-lg-3 px-3'>
                            <img className='landing__icon-por-que-ean' alt='Ícono de certificado.' src={landingImages('./icons/Certificaciones.png')} width="100" height="100" />
                            <p className='landing__title-por-que-ean'>Certificaciones</p>
                            <p className='landing__paragraph-por-que-ean'>Todos nuestros cursos incluyen insignias o certificados que acreditan tu conocimiento</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <AcceptDialog 
          open={eanuserDialog}
          message="Ya disfrutas de un plan eanista."
          style='info'
          afterClose={()=>setEanuserDialog(false)}
          ></AcceptDialog>
        <div className='landing__container-eres-comunidad-eanista'>
        <div className='container me-lg-0 my-5 ms-lg-auto'>
                <div className='row justify-content-lg-end align-items-center'>
                    <div className='col-12 d-block d-lg-none' style={{overflow: "hidden", textAlign: "center", margin: "0 auto"}}>
                        {/*<img alt='Men looking at laptp' src={landingImages('./men-laptop-medium.png')} style={{width: "60%", height: "auto",minWidth: "100%", maxWidth: "none", margin: "0 -100%"}}/> */}
                        <img alt='Men looking at laptp' src={landingImages('./men-laptop-medium.png')} className="img-fluid" width="516" height="348" />
                    </div>
                    <div className='col-xl-4 col-lg-5 col-md-10 col-12'>
                        <div className='row'>
                            <div className='col-12 landing__text-eres-comunidad-eanista'>
                                ¿Eres comunidad eanista <span className='landing__text-color-green'> y quieres ingresar a EanX? </span>
                            </div>
                        </div>
                        <div className='row my-4'>
                            <div className='col-12 landing__paragraph-text'>
                                Si eres estudiante activo, docente o colaborador, valida tus datos para activar tu cuenta y no te quedes atrás, aprende y evoluciona.
                            </div>
                        </div>
                        <div className='row text-center  justify-content-center justify-content-md-start'>
                            <Link onClick={eanActivePlan?showEanDialog:null} to='/verificar-datos' className='col-11 col-md-4 col-lg-5 landing__button-primary'>Valida Tus Datos</Link>
                        </div>
                    </div>
                    <div className='col-lg-7 col-xl-8 d-flex justify-content-end pe-0 d-none d-lg-grid'>
                         {/*<img alt='Men looking at laptp' src={landingImages('./men-laptop.png')} style={{width: "100%", height: "auto"}}/> */}
                        <img alt='Men looking at laptp' src={landingImages('./men-laptop.png')} className="img-fluid" width="822" height="478" />
                    </div>
                </div>
            </div>
        </div>
        <div className='container mt-3'>
            <div className='row justify-content-center text-center'>
                <div className='col-12 col-md-8 col-lg-6 '>
                    <p className='landing__h3'>Casos de Éxito</p>
                </div>
            </div>
        </div>
        <div className='container mb-3'>
            <Suspense fallback={<div>Loading...</div>}>
                <SuccessfulCasesSection />
            </Suspense>
        </div>
        <div className='container-fluid landing__container-unete-a-nosotros'>
            <div className='row text-center pt-3'>
                <div className='col-12 landing__title-unete-a-nosotros my-4'>
                    ¡Únete Ahora!
                </div>
            </div>
            <div className='row justify-content-center text-center pb-4'>
                <div className='col-md-10 col-lg-7 col-xl-5 col-12 landing__paragraph-unete-a-nosotros'>
                    Elige uno de nuestros planes, suscríbete y fortalece tus
                    habilidades y competencias en el tema que quieras.
                </div>
            </div>
            <div className='row justify-content-center text-center pb-5'>
                <div className='col-10 col-md-3 col-lg-3 col-xl-2 landing__button-see-our-plans' onClick={handleScrollPlanSection}>
                    Ver Nuestros Planes
                </div>
            </div>
        </div>
        <Footer />
    </>
  )
}
