import React, { useEffect } from 'react'
import { AdminHeader } from '../plans/AdminHeader';
import { SimpleFooter } from '../../footer/Footer';
import { B2BCreateEditSection } from './B2BCreateEditSection';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuthToken } from '../../../hooks/useAuthToken';
/**
 * Creado por Equilibrio Agency 2022<br>
 * Página para mostrar contenido de sección edición de usuarios
 * @return {HTMLElement} Elementos HTML de cada componente
 * @interface AdminHeader
 */
export const B2BCreateEditPage = () => {
    const { getSessionUser } = useAuthToken(true);
    const navigate = useNavigate();
    useEffect(()=> {
      const sessionUser = getSessionUser();
      if(sessionUser.type !== 0 && sessionUser.type !== 1){
        navigate("/admin/usuarios");
      }
    },[]);
    return (
        <>
            <AdminHeader />
            <div style={{marginTop: "100px"}}></div>
            <B2BCreateEditSection />
            <SimpleFooter />
        </>
    )
}