import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import { useFetch } from '../../hooks/useFetch';
import { useForm as hookUseForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { AuthFormContainerPage, PwdEye, SubmitFormButton } from '../controls/UIControls';
import { PasswordValidator } from '../controls/PasswordValidator';
import * as CONSTANTS from '../../assets/constants';
/**
 * Creado por Equilibrio Agency 2022<br>
 * Función de registro gratuito
 * @param {string} userID ID de usuario a registrar
 * @param {Array} dataPlan Datos del plan a registrar
 * @param {Array} dataPlan Datos del curso a registrar
 * @return {HTMLElement} Elementos HTML de cada componente
 * @interface RegisterPage
 */
export const RegisterPage = ({userID, dataPlan, dataCourse}) => {
    const alphabetPattern = CONSTANTS.PATTERN_ALPHABET;
    const numericPattern = CONSTANTS.PATTERN_NUMERIC;
    const alphanumericPattern = CONSTANTS.PATTERN_ALPHANUMERIC;

    const numericMessage=CONSTANTS.MESSAGE_DOCUMENT_NUMERIC_INVALID;
    const alphanumericMessage=CONSTANTS.MESSAGE_DOCUMENT_NUMERIC_INVALID;

    const [data, handleFetch] = useFetch();
    const location = useLocation();
    const plan = dataPlan ? dataPlan : location.state?.plan;
    const course = dataCourse ? dataCourse : location.state?.course;
    const eanuser = dataPlan ? {} : location.state?.eanuser;
    useEffect(() => {
        window.scrollTo(0, 0);
        if(userID){
            setValue("firstName", eanuser?.name);
            setValue("lastName", eanuser?.lastname);
            setValue("userEmail", eanuser?.email);
            setValue("documentType", eanuser?.identificationType.toUpperCase());
            setValue("userDocument", eanuser?.identificationNumber);
            setValue("type", eanuser?.type);
        }
      }, []);
    const { register, handleSubmit, watch, setValue,
            control, formState: { errors }, setError, 
            getValues, setFocus } = hookUseForm();
    const [showAge, setShowAge] = useState(false);
    const [passwordIsValid, setPasswordIsValid] = useState(false);
    const [confirmPasswordMatches, setConfirmPasswordMatches] = useState(false);
    const [registerError, setRegisterError] = useState(null);
    const [colphone, setColphone] = useState(false);

    const [namePattern, setNamePattern] = useState(alphabetPattern);
    const [docPattern, setDocPattern] = useState(numericPattern);
    const [docMessage, setDocMessage] = useState(numericMessage);

    
    const setValidPassword = (isValid) =>  {
        setPasswordIsValid(isValid);
    }

    const password=watch('password','');
    const password2=watch('password2','');

    const navigator = useNavigate();
    // Función para revisar máscara de correo electrónico
    const maskEmail = email => {
        const [user,domain] = email.split('@');
        const userlen = user.length;
        let masked='';
        for(let i=0;i<userlen;i++) {
            masked+=(i===0 || i === 1 || i === userlen -2 || i === userlen -1)?user[i]:'*';
        }
        masked+='@'+domain;
        return masked;
    }

    useEffect(()=> {
        setConfirmPasswordMatches(password===password2);
    },[password, password2]);
    // Función para retornar datos obtenidos en formulario
    const getRequestData = (formValues) => {
        return {
            name: formValues.firstName,
            lastname: formValues.lastName,
            email: eanuser?.email ? eanuser?.email : formValues.userEmail,
            documentType: eanuser?.identificationType ? eanuser?.identificationType : formValues.documentType,
            document: eanuser?.identificationNumber ? eanuser?.identificationNumber : formValues.userDocument,
            phone: formValues.phone,
            address: formValues.address,
            password: formValues.password,
            type: formValues.type || 1,
        }
    }    
    const isValidPhone = (phone) => {
        if(phone.startsWith('57')){
            if(parseInt(phone[2]) === 3 && parseInt(phone.length) === 12){
                setColphone(false);
            }else{
                setColphone(true);
            }
        }else{
                setColphone(false);
            }
      };
    // Handle para guardar datos de registro y verificación de correo electrónico
    const HandleRegister = (formValues) => {
        setRegisterError(null);
        if(passwordIsValid && confirmPasswordMatches && !isValidPhone(formValues.phone)) {
             const response = handleFetch({
                url:'users',
                method: 'POST', 
                data: getRequestData(formValues)
            });
            response
                .then(async r => {
                    if(plan && plan?.codeId){
                        await handleFetch({
                            url: `users/couponsusers/${plan?.codeId}`,
                            method: 'PATCH',
                            data: {
                                "userId": r.id
                            },
                        })
                    }
                    navigator('/verificar-email',{state: {email: eanuser?.email ? eanuser?.email : getValues('userEmail'), changeEmail: false, registerPay: true,plan,course}});
                },
                error => {
                    console.log('FETCH ERROR REGISTER 1', error);
                    if(error.errors) {
                        let e = error.errors[0];
                        switch(e.id) {
                            case 201: // Correo ya registrado
                                setError('userEmail',{message:CONSTANTS.MESSAGE_EMAIL_REGISTERED},{shouldFocus:true});
                                break;
                            case 202: // Documento ya registrado
                                setError('userDocument',{message: CONSTANTS.MESSAGE_DOCUMENT_REGISTERED.replace('%1',maskEmail(error.data.email))});
                                setFocus('userDocument');
                                break;
                            default:
                                break;
                        }
                    } else {
                        setRegisterError( CONSTANTS.MESSAGE_GENERIC_ERROR);
                    }
                });
        } else {
            alert('Hay errores en el formulario');
        }
    }

    const handleError = (e) => console.log(errors,e);
    // Función validar tipo de documento
    const handleSelectChange = (selectedOption) => {
        if(selectedOption.target.value === 'DIE' || selectedOption.target.value === 'PA' || selectedOption.target.value === 'TI') {
            setShowAge(true);
            console.log("showAge", showAge);
        }else {
            setShowAge(false);
        }
        if(selectedOption.target.value === 'PA') {
            setDocPattern(alphanumericPattern);
            setDocMessage(alphanumericMessage);
        } else {
            setDocPattern(numericPattern);
            setDocMessage(numericMessage);
        }
        //trigger('userDocument');
    }

    const registerForm = (
        <form onSubmit={ handleSubmit(HandleRegister, handleError) }>
            <div className="auth__title-register">TU CUENTA</div>
            <div className="mb-3">
                <label className='auth__form-label'>Correo <span className='auth__required-input'>*</span> <span className='auth__comment'>Será tu usuario en plataforma</span></label>
                <input
                    type='text'
                    name='userEmail'
                    disabled={userID}
                    className={`form-control auth__input 
                                ${errors.userEmail?"auth__input--error":""}`}
                    placeholder={CONSTANTS.LABEL_EMAIL_PLACEHOLDER}
                    {...register("userEmail", { required: CONSTANTS.MESSAGE_EMAIL_REQUIRED,
                        pattern: {
                            value:  CONSTANTS.PATTERN_EMAIL ,
                            message:  CONSTANTS.MESSAGE_EMAIL_INVALID
                        }})}
                />
                {errors.userEmail && (
                    <span role="alert" className='auth__inputError'>
                    {errors.userEmail.message}
                    </span>
                )}
            </div>

            <div className="mb-3">
                <label className='auth__form-label'>Contraseña <span className='auth__required-input'>*</span></label>
                <Controller
                    name="password"
                    control={control}
                    rules={{ required: CONSTANTS.MESSAGE_PASSWORD_REQUIRED }}
                    render={({field: { onChange, onBlur, name}, fieldState: {invalid} }) => {
                        return (
                            <PwdEye
                                className = {`form-control auth__input ${invalid?"auth__input--error":""}`}
                                placeholder={CONSTANTS.LABEL_PASSWORD_PLACEHOLDER}
                                onChange={onChange}
                                onBlur={onBlur}
                                name={name}
                                error={invalid}
                            >
                            </PwdEye>)
                    }
                    }
                />
                <PasswordValidator
                    value={password}
                    checkCriteria={setValidPassword}
                ></PasswordValidator>
                {!passwordIsValid && (
                    <span role="alert" className='auth__inputError'>
                    {CONSTANTS.MESSAGE_PASSWORD_IVALID}
                    </span>
                )}
            </div>
            <div className="mb-3">
                <label className='auth__form-label'>Repite tu contraseña <span className='auth__required-input'>*</span></label>
                <Controller
                    name="password2"
                    control={control}
                    rules={{ required: CONSTANTS.MESSAGE_PASSWORD_REQUIRED }}
                    render={({field: { onChange, onBlur, name}, fieldState: {invalid} }) => {
                        return (
                            <PwdEye
                                className = {`form-control auth__input ${invalid?"auth__input--error":""}`}
                                placeholder={CONSTANTS.LABEL_PASSWORD_CONFIRM_PLACEHOLDER}
                                onChange={onChange}
                                onBlur={onBlur}
                                name={name}
                                error={invalid}
                            >
                            </PwdEye>)
                    }
                    }
                />
                {!confirmPasswordMatches && (
                    <span role="alert" className='auth__inputError'>
                    {CONSTANTS.MESSAGE_PASSWORD_CONFIRM_DOESNT_MATCH}
                    </span>
                )}
            </div>


            <div className="auth__title-register">DATOS PERSONALES</div>
            <div className="mb-3">
                <label className='auth__form-label'>Nombres <span className='auth__required-input'>*</span></label>
                <input 
                    type='text' 
                    name='firstName'
                    className={`form-control auth__input 
                                ${errors.firstName?"auth__input--error":""}`}
                    placeholder={CONSTANTS.LABEL_NAME_PLACEHOLDER}
                    {...register("firstName", { required: CONSTANTS.MESSAGE_NAME_REQUIRED,
                        pattern: {
                            value: namePattern,
                            message: CONSTANTS.MESSAGE_ONLY_ALPHABET,
                        } })}
                />
                {errors.firstName && (
                    <span role="alert" className='auth__inputError'>
                    {errors.firstName.message}
                    </span>
                )}                        
            </div>
            <div className="mb-3">
                <label className='auth__form-label'>Apellidos <span className='auth__required-input'>*</span></label>
                <input 
                    type='text' 
                    name='lastName'
                    className={`form-control auth__input 
                                ${errors.lastName?"auth__input--error":""}`}
                    placeholder={CONSTANTS.LABEL_LASTNAME_PLACEHOLDER}
                    {...register("lastName", { required: CONSTANTS.MESSAGE_LASTNAME_REQUIRED,
                        pattern: {
                            value: namePattern,
                            message: CONSTANTS.MESSAGE_ONLY_ALPHABET,
                        } })}
                />
                {errors.lastName && (
                    <span role="alert" className='auth__inputError'>
                    {errors.lastName.message}
                    </span>
                )}                        
            </div>
            <div className="mb-3">
                <label className='auth__form-label'>Tipo de documento </label>
                <select 
                    defaultValue=""
                    className={`form-select auth__select 
                                ${errors.documentType?"auth__input--error":""}`}
                    aria-label="Default select example"
                    name='documentType'
                    disabled={userID}
                    {...register("documentType", { required: CONSTANTS.MESSAGE_DOCUMENT_TYPE_REQUIRED,
                                                    onChange: handleSelectChange  })}
                >
                    <option value="">Selecciona el tipo de documento</option>
                    <option value="CC">Cédula de Ciudadanía</option>
                    <option value="CE">Cédula de Extranjería</option>
                    <option value="DIE">Documento de Identidad Extranjera</option>
                    <option value="PA">Pasaporte</option>
                    <option value="TI">Tarjeta de Identidad</option>
                    <option value="PEP">Permiso Especial de Permanencia</option>
                    <option value="DNI">Documento nacional de identidad</option>
                    <option value="IPJ">Identificador de persona jurídica</option>
                    <option value="CERC">Certificado Cabildo</option>
                    <option value="NIP">Número Identificación Personal</option>
                    <option value="NSE">Número por Secretaría de Educación</option>
                    <option value="NUIP">Número Único Identificación Personal</option>
                    <option value="REGC">Registro Civil</option>
                    <option value="SNP">Registro SNP</option>
                    <option value="TP">Tarjeta profesional</option>
                    <option value="Visa">Visa</option>
                    <option value="NIT">N.I.T.</option>
                    <option value="PPT">Permiso por Protección Temporal</option>
                </select>
                {errors.documentType && (
                    <span role="alert" className='auth__inputError'>
                    {errors.documentType.message}
                    </span>
                )}                        
            </div>
            {
                showAge ? (
                    <div className='show-form-age'>
                        <div className="form-check">
                            <input className="form-check-input auth__form-check" 
                            type="checkbox" 
                            value="" 
                            {...register("olderThan12", {required: CONSTANTS.MESSAGE_OLDER_THAN_12_REQUIRED})}
                            />
                            <label className='form-check-label auth__form-label'  style={{fontSize: "12px"}}>
                                Declaro ser mayor de 12 años.
                            </label>
                        </div>
                        <div>
                            <label className='form-check-label auth__form-label' style={{fontSize: "12px", paddingBottom: "12px"}}>
                                Si eres menor de 12 años requieres autorización expresa de tus padres o tutores, 
                                pídeles que se comuniquen con nosotros <Link to='/contactanos' className='text_primary'>aquí.</Link>
                            </label>
                        </div>
                        {errors.olderThan12 && (
                            <div role="alert" className='auth__inputError'>
                            {errors.olderThan12.message}
                        </div>
                    )}                        
                    </div>
                ) : null
            }
            
            
            <div className="mb-3">
                <label className='auth__form-label'>Número de documento <span className='auth__required-input'>*</span></label>
                <input 
                    type='text' 
                    name='userDocument'
                    disabled={userID}
                    className={`form-control auth__input 
                                ${errors.userDocument?"auth__input--error":""}`}
                    placeholder={CONSTANTS.LABEL_DOCUMENT_PLACEHOLDER}
                    {...register("userDocument", { 
                        required: CONSTANTS.MESSAGE_DOCUMENT_REQUIRED, 
                        pattern: {
                            value: docPattern,
                            message: docMessage
                        } })}
                />
                {errors.userDocument && (
                    <span role="alert" className='auth__inputError'>
                    {errors.userDocument.message}
                    </span>
                )}                        
            </div>
            <div className="mb-3">
                <label className='auth__form-label'>Teléfono móvil<span className='auth__required-input'>*</span></label>
                <Controller
                    name="phone"
                    control={control}
                    className="auth__select"
                    rules={{ required: CONSTANTS.MESSAGE_PHONE_REQUIRED }}
                    render={({ field }) => 
                    <PhoneInput {...field}
                    inputClass={`auth__input-phone 
                                ${errors.phone?"auth__input--error":""}`}
                    specialLabel=''
                    inputProps={{
                        name: 'phone',
                        required: true,
                        placeholder: CONSTANTS.LABEL_PHONE_PLACEHOLDER
                    }}
                    country={'co'}/>}
                />
                {errors.phone && (
                    <span role="alert" className='auth__inputError'>
                    {errors.phone.message}
                    </span>
                )}
                {colphone && (
                    <span role="alert" className='auth__inputError'>Tu número colombiano debe iniciar en 3 y tener el formato 3## #######</span>
                )}                        
            </div>
            <div className="mb-3">
                <label className='auth__form-label'>Dirección <span className='auth__required-input'>*</span></label>
                <input 
                    type='text' 
                    name='address'
                    className={`form-control auth__input 
                                ${errors.address?"auth__input--error":""}`}
                    placeholder={CONSTANTS.LABEL_ADDRESS_PLACEHOLDER}
                    {...register("address", { required: CONSTANTS.MESSAGE_ADDRESS_REQUIRED })}
                />
                {errors.address && (
                    <span role="alert" className='auth__inputError'>
                    {errors.address.message}
                    </span>
                )}                        
            </div>
            <div className="form-check">
                <input className="form-check-input auth__form-check" 
                type="checkbox" 
                value="" 
                {...register("acceptTerms", {required: CONSTANTS.MESSAGE_ACCEPT_TERMS_REQUIRED})}
                />
                <label className='form-check-label auth__form-label' style={{fontSize: "12px"}} >
                    Acepto <a href='https://eanx.co/terminos-condiciones.pdf' className='auth__link'>Términos y Condiciones</a> de la plataforma.
                </label>
            </div>
            <div className="form-check">
                <input className="form-check-input auth__form-check" 
                    type="checkbox" 
                    value="" 
                    {...register("acceptPolicy", {required:CONSTANTS.MESSAGE_ACCEPT_POLICY_REQUIRED})}
                    />
                <label className='form-check-label auth__form-label' style={{fontSize: "12px"}} >
                Tengo a disposición la <a href='https://universidadean.edu.co/sites/default/files/institucion/acuerdos/politica-tratamiento-de-datos-personales.pdf' className='auth__link'>Política de tratamiento de datos personales</a> y el <a href='https://universidadean.edu.co/la-universidad/quienes-somos/orientacion-estrategica/reglamentos-universidad-ean/aviso-de-privacidad' className='auth__link'>Avíso de privacidad</a> de la Universidad Ean
                y con fundamento en ello, <a href='https://universidadean.edu.co/la-universidad/quienes-somos/orientacion-estrategica/reglamentos-universidad-ean/autorizacion-para-uso-de-datos-personales' className='auth__link'>autorizo</a> a la Universidad Ean de manera voluntaria, explícita, informada e inequívoca el
                tratamiento de mis datos personales.
                </label>
            </div>
            {errors.acceptTerms && (
                <div role="alert" className='auth__inputError'>
                {errors.acceptTerms.message}
                </div>
            )}                        
            {errors.acceptPolicy && (
                <div role="alert" className='auth__inputError'>
                {errors.acceptPolicy.message}
                </div>
            )}
            <input
                type='hidden'
                name='type'
                {...register("type")}
            />
            <SubmitFormButton main_title="Suscribirme" wait_title="Procesando..." loading={data.loading}></SubmitFormButton>

            {registerError && (
                <div role="alert" className='auth__inputError text-center'>
                { CONSTANTS.MESSAGE_GENERIC_ERROR}
                </div>
            )}

            { !userID ? <div className="auth__account-exists">
                ¿Ya tienes una cuenta? 
                <Link to="/ingreso" className='auth__link'> Inicia sesión</Link>
            </div> : '' }
        </form>
 
    );
    return (
        <AuthFormContainerPage title={plan?.name || course?.name || 'Registro'} content={registerForm} containerClass='py-5'></AuthFormContainerPage>
    )
}