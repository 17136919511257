import React, { useState, useEffect } from 'react'
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import { useFetch } from '../../hooks/useFetch';
import { useNavigate } from "react-router-dom";
import { AuthFormContainerPage, SubmitFormButton } from '../controls/UIControls';
import { useGetPlan } from '../../hooks/useGetPlan';
import { useGetCourse } from '../../hooks/useGetCourse';
import { useAssociateDiscount } from '../../hooks/useGetDiscount';
import { AmountFormat } from '../controls/AmountFormat';
import { apiConfig } from '../../api/apiConfig';
const landingImages = require.context('../../assets/images', true);
/**
 * Creado por Equilibrio Agency 2022<br>
 * Interface para tipo de pago
 * @interface PaymentTypePage
 */
export const PaymentTypePage = () => {

    const [showAlert, setShowAlert] = useState(false);
    const [planAssociated, setPlanAssociated] = useState(true);
    const [planAssociatedMessage, setPlanAssociatedMessage] = useState('');
    const [getPlanStarted, setGetPlanStarted] = useState(false);
    const [processing, setProcessing] = useState(false);
    const location = useLocation();
    const  [searchParams] = useSearchParams();
    const {getPlan, associatePlan, mpPreference} = useGetPlan();
    const {getCourse, associateCourse, mpPreferenceCourse} = useGetCourse();
    const {associateDiscount} = useAssociateDiscount();
    const COURSE_ASSOCIATED = 'El curso fue asociado correctamente a tu cuenta.';
    const COURSE_NOT_ASSOCIATED = 'Ocurrió un error al asociar el curso seleccionado a tu cuenta.';
    const COURSE_PURCHASED = 'La compra de tu curso se realizó con éxito.';
    const COURSE_NOT_PURCHASED = 'El pago de tu curso fue rechazado.';
    const PLAN_ASSOCIATED = 'El plan fue asociado correctamente a tu cuenta.';
    const PLAN_NOT_ASSOCIATED = 'Ocurrió un error al asociar el plan seleccionado a tu cuenta.';
    const PLAN_PURCHASED = 'La compra de tu plan se realizó con éxito.';
    const PLAN_NOT_PURCHASED = 'El pago de tu plan fue rechazado.';

    //const planAssociated = location.state?.planAssociated
    const [buttonProperties, setButtonProperties] = useState({label:'Ir al login', linkProps: {to:'/ingreso'}});


    const handleGetPlanResponse = status => {
        setGetPlanStarted(true);
        switch(status) {
            case 'assigned':
                setPlanAssociated(true);
                setPlanAssociatedMessage(PLAN_ASSOCIATED);
                setShowAlert(true);
                break;
            case 'not_assigned':
                setPlanAssociated(false);
                setPlanAssociatedMessage(PLAN_NOT_ASSOCIATED);
                setShowAlert(true);
                break;
            case 'purchase':
                setShowAlert(false);
                break;
            case 'approved':
                setPlanAssociated(true);
                setPlanAssociatedMessage(PLAN_PURCHASED);
                setShowAlert(true);
            case 'rejected':
                setPlanAssociated(false);
                setPlanAssociatedMessage(PLAN_NOT_PURCHASED);
                setShowAlert(true);
        }
    };
    // Handle para gestionar estado de registro
    const handleGetCourseResponse = status => {
        setGetPlanStarted(true);
        switch(status) {
            case 'assigned':
                setPlanAssociated(true);
                setPlanAssociatedMessage(COURSE_ASSOCIATED);
                setShowAlert(true);
                break;
            case 'not_assigned':
                setPlanAssociated(false);
                setPlanAssociatedMessage(COURSE_NOT_ASSOCIATED);
                setShowAlert(true);
                break;
            case 'purchase':
                setShowAlert(false);
                break;
            case 'approved':
                setPlanAssociated(true);
                setPlanAssociatedMessage(COURSE_PURCHASED);
                setShowAlert(true);
            case 'rejected':
                setPlanAssociated(false);
                setPlanAssociatedMessage(COURSE_NOT_PURCHASED);
                setShowAlert(true);
                }
    };
    const processAnswer = async () => {
        //Almaceno valor de external_reference para asignación el plan si es necesaria
        const status = searchParams.get('status');
        const userPlanData = searchParams.get('external_reference');
        const paymentId = searchParams.get('payment_id');
        const merchandOrderId = searchParams.get('merchant_order_id');
        const requestId = searchParams.get('requestId');
        if(status=='approved') {
            const userPlan = userPlanData.split('-');
            const isNumeric = !isNaN(userPlan[1]);
            const firstDashIndex = userPlanData.indexOf('-');
            const textSegment = userPlanData.slice(firstDashIndex + 1);
            if(isNumeric){
                // Se asigna el plan
                associatePlan({id: parseInt(userPlan[0])}, {id: parseInt(userPlan[1])},`${merchandOrderId}` )
                .then (handleGetPlanResponse);
            }else{
                // Se asigna el curso
                associateCourse({id: parseInt(userPlan[0])}, {id: textSegment},`${merchandOrderId}` )
                .then (handleGetCourseResponse);
            }       
        } else {
            handleGetPlanResponse(status);
        }
    }

    const handleClick = (e) => {
        e.preventDefault();
        const {user,plan,course,discount} = location.state;
        const updatedPlan = { ...plan };
        if (updatedPlan) updatedPlan.price = totalPrice;
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
            event: "begin_checkout",
            ecommerce: {
            items: [{
            item_name: plan.name,
            item_id: plan.id,
            location_id: "Online Store",
            price: plan.price,
            item_brand: "eanX",
            quantity: 1
            }]
            }
        });
        setProcessing(true);
        if(discount){
        associateDiscount(user,course,plan,discount,totalPrice)
        .then(()=>{})
        .finally(()=>{setProcessing(false);});
        }
        if(course){
            let courseaux = {
                  ...course,
                  "price": totalPrice,
                  "course.name": course.name
              };
            getCourse(user,courseaux, false)
            .then(handleGetPlanResponse)
            .finally(()=>{setProcessing(false);});
        }else{
        getPlan(user,updatedPlan,false)
            .then(handleGetPlanResponse)
            .finally(()=>{setProcessing(false);});    
        }
    }


    useEffect(()=> {
        if(!getPlanStarted) {
            setButtonProperties({label:'Continuar...', linkProps: {to:'/', onClick: handleClick}});
        } else {
            processAnswer();
            setButtonProperties({label:'Ir al login', linkProps: {to:'/ingreso'}})
        }
    },[getPlanStarted, mpPreference]);

    const [data, handleFetch] = useFetch();
    const plan = location.state.plan;
    const user = location.state.user;
    const [ip, setIp] = useState(null);
    const navigate = useNavigate();
    const course = location.state.course;
    const discount = location?.state?.discount;
    const [discountType, setDiscountType] = useState(-1);
    const [totalPrice, setTotalPrice] = useState(course ? course.price : plan.price);
    function calcFinalPrice(price, type, value) {
        let finalPrice = price;
        if (type === 0) finalPrice = price - value;
        else if (type === 1) {
          const porcDiscount = (price * value) / 100;
          finalPrice = price - porcDiscount;
        } else {
          console.error('Tipo de cálculo no válido. Debe ser 0 o 1.');
        }
        if(finalPrice<0) finalPrice = price; 
        return finalPrice;
      }
    useEffect(() => {
        window.scrollTo(0, 0);
        if(discount?.code){
            setDiscountType(discount.type);
            setTotalPrice(calcFinalPrice(course ? course.price : plan.price,discount.type,discount.price));
        }
        fetch('https://api64.ipify.org?format=json')
        .then(response => response.json())
        .then(data => {
            setIp(data.ip)
        })
        .catch(error => console.error('Error al obtener la IP:', error));
    }, []);
    const openCheckout = async (e) => {
        e.preventDefault();
        const currentDate = new Date();
        currentDate.setMinutes(currentDate.getMinutes() + 30);
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');
        const hours = String(currentDate.getHours()).padStart(2, '0');
        const minutes = String(currentDate.getMinutes()).padStart(2, '0');
        const seconds = String(currentDate.getSeconds()).padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}-05:00`;
        const data = { "plan":
            {
            "name":plan.name,
            "currency":"COP",
            "price":plan.price
          },
          "reference":`${year}${month}${day}-${plan.id}-${user.id}`,
          "ip": ip,
          "userAgent": window.navigator.userAgent,
          "expiration": `${formattedDate}`,
          "buyer": {
            "name": data.user.name,
            "surname": data.user.lastname,
            "documentType": data.user.documentType,
            "document": data.user.document,
            "email": data.user.email,
            "mobile": data.user.phone
            }
          }
        let requestOptions = {
            method: 'POST',
            headers: Object.assign({},apiConfig.header, {}),
            body: JSON.stringify(data)
        };
        await fetch( `${ apiConfig.baseUrl }payments/placetopay`,  requestOptions)
        .then( async resp => {
            const response = await resp.json();
            window.P.init(response.processUrl);
            window.P.on('response', function (response) {
                if(response.status.status === "APPROVED"){
                    navigate(`/cuenta-creada?&status=approved&external_reference=${user.id}-${plan.id}&payment_id=${response.reference}&merchant_order_id=`);
                }
            });
        })
        .catch (error => {
            return Promise.reject(error)
        })
    };
    const registerWithPaymentForm = (
        <form onSubmit={ handleClick }>

            <div className='row justify-content-center'>
                <div className='col-12 col-sm-6 mt-3'>
                    <div style={{textAlign: "center"}}>
                        <label style={{marginBottom: "5px", fontSize: "15px"}}>Tu pago se hará mediante:</label>
                        <img
                            alt='Placetopay'
                            src={'https://static.placetopay.com/placetopay-logo.svg'}
                            style={{width: "160px", height: "auto"}}
                            className="rounded mx-auto d-block"
                        />
                    </div>

                </div>

                <div className='col-12 col-sm-6 mt-3'>

                    <div className='PaymentType__table-container mt-4 mb-3'>
                        <table className='PaymentType__table'>
                            <tr className='PaymentType__tr'>
                                <td className='PaymentType__td-left'>{course ? course.name : plan.name}</td>
                                <td className='PaymentType__td-right'><AmountFormat
                                    name='price'
                                    value={course ? course.price : plan.price}
                                    displayType='text'
                                    currency='COP'
                                /></td>
                            </tr>
                            <tr className='PaymentType__tr'>
                                <td className='PaymentType__td-left'>Descuento</td>
                                <td className={`PaymentType__td-right ${discountType !== -1?'ProfilePage__color-green':''}`}><AmountFormat
                                    name='discount'
                                    value={discount?.price || 0}
                                    displayType='text'
                                    currency={discountType===1?'':'COP'}
                                    percentage={discountType===1}
                                /></td>
                            </tr>
                            <tr className='PaymentType__tr'>
                                <td className='PaymentType__td-left'>Total</td>
                                <td className='PaymentType__td-right'><AmountFormat
                                    name='total'
                                    value={totalPrice}
                                    displayType='text'
                                    currency='COP'
                                /></td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
            <div className='row justify-content-center'>
                <SubmitFormButton main_title="Ir a pagar" wait_title="Procesando..." loading={data.loading}></SubmitFormButton>
            </div>
        </form>

    );
    return (
        <AuthFormContainerPage title="Confirmar pago" content={registerWithPaymentForm} containerSubClass='full-width' registerPay={3}></AuthFormContainerPage>
    )
}