import React, { useEffect } from 'react'
import { SimpleFooter } from '../../footer/Footer'
import { AdminHeader } from '../plans/AdminHeader'
import { B2BCourseSection } from "./B2BCourseSection";
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuthToken } from '../../../hooks/useAuthToken';
/**
 * Creado por Equilibrio Agency 2022<br>
 * Página para mostrar contenido de sección de gestión de cursos para B2B
 * @return {HTMLElement} Elementos HTML de cada componente
 * @interface AdminHeader
 */
export const B2BCoursePage = () => {
  
  const location = useLocation();
  const dataB2B = location?.state?.dataB2B ? location.state.dataB2B : '';
  const { getSessionUser } = useAuthToken(true);
  const navigate = useNavigate();
  useEffect(()=> {
    const sessionUser = getSessionUser();
    if(sessionUser.type !== 0 && sessionUser.type !== 1){
      navigate("/admin/usuarios");
    }
  },[]);
  return (
    <>
      <AdminHeader />
      <div style={{marginTop: "100px"}}></div>
      <B2BCourseSection dataB2B={dataB2B} />
      <SimpleFooter></SimpleFooter>
    </>
  )
}
