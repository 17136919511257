import React, { useEffect, useState } from 'react'
import { useFetch } from '../../../hooks/useFetch';
import { useForm as hookUseForm, Controller } from "react-hook-form";
import { useAuthToken } from '../../../hooks/useAuthToken';
import { ErrorMessage, SubmitFormButton, Toast, PwdEye } from '../../controls/UIControls';
import * as CONSTANTS from '../../../assets/constants';
const landingImages = require.context('../../../assets/images', true);
/**
 * Creado por Equilibrio Agency 2023<br>
 * Listado sección edición de Profile
 * @interface ProfileSection
 */
export const ProfileSection = () => {
    const { register, handleSubmit, setValue, control, formState: { errors } } = hookUseForm({ reValidateMode: "onChange" });
    const { getSessionUser}= useAuthToken(true);
    const sessionUser = getSessionUser();
    const alphabetPattern = CONSTANTS.PATTERN_ALPHABET;
    const [namePattern] = useState(alphabetPattern);
    const [fetchData, handleFetch] = useFetch();
    const [toastProperties, setToastProperties] = useState({ show: false });    
    const afterCloseToast = () => {
        setToastProperties({ show: false });
    }
    const tipousuario = [
        { 0: ["Super Administrador"] },
        { 1: ["Soporte"] },
        { 2: ["Administrador"] },
        { 3: ["Observador"] }
      ];
      const tipoNombre = tipousuario.find(obj => obj[sessionUser.type])?.[sessionUser.type]?.[0];
    // Consulta de endpoint listado de encuestas
    useEffect(() => {
        const response = handleFetch({
            url: `bo/users/${sessionUser.id}`,
            method: 'GET',
        });
        response
            .then((r) => {
                setValue("name", r.name);
                setValue("lastname", r.lastname);
                setValue("email", r.email);
            },
                error => {
                    console.log(error.errors);
                })
    }, [])
    // Función submit para guardar información
    const onSubmit = (data) => {
            handleFetch({
                url: `bo/users/${sessionUser.id}`,
                method: 'PATCH',
                data: data,
            })
            .then(() => {
                setToastProperties({
                            show: true,
                            type: 'success',
                            title: 'Operación exitosa',
                            message: 'La información del Modal se guardó correctamente.',
                            position: { horizontal: 'left' },
                        })
            })
            .catch(() => {
                setToastProperties({
                    show: true,
                    type: 'error',
                    message: 'Error al guardar información del Modal. Intenta nuevamente.',
                    position: { horizontal: 'left' },
                });
            });
    }
    return (
        <>
            <Toast {...toastProperties} closeCallback={afterCloseToast} ></Toast>
            <div className='text-center'><h1>Perfil de {tipoNombre}</h1> </div>
            <div className='d-flex justify-content-center'>
                <div className='UserFormContainer row m-2 p-3 col-10 col-md-10'>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className='mb-3'>
                            <div className='row'>
                                <div className="col-12 col-md-6">
                                    <label className="form-label UserFormContainer__label">Nombres</label>
                                        <input
                                            type='text'
                                            name='name'
                                            className={`form-control auth__input 
                                            ${errors.name ? "auth__input--error" : ""}`}
                                            placeholder={CONSTANTS.LABEL_NAME_PLACEHOLDER}
                                            {...register("name", {
                                                required: CONSTANTS.MESSAGE_NAME_REQUIRED,
                                                pattern: {
                                                    value: namePattern,
                                                    message: CONSTANTS.MESSAGE_ONLY_ALPHABET,
                                                }
                                            })}
                                        />
                                        {errors.name && (
                                            <span role="alert" className='auth__inputError'>
                                                {errors.name.message}
                                            </span>
                                        )}
                                </div>
                                <div className="col-12 col-md-6">
                                    <label className='form-label UserFormContainer__label'>Apellidos</label>
                                        <input
                                            type='text'
                                            name='lastname'
                                            className={`form-control auth__input 
                                    ${errors.lastname ? "auth__input--error" : ""}`}
                                            placeholder={CONSTANTS.LABEL_LASTNAME_PLACEHOLDER}
                                            {...register("lastname", {
                                                required: CONSTANTS.MESSAGE_LASTNAME_REQUIRED,
                                                pattern: {
                                                    value: namePattern,
                                                    message: CONSTANTS.MESSAGE_ONLY_ALPHABET,
                                                }
                                            })}
                                        />
                                        {errors.lastname && (
                                            <span role="alert" className='auth__inputError'>
                                                {errors.lastname.message}
                                            </span>
                                        )}
                                </div>
                            </div>
                        </div>
                        <div className='mb-1'>
                            <div className='row'>
                                <div className="col-12 col-md-6">
                                    <label className='form-label UserFormContainer__label'>Correo electrónico</label>
                                        <input
                                            type='text'
                                            name='email'
                                            className={`form-control auth__input 
                                    ${errors.email ? "auth__input--error" : ""}`}
                                            placeholder={CONSTANTS.LABEL_EMAIL_PLACEHOLDER}
                                            {...register("email", {
                                                required: CONSTANTS.MESSAGE_EMAIL_REQUIRED,
                                                pattern: {
                                                    value: CONSTANTS.PATTERN_EMAIL,
                                                    message: CONSTANTS.MESSAGE_EMAIL_INVALID
                                                }
                                            })}
                                        />
                                        {errors.email && (
                                            <span role="alert" className='auth__inputError'>
                                                {errors.email.message}
                                            </span>
                                        )}
                                </div>
                                <div className="col-12 col-md-6">
                                    <label className='form-label UserFormContainer__label'>Contraseña</label>
                                            <Controller
                                                name="password"
                                                control={control}
                                                render={({ field: { onChange, onBlur, name }, fieldState: { invalid } }) => {
                                                    return (
                                                        <PwdEye
                                                            className={`form-control auth__input ${invalid ? "auth__input--error" : ""}`}
                                                            placeholder={CONSTANTS.LABEL_PASSWORD_PLACEHOLDER}
                                                            onChange={onChange}
                                                            onBlur={onBlur}
                                                            name={name}
                                                            error={invalid}
                                                        >
                                                        </PwdEye>)
                                                }
                                                }
                                            />
                                </div>
                            </div>
                        </div>
                        <SubmitFormButton
                            main_title="Guardar"
                            wait_title="Guardando..."
                            loading={fetchData.loading}>
                        </SubmitFormButton>
                    </form>
                    <ErrorMessage message={fetchData.error ? ((!!fetchData.error.code) ? CONSTANTS.MESSAGE_PLAN_ERROR : CONSTANTS.MESSAGE_GENERIC_ERROR) : ''} />
                </div>
            </div>
        </>
    )
}

/**
 * Creado por Equilibrio Agency 2022<br>
 * Función Loading
 * @function Loading
 */
const Loading = (props) => {
    const loadingImage = landingImages('./icons/loading_spin_ean.svg');
    return (
        <div className='d-flex justify-content-center' style={{ minHeight: "300px" }}>
            <div className='row text-center position-absolute justify-content-center'>
                <img alt='Loader' src={loadingImage} />
            </div>
        </div>
    );
}